<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="card mb-3 w-100">
            <img src="../assets/images/successful.svg" class="card-img-top" alt="">
            <div class="card-body">
                <h5 class="card-title text-center">{{ getOrderPlacedLabel }}</h5>
                <p class="card-text">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td>{{ getOrderLabel }}</td>
                                <td style="text-align: right;">{{ order?.oid }}</td>
                            </tr>
                            <tr>
                                <td>{{ getTimestampLabel }}</td>
                                <td style="text-align: right;">{{ order?.time }}</td>
                            </tr>
                            <tr>
                                <td>{{ getTypeLabel }}</td>
                                <td style="text-align: right;">{{ order?.type }}</td>
                            </tr>
                            <tr>
                                <td>{{ getNameLabel }}</td>
                                <td style="text-align: right;">{{ order?.name }}</td>
                            </tr>
                            <tr>
                                <td>{{ getCodeLabel }}</td>
                                <td style="text-align: right;">{{ order?.code }}</td>
                            </tr>
                            <tr>
                                <td>{{ getOrderTypeLabel }}</td>
                                <td style="text-align: right;">{{ order?.action }}</td>
                            </tr>
                            <tr>
                                <td>{{ getQuantityLabel }}</td>
                                <td style="text-align: right;">{{ order?.amount }}</td>
                            </tr>
                            <tr>
                                <td>{{ getValidityLabel }}</td>
                                <td style="text-align: right;">{{ order?.expiry_date }}</td>
                            </tr>
                            <tr>
                                <td>{{ getExchangeLabel }}</td>
                                <td style="text-align: right;">{{ order?.ex }}</td>
                            </tr>
                            <tr>
                                <td>{{ getEstimatedCommissionLabel }}</td>
                                <td style="text-align: right;">{{ order?.fee }}</td>
                            </tr>
                            <tr>
                                <td>{{ getEstimatedTotalLabel }}</td>
                                <td style="text-align: right;">{{ order?.price }}</td>
                            </tr>
                        </tbody>
                    </table>
                </p>
            </div>
        </div>        
    </div>
</template>
<script>
import Translations from "@/lang/translations.js";

export default {
    props: {
        order: {
            required: true,
            type: Object
        }
    },
    computed: {
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getOrderPlacedLabel() {
            return Translations.getOrderPlacedLabel(this.getUserLanguage);
        },
        getOrderLabel() {
            return Translations.getOrderLabel(this.getUserLanguage);
        },
        getTimestampLabel() {
            return Translations.getTimestampLabel(this.getUserLanguage);
        }, 
        getTypeLabel() {
            return Translations.getTypeLabel(this.getUserLanguage);
        }, 
        getNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage);
        }, 
        getCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage);
        }, 
        getOrderTypeLabel() {
            return Translations.getOrderTypeLabel(this.getUserLanguage);
        }, 
        getQuantityLabel() {
            return Translations.getQuantityLabel(this.getUserLanguage);
        }, 
        getValidityLabel() {
            return Translations.getValidityLabel(this.getUserLanguage);
        }, 
        getExchangeLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage);
        }, 
        getEstimatedCommissionLabel() {
            return Translations.getEstimatedCommissionLabel(this.getUserLanguage);
        },
        getEstimatedTotalLabel() {
            return Translations.getEstimatedTotalLabel(this.getUserLanguage);
        },
    }
}
</script>

<style scoped>
    img {
        max-height: 5rem;
        max-width: auto;
    }
</style>