<template>
  <div class="vh-100 d-flex justify-content-center">
    <div class="form-access my-auto">
      <form>
        <span>Create Demo Account</span>
        <div class="form-group">
          <input type="text" class="form-control" :class="{ 'red-border': 0 === name.length, 'green-border': 0 !== name.length }" placeholder="Name" v-model="name" required />
        </div>
        <div class="form-group">
          <input type="email" class="form-control" :class="{ 'red-border': !isValidEmail, 'green-border': isValidEmail }" placeholder="Email" v-model="email" required />
        </div>
        <div class="form-group">
          <input type="tel" class="form-control" :class="{ 'red-border': 0 === phone.length, 'green-border': 0 !== phone.length }" placeholder="Phone" v-model="phone" required />
        </div>
        <div class="form-group">
          <input type="password" class="form-control" :class="{ 'red-border': 0 === password.length, 'green-border': 0 !== password.length }" placeholder="Password" v-model="password" required />
        </div>
        <button type="submit" class="btn btn-primary" :disabled="!submitEnabled" @click.prevent="registerUser()">Create
          Account</button>
      </form>
      <h2>
        Already have an account?
        <router-link to="/login"> Sign in here</router-link>
      </h2>
    </div>
  </div>
</template>

<script>
import http from "@/axios/http-common";

function validateEmail(mail) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(mail);
}

export default {
  data() {
    return {
      name: "", email: "", phone: "", password: "", confirmedPasswordClass: "black-color",
      isValidEmail: false
    }
  },
  watch: {
    email: function(newVal) {
      this.isValidEmail = validateEmail(newVal);
    }
  },
  methods: {
    async registerUser() {
      try {
        // always demo
        const res = await http.getApiClient(0).get("", {
          params: {
            a: "demo_reg_ex",
            em: encodeURIComponent(this.email),
            ph: encodeURIComponent(this.phone),
            name: encodeURIComponent(this.name),
            pw: encodeURIComponent(this.password)
          }});
        if (res) {
          // check answer
          if (0 === res.data.ec) {
            this.$toast.success("You have registered with EFOCS. Please follow the link in your email to activate your account", {
              "duration": 10000,
              "pauseOnHover": true
            });
            this.$router.push({ name: "Login" });
          } else if (9308 === res.data.ec) {
            this.$toast.error("This email is already occupied", {
              "duration": 5000,
              "pauseOnHover": true
            });

          } else {
            this.$toast.error("Could not register with EFOCS", {
              "duration": 5000,
              "pauseOnHover": true
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  },
  computed: {
    submitEnabled() {
      return 0 < this.name.length && 0 < this.password.length &&
        0 < this.email.length && 0 < this.phone.length;
    }
  }
}
</script>

<style scoped>


input.red-border {
  border: 2px solid red;
}

input.green-border {
  border: 2px solid green;
}

input.black-border {
  border: 2px solid black;
}
</style>