<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="row sm-gutters">
            <!-- Menu -->
            <div class="col-sm-12 col-md-2">
                <Menu></Menu>
            </div>
            <!-- Messages -->
            <div class="col-sm-12 col-md-10">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">{{ getMessagesLabel }}&nbsp;({{ getSystemMessagesCount
                            }}&nbsp;{{getItemsLabel}})</h5>
                        <button class="btn btn-primary" @click.prevent="resetSystemMessages">{{ getClearAllLabel
                            }}</button>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{ getDateLabel }}</th>
                                        <th>{{ getTypeLabel }}</th>
                                        <th>{{ getMessageLabel }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(message) in getSystemMessages" :key="message.date">
                                        <td>{{ parseUnixMsTime(message.date) }}</td>
                                        <td>
                                            <div class="icon">
                                                <i v-if="!message.error" class="icon ion-md-lock"></i>
                                                <i v-else class="icon ion-md-alert"></i>
                                            </div>
                                        </td>
                                        <td>{{ message.mess }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '../components/Menu.vue';
import UiUtils from "@/assets/js/ui_utils.js";
import Translations from "@/lang/translations.js";

export default {
    components: {
        Menu
    },
    methods: {
        parseUnixMsTime(itemTime) {
            return UiUtils.parseUnixMsTime(itemTime);
        },
        resetSystemMessages() {
            this.$store.commit("resetSystemMessages");
        }
    },
    computed: {
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },      
        getMessagesLabel() {
            return Translations.getMessagesLabel(this.getUserLanguage);
        },
        getClearAllLabel() {
            return Translations.getClearAllLabel(this.getUserLanguage);
        },
        getDateLabel() {
            return Translations.getDateLabel(this.getUserLanguage);
        },
        getTypeLabel() {
            return Translations.getTypeLabel(this.getUserLanguage);
        },
        getMessageLabel() {
            return Translations.getMessageLabel(this.getUserLanguage);
        },
        getItemsLabel() {
            return Translations.getItemsLabel(this.getUserLanguage);
        },
        getSystemMessages() {
            const messages = this.$store.getters["getSystemMessages"];
            let localMessages = [];
            // copy messages to avoid ercursive updates
            for (let cnt = 0; cnt < messages.length; cnt ++) {
                localMessages.push(messages[cnt]);
            }
            // order descending
            UiUtils.sortByDate(localMessages, "date", true).reverse();
            return localMessages;
        },
        getSystemMessagesCount() {
            return this.getSystemMessages.length;
        }
    }
}
</script>