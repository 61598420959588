/* eslint-disable */
<template>
  <header v-if="$store.getters.isAppReady && $store.getters.isUserLogged">
    <nav class="navbar navbar-expand-lg navbar-light">
      <router-link class="navbar-brand" to="/">
        <img id="efocs_logo" src="@/assets/images/EFOCS_Logo_Horizontal.svg" alt="" @click.prevent="goToDashboard()" />
      </router-link>
      <div v-if="1200 < getWindowsWidth" id="exchangeSelectArea" class="input-group">
        <select id="selectExchange" class="form-select" v-model="selectedExchange">
          <option value="0">{{ getAllExchangesLabel }}</option>
          <option v-for="exchange in getExchanges" :key="exchange.eID" :value="exchange.eID">{{
            exchange.code }}
          </option>
        </select>
      </div>
      <div v-if="1200 < getWindowsWidth" id="searchContainer">
        <div class="row">
          <div id="searchArea" class="input-group">
            <div class="input-group-prepend">
              <button class="btn btn-outline-secondary btn-light" id="inputGroup-sizing-sm"
                @click.prevent="forceSearch">
                <i class="icon ion-md-search"></i>
              </button>
            </div>
            <input id="searchInput" type="text" class="form-control" :placeholder="getSearchPlaceholder"
              aria-describedby="inputGroup-sizing-sm" v-model="search" ref="seachInput" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary btn-light" type="button"
                @click.prevent="search = ''; $refs.seachInput?.focus();">X</button>
            </div>
          </div>
        </div>
        <!-- dropdown search -->
        <div class="row">
          <div id="searchResults" class="card" v-if="2 < search.length">
            <div class="card-body">
              <div id="searchResultsContainer" class="container">
                <div id="searchResultsDisplayContainer">
                  <div v-if="0 !== getXbulSearchedStocks.length" class="search-item row mtb15">
                    <div class="col"></div>
                    <div class="col-sm-2 search-exchange">XBUL</div>
                  </div>
                  <div class="search-item row mtb15" v-for="(stock, index) in getXbulSearchedStocks" :key="index"
                    @click="openExtendedInfo(stock)">
                    <div class="col">
                      <div class="row">{{ stock.code }}</div>
                      <div class="row">{{ stock.nEN }}</div>
                    </div>
                    <div class="col-sm-2 mtb15">&rarr;</div>
                    <hr>
                  </div>
                  <div v-if="0 !== getXetrSearchedStocks.length" class="search-item row mtb15">
                    <div class="col"></div>
                    <div class="col-sm-2 search-exchange">XETR</div>
                  </div>
                  <div class="search-item row mtb15" v-for="(stock, index) in getXetrSearchedStocks" :key="index"
                    @click="openExtendedInfo(stock)">
                    <div class="col">
                      <div class="row">{{ stock.code }}</div>
                      <div class="row">{{ stock.nEN }}</div>
                    </div>
                    <div class="col-sm-2 mtb15">&rarr;</div>
                    <hr>
                  </div>
                  <div v-if="0 !== getXfraSearchedStocks.length" class="search-item row mtb15">
                    <div class="col"></div>
                    <div class="col-sm-2 search-exchange">XFRA</div>
                  </div>
                  <div class="search-item row mtb15" v-for="(stock, index) in getXfraSearchedStocks" :key="index"
                    @click="openExtendedInfo(stock)">
                    <div class="col">
                      <div class="row">{{ stock.code }}</div>
                      <div class="row">{{ stock.nEN }}</div>
                    </div>
                    <div class="col-sm-2 mtb15">&rarr;</div>
                    <hr>
                  </div>
                  <div
                    v-if="0 === getXbulSearchedStocks.length && 0 === getXetrSearchedStocks.length && 0 === getXfraSearchedStocks.length">
                    <strong>{{ getNoMatchingInstrumentsFoundLabel }}</strong><br />
                    {{ getRefineYourKeywordsLabel }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- user menu -->
      <button v-if="1200 < getWindowsWidth" class="navbar-toggler" type="button" data-bs-toggle="collapse"
        data-bs-target="#headerNav" aria-controls="headerNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div v-if="1200 < getWindowsWidth" class="collapse navbar-collapse " id="headerNav">
        <ul class="navbar-nav ml-auto ">
          <li class="nav-item dropdown header-img-icon">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown5" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              <div class="container">
                <div class="img-overlay-wrap">
                  <img src="../assets/images/EFOCS_Avatar.svg" class="header-avatar-image rounded-circle"
                    alt="avatar" />
                  <svg class="svg-rect" width="80" height="20">
                    <rect x="0" y="0" rx="3" ry="3" width="80" height="20" :fill="getUserTypeFillColor"></rect>
                    <text class="avatar_text pt-4" x="50%" y="50%" text-anchor="middle" stroke="white"
                      stroke-width="1px" dy=".3em">{{ getUserTypeString }}</text>
                  </svg>
                </div>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown5">
              <div class="dropdown-body">
                <ul class="profile-nav">
                  <li class="nav-item">
                    <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'main' } }" class="nav-link">
                      <i class="icon ion-md-lock"></i>
                      <span>{{ getMainLabel }}</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'currency' } }" class="nav-link">
                      <i class="icon ion-logo-euro"></i>
                      <span>{{ getCurrencyMenuItem }}</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'language' } }" class="nav-link">
                      <i class="icon ion-md-flag"></i>
                      <span>{{ getLanguageMenuItem }}</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'password' } }" class="nav-link">
                      <i class="icon ion-md-lock"></i>
                      <span>{{ getPasswordMenuItem }}</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'Login' }" class="nav-link red" @click="doLogout()">
                      <i class="icon ion-md-power"></i>
                      <span>{{ getLogoutMenuItem }}</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </ul>
          </li>
        </ul>
      </div>
      <!-- responsive layout -->
      <div v-if="1200 > getWindowsWidth" class="input-group d-flex justify-content-end">
        <div id="exchangeSelectArea" class="input-group">
          <select id="selectExchange" class="form-select form-select-sm" v-model="selectedExchange">
            <option value="0">{{ getAllExchangesLabel }}</option>
            <option v-for="exchange in getExchanges" :key="exchange.eID" :value="exchange.eID">{{
              exchange.code }}
            </option>
          </select>
        </div>
        <!-- Modal search on lower resolutions -->
        <div v-if="1200 > getWindowsWidth" class="modal fade" tabindex="-1" role="dialog"
          aria-labelledby="searchInstrument" aria-hidden="true"
          :class="{ 'show': searchModalShow, 'd-block': searchModalActive }">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="searchInstrument">{{ getSearchPlaceholder }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="closeModal()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div id="searchContainerModal" class="container-fluid">
                  <div class="row">
                    <div id="searchAreaModal" class="input-group">
                      <div class="input-group-prepend">
                        <button class="btn btn-outline-secondary btn-light" id="inputGroup-sizing-sm-modal"
                          @click.prevent="forceSearch">
                          <i class="icon ion-md-search"></i>
                        </button>
                      </div>
                      <input id="searchInputModal" type="text" class="form-control" :placeholder="getSearchPlaceholder"
                        aria-describedby="inputGroup-sizing-sm-modal" v-model="search" ref="searchInputModal" />
                      <div class="input-group-append">
                        <button class="btn btn-outline-secondary btn-light" type="button"
                          @click.prevent="search = ''; $refs.searchInputModal?.focus();">X</button>
                      </div>
                    </div>
                  </div>
                  <!-- dropdown search -->
                  <div class="row">
                    <div id="searchResultsModal" class="card" v-if="2 < search.length">
                      <div class="card-body">
                        <div id="searchResultsContainerModal" class="container">
                          <div id="searchResultsDisplayContainerModal">
                            <div class="search-item row mtb15" v-for="(stock, index) in getSearchedStocks" :key="index"
                              @click="openExtendedInfo(stock)">
                              <div class="col-sm-2 search-exchange">{{ stock.xc }}</div>
                              <div class="col">({{ stock.code }})&nbsp;{{ stock.nEN }}</div>
                            </div>
                            <div v-if="0 === getSearchedStocks.length">
                              <strong>{{ getNoMatchingInstrumentsFoundLabel }}</strong><br />
                              {{ getRefineYourKeywordsLabel }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="1200 < getWindowsWidth" id="searchContainer">
          <div class="row">
            <div id="searchArea" class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm" @click.prevent="forceSearch">
                  <i class="icon ion-md-search"></i>
                </span>
              </div>
              <input id="searchInput" type="text" class="form-control" :placeholder="getSearchPlaceholder"
                aria-describedby="inputGroup-sizing-sm" v-model="search" ref="seachInput" />
              <div class="input-group-append">
                <button class="btn btn-secondary" type="button"
                  @click.prevent="search = ''; $refs.seachInput?.focus();">X</button>
              </div>
            </div>
          </div>
          <!-- dropdown search -->
          <div class="row">
            <div id="searchResults" class="card" v-if="2 < search.length">
              <div class="card-body">
                <div id="searchResultsContainer" class="container">
                  <div id="searchResultsDisplayContainer">
                    <div class="search-item row mtb15" v-for="(stock, index) in getSearchedStocks" :key="index"
                      @click="openExtendedInfo(stock)">
                      <div class="col-sm-2 search-exchange">{{ stock.xc }}</div>
                      <div class="col">({{ stock.code }})&nbsp;{{ stock.nEN }}</div>
                    </div>
                    <div v-if="0 === getSearchedStocks.length">
                      <strong>{{ getNoMatchingInstrumentsFoundLabel }}</strong><br />
                      {{ getRefineYourKeywordsLabel }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- show search button -->
        <button v-if="1200 > getWindowsWidth" class="btn btn-sm ms-1 me-1" @click.prevent="openModal">
          <i class="icon ion-md-search"></i>
        </button>
        <!-- user menu -->
        <button v-if="1200 < getWindowsWidth" class="btn btn-sm navbar-toggler" type="button" data-bs-toggle="collapse"
          data-bs-target="#headerNav" aria-controls="headerNav" aria-expanded="true" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="1200 < getWindowsWidth" class="collapse navbar-collapse " id="headerNav">
          <ul class="navbar-nav ml-auto ">
            <!--<li class="nav-item dropdown header-custom-icon">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown4" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" @click="hasOpenedMessages = true">
              <i class="icon ion-md-notifications"></i>
              <span v-if="!hasOpenedMessages && 0 !== getSystemMessagesCount" class="circle-pulse"></span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown4">
              <div class="
                  dropdown-header
                  d-flex
                  align-items-center
                  justify-content-between
                ">
                <p class="mb-0 font-weight-medium">{{ getSystemMessagesCount }} {{ getNewNotificationsLabel }}</p>
                <a v-if="0 !== getSystemMessagesCount" href="#!" class="text-muted"
                  @click.prevent="resetSystemMessages">{{ getClearAllLabel }}</a>
              </div>
              <div class="dropdown-body">
                <a href="#!" v-for="(message) in getSystemMessages" :key="message.date" class="dropdown-item">
                  <div class="icon">
                    <i v-if="!message.error" class="icon ion-md-lock"></i>
                    <i v-else class="icon ion-md-alert"></i>
                  </div>
                  <div class="content">
                    <p>{{message.mess}}</p>
                    <p class="sub-text text-muted">{{ parseUnixMsTime(message.date) }}</p>
                  </div>
                </a>
              </div>
              <div class="dropdown-footer d-flex align-items-center justify-content-center">
                <router-link :to="{ name: 'Messages'}">{{ getViewAllLabel }}</router-link>
              </div>
            </ul>
          </li>-->
            <li class="nav-item dropdown header-img-icon">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown5" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <div class="container">
                  <div class="img-overlay-wrap">
                    <img src="../assets/images/EFOCS_Avatar.svg" class="header-avatar-image rounded-circle"
                      alt="avatar" />
                    <svg class="svg-rect" width="80" height="20">
                      <rect x="0" y="0" rx="3" ry="3" width="80" height="20" :fill="getUserTypeFillColor"></rect>
                      <text class="avatar_text pt-4" x="50%" y="50%" text-anchor="middle" stroke="white"
                        stroke-width="1px" dy=".3em">{{ getUserTypeString }}</text>
                    </svg>
                  </div>
                </div>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown5">
                <div class="dropdown-body">
                  <ul class="profile-nav">
                    <li class="nav-item">
                      <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'main' } }" class="nav-link">
                        <i class="icon ion-md-lock"></i>
                        <span>{{ getMainLabel }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'currency' } }" class="nav-link">
                        <i class="icon ion-logo-euro"></i>
                        <span>{{ getCurrencyMenuItem }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'language' } }" class="nav-link">
                        <i class="icon ion-md-flag"></i>
                        <span>{{ getLanguageMenuItem }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'password' } }" class="nav-link">
                        <i class="icon ion-md-lock"></i>
                        <span>{{ getPasswordMenuItem }}</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link :to="{ name: 'Login' }" class="nav-link red" @click="doLogout()">
                        <i class="icon ion-md-power"></i>
                        <span>{{ getLogoutMenuItem }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </ul>
            </li>
          </ul>
        </div>
        <!-- Modal show menu -->
        <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="showMenu" aria-hidden="true"
          :class="{ 'show': menuModalShow, 'd-block': menuModalActive }">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">{{ getMenuLabel }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  @click="menuModalShow = false; menuModalActive = false;">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#headerNav"
                    aria-controls="headerNav" aria-expanded="true" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                  </button>
                  <div class="collapse navbar-collapse " id="headerNav">
                    <ul class="navbar-nav ml-auto ">
                      <li class="nav-item dropdown header-img-icon">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown5" role="button"
                          data-bs-toggle="dropdown" aria-expanded="false">
                          <div class="container">
                            <div class="img-overlay-wrap">
                              <img src="../assets/images/EFOCS_Avatar.svg" class="header-avatar-image rounded-circle"
                                alt="avatar" />
                              <svg class="svg-rect" width="80" height="20">
                                <rect x="0" y="0" rx="3" ry="3" width="80" height="20" :fill="getUserTypeFillColor">
                                </rect>
                                <text class="avatar_text pt-4" x="50%" y="50%" text-anchor="middle" stroke="white"
                                  stroke-width="1px" dy=".3em">{{ getUserTypeString }}</text>
                              </svg>
                            </div>
                          </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown5">
                          <div class="dropdown-body">
                            <ul class="profile-nav">
                              <li class="nav-item">
                                <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'main' } }"
                                  class="nav-link">
                                  <i class="icon ion-md-lock"></i>
                                  <span>{{ getMainLabel }}</span>
                                </router-link>
                              </li>
                              <li class="nav-item">
                                <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'currency' } }"
                                  class="nav-link">
                                  <i class="icon ion-logo-euro"></i>
                                  <span>{{ getCurrencyMenuItem }}</span>
                                </router-link>
                              </li>
                              <li class="nav-item">
                                <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'language' } }"
                                  class="nav-link">
                                  <i class="icon ion-md-flag"></i>
                                  <span>{{ getLanguageMenuItem }}</span>
                                </router-link>
                              </li>
                              <li class="nav-item">
                                <router-link :to="{ name: 'Profile', params: { selectedMenuItem: 'password' } }"
                                  class="nav-link">
                                  <i class="icon ion-md-lock"></i>
                                  <span>{{ getPasswordMenuItem }}</span>
                                </router-link>
                              </li>
                              <li class="nav-item">
                                <router-link :to="{ name: 'Login' }" class="nav-link red" @click="doLogout()">
                                  <i class="icon ion-md-power"></i>
                                  <span>{{ getLogoutMenuItem }}</span>
                                </router-link>
                              </li>
                            </ul>
                          </div>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <Menu></Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- user menu in responsive -->
        <button v-if="1200 > getWindowsWidth" type="button" class="btn btn-sm"
          @click.prevent="menuModalShow = true; menuModalActive = true;">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import http from "@/axios/http-common";
import UiUtils from "@/assets/js/ui_utils.js";
import ApiUtils from "@/assets/js/api_utils.js";
import Translations from "@/lang/translations.js";
import Menu from './Menu.vue';

export default {
  components: {
    Menu
  },
  data() {
    return {
      /* the searching user typed in input */
      search: "",
      /* flag indicating there are messages not seen by user */
      hasOpenedMessages: false,
      /* 0 - all exchanges, other: exchange id */
      selectedExchange: 0,      
      lastTypechange: 0,
      searchModalShow: false,
      searchModalActive: false,
      menuModalShow: false,
      menuModalActive: false,
    }
  },
  watch: {
    getSystemMessagesCount: function(newVal, oldVal) {
      if (oldVal < newVal) {
        this.hasOpenedMessages = false;
      }
    },
    search: function (newVal) {
      // check search
      this.searchModalActive = 0 !== newVal.length;
      (async () => await this.performSearch())();
    },
    selectedExchange: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.commit("setSelectedExchangeCode", parseInt(newVal));
      }
    },
    getCurrentRouteName: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search = "";
      }
    },
    $route(to, from) {
      this.menuModalShow = false; 
      this.menuModalActive = false;
    }
  },
  updated() {
    if (this.search) {
      // after components update during search we lose focus
      // so we reset it here
      this.$nextTick(() => {
        this.$refs.seachInput?.focus();
      });
    }
  },
  methods: { 
    openModal() {
      this.searchModalShow = true;
      this.searchModalActive = true;
    },
    closeModal() {
      this.searchModalShow = false;
      this.searchModalActive = false;
    },
    async searchApi(code, name) {
      const user = this.$store.getters["getUser"];
      // get exchanges and stocks
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "search",
            id: user.id,
            key: user.key,
            sc: code ?? null,
            name: name ?? null
          }
        });
        if (res && 0 === res.data.ec) {
          // update searched stocks
          this.$store.commit("setSearchedStocks", res.data.list);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async performSearch() {
      this.$store.commit("setHasItemInSearch", this.search);
      let trimmedNewVal = this.search.trim();
      if (3 > trimmedNewVal.length) {
        // update searched stocks
        this.$store.commit("setSearchedStocks", []);
        return;
      }
      // filter stocks
      // if search starts with / the we search stock codes only
      // if search starts with // we search name only
      // else we search everything
      if (this.search.startsWith("//")) {
        // names
        trimmedNewVal = trimmedNewVal.substring(2);
        await this.searchApi(null, trimmedNewVal);
      } else if (this.search.startsWith("/")) {
        // codes
        trimmedNewVal = trimmedNewVal.substring(1);
        await this.searchApi(trimmedNewVal, null);
      } else {
        // all
        await this.searchApi(trimmedNewVal, trimmedNewVal);
      }
    },
    getExchangeCodeString(code) {
      const exchanges = this.$store.getters["getExchanges"];
      return UiUtils.getExchangeCodeById(exchanges, code);
    },    
    getUserType() {
      return this.$store.getters["getUserType"];
    },
    toggleDark: function () {
      this.$store.commit("changeTheme");
      document.body.classList.toggle("dark");
    },
    async doLogout() {
      let user = this.$store.getters["getUser"];
      await http.getApiClient(this.getUserType()).get("", {
        params: {
          a: "logout",
          id: user.id,
          key: user.key
        }
      })
        .then(res => {
          if (0 === res.data.ec) {
            // reset store to avoid users access to other user's data
            this.$store.commit("resetStoreState", null);
            // redirect
            this.$store.commit("setUser", null);
            // hide headers
            this.$store.setAppStateNotReady;
            // reset all values
            this.search = "";
            this.hasOpenedMessages = false;
            this.lastTypechange = 0;
            this.$router.push({ name: "Login" });
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    getUserLanguage() {
      return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
    },
    resetSystemMessages() {
      this.$store.commit("resetSystemMessages");
    },
    parseUnixMsTime(itemTime) {
      return UiUtils.parseUnixMsTime(itemTime);
    },
    forceSearch() {
      this.search = this.search + " ";
    },
    openExtendedInfo(stock) {
      let stockCMic = 1;
      if (0 === 'XETR'.localeCompare(stock.xc?.toUpperCase())) {
        stockCMic = 2;
      } else if (0 === 'XFRA'.localeCompare(stock.xc?.toUpperCase())) {
        stockCMic = 3;
      }
      this.$store.commit("setSelectedTradingCode", stock.code);
      this.$store.commit("setSelectedTradingCodeSegment", stock.sgm);
      this.$store.commit("setSelectedTradingCodeShareTypeId", stock.tID);
      this.$store.commit("setSelectedTradingExchangeId", stockCMic);
      this.$store.commit("setSelectedTradingStockName", 
        0 === "EN".localeCompare(this.getUserLanguage().toUpperCase())
        ? stock.nEN
        : stock.nBG ? stock.nBG : stock.nEN);
      // hide search dropdown
      this.search = "";
      // move to exdended info if not already there
      if (0 !== "ExtendedStock".localeCompare(this.$route.name)) {
        this.$router.push({ name: "ExtendedStock", params: { stockCode: stock.code, itemExchangeId: stockCMic, itemStockCodeSegment: stock.sgm } });
      }
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    }
  },
  computed: {
    getWindowsWidth() {
      return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
    },
    getCurrentRouteName() {
      return this.$route.name;
    },
    getSearchedStocksFromState() {
      return this.$store.getters["getSearchedStocks"];
    },
    getSystemMessages() {
      const messages = this.$store.getters["getSystemMessages"];
      let localMessages = [];
      // copy messages to avoid ercursive updates
      for (let cnt = 0; cnt < messages.length; cnt++) {
        localMessages.push(messages[cnt]);
      }
      // order descending
      UiUtils.sortByDate(localMessages, "date", true).reverse();
      return localMessages;
    },
    getSystemMessagesCount() {
      return this.getSystemMessages.length;
    },
    getPasswordMenuItem() {
      return Translations.getPasswordMenuItem(this.getUserLanguage());
    },
    getLogoutMenuItem() {
      return Translations.getLogoutMenuItem(this.getUserLanguage());
    },
    getSearchPlaceholder() {
      return Translations.getSearchPlaceholder(this.getUserLanguage());
    },
    getNewNotificationsLabel() {
      return Translations.getNewNotificationsLabel(this.getUserLanguage());
    },
    getClearAllLabel() {
      return Translations.getClearAllLabel(this.getUserLanguage());
    },
    getViewAllLabel() {
      return Translations.getViewAllLabel(this.getUserLanguage());
    },
    getUserTypeString() {
      const userType = this.$store.getters["getUserType"];
      return 0 === userType ? 
        Translations.getDemoLabel(this.getUserLanguage()) : 
        Translations.getLiveLabel(this.getUserLanguage());
    },
    getUserTypeFillColor() {
      const userType = this.$store.getters["getUserType"];
      return 0 === userType ?
        "#0F7392" :
        "#EC6024";
    },    
    getLanguageMenuItem() {
      return Translations.getLanguageMenuItem(this.getUserLanguage());
    },
    getCurrencyMenuItem() {
      return Translations.getCurrencyMenuItem(this.getUserLanguage());
    },
    getAllExchangesLabel() {
      return Translations.getAllExchangesLabel(this.getUserLanguage());
    },
    getExchanges() {
      return this.$store.getters["getExchanges"];
    },
    getCloseLabel() {
      return Translations.getCloseLabel(this.getUserLanguage());
    },
    getXbulSearchedStocks() {
      const stocks = this.getSearchedStocksFromState;
      if (!stocks || 0 === stocks.length) {
        return [];
      }
      // we want to sort the found stocks
      // Instrument | Exchange
      let sortedStocks = [];
      for (let stCnt = 0; stCnt < stocks.length; stCnt++) {
        if (0 === 'XBUL'.localeCompare(stocks[stCnt]?.xc?.toUpperCase())) {
          sortedStocks.push(stocks[stCnt]);
        }
      }

      return sortedStocks;
    },
    getXetrSearchedStocks() {
      const stocks = this.getSearchedStocksFromState;
      if (!stocks || 0 === stocks.length) {
        return [];
      }
      // we want to sort the found stocks
      // Instrument | Exchange
      let sortedStocks = [];
      for (let stCnt = 0; stCnt < stocks.length; stCnt++) {
        if (0 === 'XETR'.localeCompare(stocks[stCnt]?.xc?.toUpperCase())) {
          sortedStocks.push(stocks[stCnt]);
        }
      }

      return sortedStocks;
    },
    getXfraSearchedStocks() {
      const stocks = this.getSearchedStocksFromState;
      if (!stocks || 0 === stocks.length) {
        return [];
      }
      // we want to sort the found stocks
      // Instrument | Exchange
      let sortedStocks = [];
      for (let stCnt = 0; stCnt < stocks.length; stCnt++) {
        if (0 === 'XFRA'.localeCompare(stocks[stCnt]?.xc?.toUpperCase())) {
          sortedStocks.push(stocks[stCnt]);
        }
      }

      return sortedStocks;
    },
    getSearchedStocks() {
      const stocks = this.getSearchedStocksFromState;
      if (!stocks || 0 === stocks.length) {
        return [];
      }
      // we want to sort the found stocks
      // Instrument | Exchange
      const exchanges = this.getExchanges;
      if (!exchanges || 0 === exchanges.length) {
        // return unsorted
        return stocks;
      }
      let sortedStocks = [];
      // note: o(^2)
      for (let exCnt = 0; exCnt < exchanges.length; exCnt ++) {
        for (let stCnt = 0; stCnt < stocks.length; stCnt ++) {
          if (0 === stocks[stCnt]?.xc?.toLowerCase().localeCompare(exchanges[exCnt]?.code?.toLowerCase())) {
            sortedStocks.push(stocks[stCnt]);
          }
        }
      }

      return sortedStocks;
    }, 
    getNoMatchingInstrumentsFoundLabel() {
      return Translations.getNoMatchingInstrumentsFoundLabel(this.getUserLanguage());
    }, 
    getRefineYourKeywordsLabel() {
      return Translations.getRefineYourKeywordsLabel(this.getUserLanguage());
    }, 
    getMainLabel() {
      return Translations.getMainLabel(this.getUserLanguage());
    },
    getMenuLabel() {
      return Translations.getMenuLabel(this.getUserLanguage());
    },
  }
};
</script>

<style scoped>
  .navbar-nav {
    margin-left: auto;
  }
  #searchArea {
    margin-left: 0.7rem;
    max-width: 32rem;
    position: relative;
  }
  #exchangeSelectArea {
    margin-left: 0.7rem;
    max-width: 16rem;
  }
  .input-group-prepend {
    cursor: pointer;
  }

  #efocs_logo {
    width: 50%; 
    height: auto;
  }

  #searchResults {
    position: absolute;
    display: flex;
    flex-direction: column; 
    float: left;
    z-index: 999;
    overflow: hidden;
    border:1px solid;
    color: black;
    max-width: 40rem;
  }

  #searchResults .card-body {
    background-color: white;
    width: 100%;
  }

  #searchResultsContainer {
    max-height: 10rem;
    max-width: auto;
    display: flex;
    flex: 1;
    min-height: 0px;
  }

  #searchResultsDisplayContainer {
    flex: 1;
    overflow: auto;
  }

  .search-exchange {
    background-color: #E0E7F7;
    color: blue;
  }

  .search-item {
    cursor: pointer;
  }

  #exchangeSelectArea {
    max-width: 10rem;
    max-height: auto;
  }

  #searchContainer {
    width: 40rem;
    height: auto;
  }

  .avatar_text {
    top:0;
    left:0;
    font-size: xx-small;
  }

.img-overlay-wrap {
  position: relative;
  display: inline-block; /* <= shrinks container to image size */
  transition: transform 150ms ease-in-out;
}

.img-overlay-wrap img { /* <= optional, for responsiveness */
   display: block;
   max-width: 100%;
   height: auto;
}

.img-overlay-wrap svg {
  position: absolute;
  top: 80%;
  left: -90%;
}
</style>