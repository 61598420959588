import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Notfound from "../views/Notfound.vue";
import Profile from "../views/Profile.vue";
import Reset from "../views/Reset.vue";
import Signup from "../views/Signup.vue";
import Wallet from "../views/Wallet.vue";
import ExtendedStock from "../views/ExtendedStock.vue";
import Messages from "../views/Messages.vue";
import TradeDetails from "../views/TradeDetails.vue";
import OrderDetails from "../views/OrderDetails.vue";
import WalletBreakdownByExchange from "../views/WalletBreakdownByExchange.vue";
import TransactionDetails from "../views/TransactionDetails.vue";

import store from "../store/index";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }
  },
  {
    path: "/extended",
    name: "ExtendedStock",
    component: ExtendedStock,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    props: true,
  },
  {
    path: "/404",
    name: "Notfound",
    component: Notfound,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/reset",
    name: "Reset",
    component: Reset,
  },
  {
    path: "/sign-up",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    meta: { requiresAuth: true }
  },
  {
    path: "/trade-details",
    name: "TradeDetails",
    component: TradeDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/order-details",
    name: "OrderDetails",
    component: OrderDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/transaction-details",
    name: "TransactionDetails",
    component: TransactionDetails,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: "/wallet-breakdown",
    name: "WalletBreakdownByExchange",
    component: WalletBreakdownByExchange,
    props: true,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  try {
    if (!to?.name) {
      next({ name: "Notfound" })
    } else if (to?.matched.some(record => record.meta.requiresAuth)) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      if (!store.getters["isUserLogged"]) {
        next({ name: "Login" });
      } else {
        next(); // go to wherever I'm going
      }
    } else {
      next(); // does not require auth, make sure to always call next()!
    }
  } catch (error) {
    console.error(error);
    next({ name: "Login" });
  }
})

export default router;
