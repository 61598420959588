/* eslint-disable */
<template>
  <div class="container-fluid mtb15 no-fluid">
    <div class="row sm-gutters">
      <!-- Menu -->
      <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
        <Menu></Menu>
      </div>
      <!-- Modal trade confirmed -->
      <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="tradeConfirmedLabel" aria-hidden="true"
        :class="{ 'show': tradeConfirmedModalShow, 'd-block': tradeConfirmedModalActive }">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <TradeConfirmed :order="orderModel" />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                @click="tradeConfirmedModalActive = false; tradeConfirmedModalShow = false;">{{
                getCloseLabel
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal confirm order cancel -->
      <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteLabel" aria-hidden="true"
        :class="{ 'show': cancelOrderModalShow, 'd-block': cancelOrderModalActive }">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="confirmDeleteLabel">{{ getConfirmDeleteValueLabel }}</h5>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-dismiss="modal"
                @click="cancelOrder(); cancelOrderModalActive = false; cancelOrderModalShow = false;">{{
                getConfirmLabel
                }}</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                @click="cancelOrderModalActive = false; cancelOrderModalShow = false;">{{
                getCloseLabel
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="cancelOrderModalActive" class="modal-backdrop fade show"></div>
      <!-- Modal order update -->
      <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteLabel" aria-hidden="true"
        :class="{ 'show': editOrderModalShow, 'd-block': editOrderModalActive }">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <Trading :id="$store.getters['getSelectedTradingStockName']"></Trading>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                @click="editOrderModalActive = false; editOrderModalShow = false;">{{
                getCloseLabel
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="editOrderModalActive" class="modal-backdrop fade show"></div>
      <!-- Modal transaction details -->
      <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="transactionDetailsLabel" aria-hidden="true"
        :class="{ 'show': transactionDetailsModalShow, 'd-block': transactionDetailsModalActive }">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="transactionDetailsLabel">{{ getTransactionDetailsLabel }}</h5>
            </div>
            <div class="modal-body">
              <div class="table-responsive">
                <table class="table">
                  <tbody v-if="null !== transactionDetails">
                    <tr>
                      <td>{{ getDateLabel }}</td>
                      <td class="price-data">{{ getDate(transactionDetails.ts) }}</td>
                    </tr>
                    <tr>
                      <td>{{ getOrderIdLabel }}</td>
                      <td class="price-data">{{ transactionDetails.oid }}</td>
                    </tr>
                    <tr>
                      <td>{{ getCurrencyLabel }}</td>
                      <td class="price-data">{{ transactionDetailsStockCurrency }}</td>
                    </tr>
                    <tr>
                      <td>{{ getStockCodeLabel }}</td>
                      <td class="price-data">{{ transactionDetailsStockCode }}</td>
                    </tr>
                    <tr>
                      <td>{{ getStockNameLabel }}</td>
                      <td class="price-data" v-if="'BG' === getUserLanguage && transactionDetailsStockNameBg">{{
                        transactionDetailsStockNameBg }}</td>
                      <td class="price-data" v-else>{{
                        transactionDetailsStockNameEn }}</td>
                    </tr>
                    <tr>
                      <td>{{ getTypeLabel }}</td>
                      <td class="price-data">{{ getOrderType(transactionDetails.tt) }}</td>
                    </tr>
                    <tr>
                      <td>{{ getQuantityLabel }}</td>
                      <td class="price-data">{{ parseNumber(transactionDetails.qty, 0) }}</td>
                    </tr>
                    <tr>
                      <td>{{ getPriceLabel }}</td>
                      <td class="price-data">{{ parseNumber(transactionDetails.sp) }}</td>
                    </tr>
                    <tr>
                      <td>{{ getPriceТotalLabel }}</td>
                      <td class="price-data">{{ parseNumber(transactionDetails.pt) }}</td>
                    </tr>
                    <tr>
                      <td>{{ getFeeLabel }}</td>
                      <td class="price-data">{{ transactionDetails.fee ? parseNumber(transactionDetails.fee) :
                        parseNumber(0) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal"
                @click="transactionDetailsModalActive = false; transactionDetailsModalShow = false;">{{
                getCloseLabel
                }}</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="transactionDetailsModalShow" class="modal-backdrop fade show"></div>
      <!-- Wallet -->
      <div class="col-sm-12" :class="{ 'col-md-10' :  1200 < getWindowsWidth }">
        <div class="settings mtb15">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-2">
                <ul class="nav nav-tabs nav-pills"
                  :class="{ 'settings-nav': 1200 < getWindowsWidth, 'show_list_horizontal text-nowrap': 1200 > getWindowsWidth }"
                  id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"
                      :class="{ 'active': 'Summary' === activeMenuSelection, 'xsmall-font-size': 1200 > getWindowsWidth }"
                      id="Summary-tab" data-bs-toggle="tab" data-bs-target="#Summary" type="button" role="tab"
                      aria-controls="Summary" aria-selected="true" @click="activeMenuSelection = 'Summary';">
                      {{ getSummaryMenuItem.toUpperCase() }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"
                      :class="{ 'active': 'Cash' === activeMenuSelection, 'xsmall-font-size': 1200 > getWindowsWidth }"
                      id="Cash-tab" data-bs-toggle="tab" data-bs-target="#Cash" type="button" role="tab"
                      aria-controls="Cash" aria-selected="true" @click="activeMenuSelection = 'Cash';">
                      {{ getMoneyLabel.toUpperCase() }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"
                      :class="{ 'active': 'Equities' === activeMenuSelection, 'xsmall-font-size': 1200 > getWindowsWidth }"
                      id="Equities-tab" data-bs-toggle="tab" data-bs-target="#Equities" type="button" role="tab"
                      aria-controls="Equities" aria-selected="false" @click="activeMenuSelection = 'Equities'">
                      {{ getPositionsMenuItem.toUpperCase() }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"
                      :class="{ 'active': 'Orders' === activeMenuSelection, 'xsmall-font-size': 1200 > getWindowsWidth }"
                      id="Orders-tab" data-bs-toggle="tab" data-bs-target="#Orders" type="button" role="tab"
                      aria-controls="Orders" aria-selected="false" @click="activeMenuSelection = 'Orders'">
                      {{ getOrdersMenuItem.toUpperCase() }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"
                      :class="{ 'active': 'Trades' === activeMenuSelection, 'xsmall-font-size': 1200 > getWindowsWidth }"
                      id="Trades-tab" data-bs-toggle="tab" data-bs-target="#Trades" type="button" role="tab"
                      aria-controls="Trades" aria-selected="false" @click="activeMenuSelection = 'Trades'">
                      {{ getTradesMenuItem.toUpperCase() }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link"
                      :class="{ 'active': 'Transactions' === activeMenuSelection, 'xsmall-font-size': 1200 > getWindowsWidth }"
                      id="Transactions-tab" data-bs-toggle="tab" data-bs-target="#Transactions" type="button" role="tab"
                      aria-controls="Transactions" aria-selected="false" @click="activeMenuSelection = 'Transactions'">
                      {{ getTransactionsMenuItem.toUpperCase() }}
                    </button>
                  </li>
                </ul>
              </div>
              <div class="col-lg-10">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade"
                    :class="{ 'active': 'Summary' === activeMenuSelection, 'show': 'Summary' === activeMenuSelection }"
                    id="Summary" role="tabpanel" aria-labelledby="Summary-tab">
                    <!-- graph -->
                    <div class="d-flex align-items-center justify-content-center">
                      <GPieChart :height="200" :data="[parseFloat(totalEquitiesValue), parseFloat(getFreeFunds)]" :lbls="[getPositionsLabel, getFreeFundsLabel]"></GPieChart>
                    </div>
                    <!-- total money and equities -->
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table ">
                          <tbody>
                            <tr>
                              <td class="equity_align_left" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                <span class="dot_money" />&nbsp;{{ getFreeFundsLabel }}
                              </td>
                              <td class="equity_align_right" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                {{ formatPrice(getFreeFunds, getCurrency, 2).replace(' ', '&nbsp;') }}
                              </td>
                              <td class="equity_align_right" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                {{ parseNumber(getMoneyPercentage) }}%
                              </td>
                            </tr>                            
                            <tr>
                              <td class="equity_align_left" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                <span class="dot_instruments" />&nbsp;{{ getPositionsLabel }}
                              </td>
                              <td class="equity_align_right" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                {{ formatPrice(totalEquitiesValue, getCurrency, 2).replace(' ', '&nbsp;') }}
                              </td>
                              <td class="equity_align_right" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                {{ parseNumber(getEquitiesPercentage) }}%
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3" class="data-column-large">
                                <strong>{{ getExcessFundsLabel }}&nbsp;{{ formatPrice(getExcessFunds, getCurrency, 2).replace(' ', '&nbsp;') }}</strong>
                              </td>
                            </tr>                            
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- money available -->
                    <div class="row">
                      <div class="row row-eq-height">
                        <div class="col-sm-12 col-md-4">
                          <div class="card h-100">
                            <div class="card-body">
                              <img
                                :class="{ 'card-img-top': 1200 < getWindowsWidth, 'card-img-top-responsive': 1200 > getWindowsWidth }"
                                src="../assets/images/bfb_logo.png" alt="Bulgarian Fund Exchange">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td v-if="1200 > getWindowsWidth"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getBaseCurrencyLabel }}
                                    </td>
                                    <td v-else>{{ getPositionsLabel }} ({{ getBaseCurrencyLabel }})</td>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                      v-if="'EUR' === getCurrency" class="equity_align_right_heavy">{{
                                      formatPrice(getXbulEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}</td>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                      v-if="'BGN' === getCurrency" class="equity_align_right_heavy">{{
                                      formatPrice(getXbulEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}</td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPositionsLabel }}
                                    </td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ formatPrice(getXbulEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getMyNumberOfInstrumentsLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      parseNumber(getXbulEquitiesPositionsCount, 0) }}</td>
                                  </tr>
                                  <tr v-if="0 < parseFloat(getXbulEquitiesValueInBgn)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getReturnLabel }}</td>
                                    <td v-if="getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn > 0"
                                      class="greenColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      +{{ parseNumber(getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) }}
                                      (+{{ parseNumber(((getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) /
                                      getXbulEquitiesAvgValueInBgn)
                                      * 100) }}%)
                                    </td>
                                    <td v-else-if="getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn < 0"
                                      class="redColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ parseNumber(getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) }}
                                      ({{ parseNumber(((getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) /
                                      getXbulEquitiesAvgValueInBgn)
                                      * 100) }}%)
                                    </td>
                                    <td v-else
                                      class="redColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ parseNumber(0) }}
                                      ({{ parseNumber(0) }}%)
                                    </td> 
                                  </tr>
                                  <tr v-else>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getReturnLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      parseNumber(getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) }}(-%)
                                    </td>
                                  </tr>
                                  <tr v-if="!isNaN(getXbulEquitiesSharesCount)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                      getPositionsBalanceLabel }}</td>
                                    <td v-if="0 !== getAllEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter 
                                        :value="getXbulEquitiesValueInCurrentCurrency / getAllEquitiesValueInCurrentCurrency * 100"
                                        min="0" max="100"></meter>&nbsp;({{
                                      parseNumber(getXbulEquitiesValueInCurrentCurrency /
                                      getAllEquitiesValueInCurrentCurrency * 100) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                      getPositionsBalanceLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-if="!isNaN(getXbulEquitiesSharesCount)"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getTotalBalanceLabel
                                      }}</td>
                                    <td v-if="0 !== totalBalance && 0 != getXbulEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter :value="getXbulEquitiesValueInCurrentCurrency / totalBalance * 100" min="0"
                                        max="100"></meter>&nbsp;({{ (getXbulEquitiesValueInCurrentCurrency /
                                      totalBalance * 100).toFixed(2) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      %{{ getTotalBalanceLabel }}
                                    </td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div class="card h-100">
                            <div class="card-body">
                              <img class="card-img-top" src="../assets/images/Deutsche_Boerse_Xetra_Logo.png"
                                alt="Deutsche Boerse Xetra">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td v-if="1200 > getWindowsWidth"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getBaseCurrencyLabel }}
                                    </td>
                                    <td v-else>{{ getPositionsLabel }} ({{ getBaseCurrencyLabel }})</td>
                                    <td v-if="'EUR' === getCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      formatPrice(getXetrEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}</td>
                                    <td v-if="'BGN' === getCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      formatPrice(getXetrEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}</td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPositionsLabel }}
                                    </td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ formatPrice(getXetrEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getMyNumberOfInstrumentsLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      parseNumber(getXetrEquitiesPositionsCount, 0) }}</td>
                                  </tr>
                                  <tr v-if="0 < parseFloat(getXetrEquitiesValueInEur)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getReturnLabel }}</td>
                                    <td v-if="getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur > 0"
                                      class="greenColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      +{{ parseNumber(getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) }}
                                      (+{{ parseNumber(((getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) /
                                      getXetrEquitiesAvgValueInEur)
                                      * 100) }}%)
                                    </td>
                                    <td v-else-if="getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur < 0"
                                      class="redColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ parseNumber(getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) }}
                                      ({{ parseNumber(((getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) /
                                      getXetrEquitiesAvgValueInEur)
                                      * 100) }}%)
                                    </td>
                                    <td v-else
                                      class="redColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ parseNumber(0) }}
                                      ({{ parseNumber(0) }}%)
                                    </td> 
                                  </tr>
                                  <tr v-else>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getReturnLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      parseNumber(getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) }}(-%)
                                    </td>
                                  </tr>
                                  <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                      getPositionsBalanceLabel }}</td>
                                    <td v-if="0 !== getAllEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter 
                                        :value="getXetrEquitiesValueInCurrentCurrency / getAllEquitiesValueInCurrentCurrency * 100"
                                        min="0" max="100"></meter>&nbsp;({{
                                      parseNumber(getXetrEquitiesValueInCurrentCurrency /
                                      getAllEquitiesValueInCurrentCurrency * 100) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getTotalBalanceLabel
                                      }}</td>
                                    <td v-if="0 !== totalBalance && 0 !== getXetrEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter :value="getXetrEquitiesValueInCurrentCurrency / totalBalance * 100" min="0"
                                        max="100"></meter>&nbsp;({{ parseNumber(getXetrEquitiesValueInCurrentCurrency /
                                      totalBalance * 100) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <div class="card h-100">
                            <div class="card-body">
                              <img class="card-img-top" src="../assets/images/xfra_logo.png" alt="Boerse Frankfurt">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td v-if="1200 > getWindowsWidth"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getBaseCurrencyLabel }}
                                    </td>
                                    <td v-else>{{ getPositionsLabel }} ({{ getBaseCurrencyLabel }})</td>
                                    <td v-if="'EUR' === getCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      formatPrice(getXfraEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}</td>
                                    <td v-if="'BGN' === getCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      formatPrice(getXfraEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}</td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPositionsLabel }}
                                    </td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ formatPrice(getXfraEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getMyNumberOfInstrumentsLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      parseNumber(getXfraEquitiesPositionsCount, 0) }}</td>
                                  </tr>
                                  <tr v-if="0 < parseFloat(getXfraEquitiesValueInEur)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getReturnLabel }}</td>
                                    <td v-if="getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur > 0"
                                      class="greenColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      +{{ parseNumber(getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) }}
                                      (+{{ parseNumber(((getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) /
                                      getXfraEquitiesAvgValueInEur)
                                      * 100) }}%)
                                    </td>
                                    <td v-else-if="getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur < 0"
                                      class="redColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ parseNumber(getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) }}
                                      ({{ parseNumber(((getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) /
                                      getXfraEquitiesAvgValueInEur)
                                      * 100) }}%)
                                    </td>
                                    <td v-else
                                      class="redColor equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ parseNumber(0) }}
                                      ({{ parseNumber(0) }}%)
                                    </td> 
                                  </tr>
                                  <tr v-else>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getReturnLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      parseNumber(getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) }}(-%)
                                    </td>
                                  </tr>
                                  <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                      getPositionsBalanceLabel }}</td>
                                    <td v-if="0 !== getAllEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter
                                        :value="getXfraEquitiesValueInCurrentCurrency / getAllEquitiesValueInCurrentCurrency * 100"
                                        min="0" max="100"></meter>&nbsp;({{
                                      parseNumber(getXfraEquitiesValueInCurrentCurrency /
                                      getAllEquitiesValueInCurrentCurrency * 100) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getTotalBalanceLabel
                                      }}</td>
                                    <td v-if="0 !== totalBalance && 0 !== getXfraEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter :value="getXfraEquitiesValueInCurrentCurrency / totalBalance * 100" min="0"
                                        max="100"></meter>&nbsp;({{ parseNumber(getXfraEquitiesValueInCurrentCurrency /
                                      totalBalance * 100) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                  <tr v-else>
                                    <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div :set="percent = getCurrencyAsPercentFromTotalMoney(money)" v-for="money in getUserMoney"
                        :key="money.c" class="col-sm-12 col-md-6">
                        <div class="card h-100">
                          <div :set="eur2bgn = getMoneyRatio('eur')" class="card-body">
                            <h5 class="card-title">{{ getFreeFundsLabel }} <strong>{{money.c}}</strong></h5>
                            <div class="table-responsive">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td v-if="1200 > getWindowsWidth"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getBaseCurrencyLabel }}
                                    </td>
                                    <td v-else>{{ getBalanceLabel }} ({{ getBaseCurrencyLabel }})</td>
                                    <td class="equity_align_right_heavy" v-if="'BGN' === getCurrency"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ formatPrice(money.a * getMoneyRatio(money.c), getCurrency, 2).replace(' ',
                                      '&nbsp;') }}
                                    </td>
                                    <td class="equity_align_right_heavy" v-if="'EUR' === getCurrency"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ formatPrice(money.a * getMoneyRatio(money.c) / eur2bgn, getCurrency,
                                      2).replace(' ', '&nbsp;') }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                      getBalanceLabel }}</td>
                                    <td class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      {{ formatPrice(money.a, money.c, 2).replace(' ', '&nbsp;') }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getCashLabel
                                      }}</td>
                                    <td v-if="0 !== percent" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter :value="percent" min="0" max="100"></meter>&nbsp;({{
                                      parseNumber(percent) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td>                                    
                                  </tr>
                                  <tr>
                                    <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getTotalBalanceLabel
                                      }}</td>
                                    <td v-if="0 !== getCurrencyAsPercentFromTotalBalance(money)" class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter :value="getCurrencyAsPercentFromTotalBalance(money)" min="0"
                                        max="100"></meter>&nbsp;({{
                                      parseNumber(getCurrencyAsPercentFromTotalBalance(money)) }}%)
                                    </td>
                                    <td v-else class="equity_align_right_heavy"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                      <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                    </td> 
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade"
                    :class="{ 'active': 'Cash' === activeMenuSelection, 'show': 'Cash' === activeMenuSelection }"
                    id="Cash" role="tabpanel" aria-labelledby="Cash-tab">
                    <div class="card w-100">
                      <div class="card-body">
                        <h5 class="card-title">{{ getMoneyLabel }}</h5>
                        <div class="table-responsive">
                          <table class="table">
                            <tbody>
                              <!-- total money -->
                              <tr>
                                <td>{{ getMoneyLabel }}</td>
                                <td class="equity_align_right_heavy">{{ formatPrice(totalAssetsValue, getCurrency, 2).replace(" ", "&nbsp;") }}</td>
                              </tr>
                              <!-- in settlement buy -->
                              <tr>
                                <td>{{ getInSettlementLabel }} (-)</td>
                                <td class="equity_align_right_heavy">{{ formatPrice(getTotalEquitiesInBuySettlementAsMoney, getCurrency, 2).replace(" ", "&nbsp;") }}</td>
                              </tr>
                              <!-- in settlement sell -->
                              <tr>
                                <td>{{ getInSettlementLabel }} (+)</td>
                                <td class="equity_align_right_heavy">{{ formatPrice(getTotalEquitiesInSellSettlementAsMoney, getCurrency, 2).replace(" ", "&nbsp;") }}</td>
                              </tr>
                              <!-- free funds -->
                              <tr>
                                <td>{{ getFreeFundsLabel }}</td>
                                <td class="equity_align_right_heavy">{{ formatPrice(getFreeFunds, getCurrency, 2).replace(" ", "&nbsp;") }}</td>
                              </tr>
                              <!-- guarantee deposit -->
                              <tr>
                                <td>{{ getGuaranteeDepositLabel }} (-)</td>
                                <td class="equity_align_right_heavy">{{ formatPrice(getGuaranteeDeposit, getCurrency, 2).replace(" ", "&nbsp;") }}</td>
                              </tr>
                              <!-- excess funds -->
                              <tr>
                                <td>{{ getExcessFundsLabel }}</td>
                                <td class="equity_align_right_heavy">{{ formatPrice(getExcessFunds, getCurrency, 2).replace(" ", "&nbsp;") }}</td>
                              </tr>
                              <!-- available to withdraw -->
                              <tr>
                                <td>{{ getAvailableToWithdrawLabel }}</td>
                                <td class="equity_align_right_heavy">{{ formatPrice(getAvailableToWithdraw, getCurrency, 2).replace(" ", "&nbsp;") }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div :set="percent = getCurrencyAsPercentFromTotalMoney(money)" v-for="(money) in getUserMoney" :key="money.c" class="col-md-4 col-sm-12">
                        <div class="card">
                          <div class="card-body">
                            <h5 class="card-title">{{ getCashLabel }}&nbsp;{{ money.c }}</h5>
                            <div class="table-responsive">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>{{ getBalanceLabel }}</td>
                                    <td class="equity_align_right_heavy">{{ formatPrice(money.a, money.c, 2).replace(' ', '&nbsp;') }}</td>
                                  </tr>
                                  <tr>
                                    <td colspan="2">
                                      <div class="progress">
                                        <div class="progress-bar progress-bar-animated" role="progressbar" :aria-valuenow="parseNumber(percent)" aria-valuemin="0" aria-valuemax="100" :style="'width: ' + percent + '%'">{{ parseNumber(percent) }}%</div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>{{ getInSettlementLabel }} (-)</td>
                                    <td v-if="money.a - money.am_ori < 0" class="equity_align_right_heavy">{{ formatPrice(money.am_ori - money.a, money.c, 2).replace(' ', '&nbsp;') }}</td>
                                    <td v-else class="equity_align_right_heavy">{{ formatPrice(0, money.c, 2).replace(' ', '&nbsp;') }}</td>
                                  </tr>
                                  <tr>
                                    <td>{{ getInSettlementLabel }} (+)</td>
                                    <td v-if="money.a - money.am_ori > 0" class="equity_align_right_heavy">{{ formatPrice(money.a - money.am_ori, money.c, 2).replace(' ', '&nbsp;') }}</td>
                                    <td v-else class="equity_align_right_heavy">{{ formatPrice(0, money.c, 2).replace(' ', '&nbsp;') }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade"
                    :class="{ 'active': 'Equities' === activeMenuSelection, 'show': 'Equities' === activeMenuSelection }"
                    id="Equities" role="tabpanel" aria-labelledby="Equities-tab">
                    <div class="row">
                      <div class="col-sm-12"
                        :class="{ 'col-md-4': 0 === parseInt(getSelectedExchangeCode), 'col-md-12': 0 !== parseInt(getSelectedExchangeCode) }">
                        <div
                          v-show="(getSelectedExchange && 'BGN' === getSelectedExchange.cc.toUpperCase()) || 0 === parseInt(getSelectedExchangeCode)"
                          class="card h-100">
                          <img
                            :class="{ 'card-img-top': 1200 < getWindowsWidth, 'card-img-top-responsive': 1200 > getWindowsWidth }"
                            src="../assets/images/bfb_logo.png" alt="Bulgarian Fund Exchange">
                          <div class="card-body">
                            <table class="table">
                              <tbody>
                                <tr>
                                  <td v-if="1200 > getWindowsWidth"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getBaseCurrencyLabel }}
                                  </td>
                                  <td v-else>{{ getPositionsLabel }} ({{ getBaseCurrencyLabel }})</td>
                                  <td v-if="'EUR' === getCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getXbulEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}</td>
                                  <td v-if="'BGN' === getCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getXbulEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}</td>
                                </tr>
                                <tr>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPositionsLabel }}
                                  </td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    {{ formatPrice(getXbulEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getMyNumberOfInstrumentsLabel }}</td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(getXbulEquitiesPositionsCount, 0) }}</td>
                                </tr>
                                <tr v-if="0 < parseFloat(getXbulEquitiesValueInBgn)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getReturnLabel }}</td>
                                  <td v-if="getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn > 0"
                                    class="greenColor equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    +{{ parseNumber(getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) }}
                                    (+{{ parseNumber(((getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) /
                                    getXbulEquitiesAvgValueInBgn)
                                    * 100) }}%)
                                  </td>
                                  <td v-if="getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn < 0"
                                    class="redColor equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    {{ parseNumber(getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) }}
                                    ({{ parseNumber(((getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) /
                                    getXbulEquitiesAvgValueInBgn)
                                    * 100) }}%)
                                  </td>
                                  <td v-else></td>
                                </tr>
                                <tr v-else>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getReturnLabel }}</td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(getXbulEquitiesValueInBgn - getXbulEquitiesAvgValueInBgn) }}(-%)
                                  </td>
                                </tr>
                                <tr v-if="!isNaN(getXbulEquitiesSharesCount)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                    getPositionsBalanceLabel }}</td>
                                  <td v-if="0 !== getAllEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter 
                                      :value="getXbulEquitiesValueInCurrentCurrency / getAllEquitiesValueInCurrentCurrency * 100"
                                      min="0" max="100"></meter>&nbsp;({{
                                    parseNumber(getXbulEquitiesValueInCurrentCurrency /
                                    getAllEquitiesValueInCurrentCurrency * 100) }}%)
                                  </td>
                                </tr>
                                <tr v-else>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                    getPositionsBalanceLabel }}</td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                  </td>
                                </tr>
                                <tr v-if="!isNaN(getXbulEquitiesSharesCount)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getTotalBalanceLabel
                                    }}</td>
                                  <td v-if="0 !== totalBalance" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter :value="getXbulEquitiesValueInCurrentCurrency / totalBalance * 100" min="0"
                                      max="100"></meter>&nbsp;({{ parseNumber(getXbulEquitiesValueInCurrentCurrency /
                                    totalBalance * 100) }}%)
                                  </td>
                                </tr>
                                <tr v-else>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    %{{ getTotalBalanceLabel }}
                                  </td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <button type="button" class="btn btn-primary w-90 m-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="openWalletBreakdownByExchange(1)">{{ getDetailsLabel }}</button>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="{ 'col-md-4': 0 === parseInt(getSelectedExchangeCode), 'col-md-12': 0 !== parseInt(getSelectedExchangeCode) }">
                        <div
                          v-show="(getSelectedExchange && 'EUR' === getSelectedExchange.cc.toUpperCase()) || 0 === parseInt(getSelectedExchangeCode)"
                          class="card h-100">
                          <img class="card-img-top" src="../assets/images/Deutsche_Boerse_Xetra_Logo.png"
                            alt="Deutsche Boerse Xetra">
                          <div class="card-body">
                            <table class="table">
                              <tbody>
                                <tr>
                                  <td v-if="1200 > getWindowsWidth"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getBaseCurrencyLabel }}
                                  </td>
                                  <td v-else>{{ getPositionsLabel }} ({{ getBaseCurrencyLabel }})</td>
                                  <td v-if="'EUR' === getCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getXetrEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}</td>
                                  <td v-if="'BGN' === getCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getXetrEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}</td>
                                </tr>
                                <tr>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPositionsLabel }}
                                  </td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    {{ formatPrice(getXetrEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getMyNumberOfInstrumentsLabel }}</td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(getXetrEquitiesPositionsCount, 0) }}</td>
                                </tr>
                                <tr v-if="0 < parseFloat(getXetrEquitiesValueInEur)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getReturnLabel }}</td>
                                  <td v-if="getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur > 0"
                                    class="greenColor equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    +{{ parseNumber(getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) }}
                                    (+{{ parseNumber(((getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) /
                                    getXetrEquitiesAvgValueInEur)
                                    * 100) }}%)
                                  </td>
                                  <td v-if="getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur < 0"
                                    class="redColor equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    {{ parseNumber(getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) }}
                                    ({{ parseNumber(((getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) /
                                    getXetrEquitiesAvgValueInEur)
                                    * 100) }}%)
                                  </td>
                                </tr>
                                <tr v-else>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getReturnLabel }}</td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(getXetrEquitiesValueInEur - getXetrEquitiesAvgValueInEur) }}(-%)
                                  </td>
                                </tr>
                                <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                    getPositionsBalanceLabel }}</td>
                                  <td v-if="0 !== getAllEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter
                                      :value="getXetrEquitiesValueInCurrentCurrency / getAllEquitiesValueInCurrentCurrency * 100"
                                      min="0" max="100"></meter>&nbsp;({{
                                    parseNumber(getXetrEquitiesValueInCurrentCurrency /
                                    getAllEquitiesValueInCurrentCurrency * 100) }}%)
                                  </td>
                                  <td v-else></td>
                                </tr>
                                <tr v-else>
                                  <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                  </td>
                                </tr>
                                <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getTotalBalanceLabel
                                    }}</td>
                                  <td v-if="0 !== totalBalance" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter :value="getXetrEquitiesValueInCurrentCurrency / totalBalance * 100" min="0"
                                      max="100"></meter>&nbsp;({{ parseNumber(getXetrEquitiesValueInCurrentCurrency /
                                    totalBalance * 100) }}%)
                                  </td>
                                  <td v-else></td>
                                </tr>
                                <tr v-else>
                                  <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <button type="button" class="btn btn-primary w-90 m-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="openWalletBreakdownByExchange(2)">{{ getDetailsLabel }}</button>
                        </div>
                      </div>
                      <div class="col-sm-12"
                        :class="{ 'col-md-4': 0 === parseInt(getSelectedExchangeCode), 'col-md-12': 0 !== parseInt(getSelectedExchangeCode) }">
                        <div
                          v-show="(getSelectedExchange && 'EUR' === getSelectedExchange.cc.toUpperCase()) || 0 === parseInt(getSelectedExchangeCode)"
                          class="card h-100">
                          <img class="card-img-top" src="../assets/images/xfra_logo.png" alt="Boerse Frankfurt">
                          <div class="card-body">
                            <table class="table">
                              <tbody>
                                <tr>
                                  <td v-if="1200 > getWindowsWidth"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getBaseCurrencyLabel }}
                                  </td>
                                  <td v-else>{{ getPositionsLabel }} ({{ getBaseCurrencyLabel }})</td>
                                  <td v-if="'EUR' === getCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getXfraEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}</td>
                                  <td v-if="'BGN' === getCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getXfraEquitiesValueInBgn, 'BGN', 2).replace(' ', '&nbsp;') }}</td>
                                </tr>
                                <tr>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPositionsLabel }}
                                  </td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    {{ formatPrice(getXfraEquitiesValueInEur, 'EUR', 2).replace(' ', '&nbsp;') }}
                                  </td>
                                </tr>
                                <tr>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getMyNumberOfInstrumentsLabel }}</td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(getXfraEquitiesPositionsCount, 0) }}</td>
                                </tr>
                                <tr v-if="0 < parseFloat(getXfraEquitiesValueInEur)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getReturnLabel }} </td>
                                  <td v-if="getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur > 0"
                                    class="greenColor equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    +{{ parseNumber(getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) }}
                                    (+{{ parseNumber(((getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) /
                                    getXfraEquitiesAvgValueInEur)
                                    * 100) }}%)
                                  </td>
                                  <td v-else-if="getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur < 0"
                                    class="redColor equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    {{ parseNumber(getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) }}
                                    ({{ parseNumber(((getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) /
                                    getXfraEquitiesAvgValueInEur)
                                    * 100) }}%)
                                  </td>
                                  <td v-else></td>
                                </tr>
                                <tr v-else>
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getReturnLabel }}</td>
                                  <td class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(getXfraEquitiesValueInEur - getXfraEquitiesAvgValueInEur) }}(-%)
                                  </td>
                                </tr>
                                <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                    getPositionsBalanceLabel }}</td>
                                  <td v-if="0 !== getAllEquitiesValueInCurrentCurrency" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter 
                                      :value="getXfraEquitiesValueInCurrentCurrency / getAllEquitiesValueInCurrentCurrency * 100"
                                      min="0" max="100"></meter>&nbsp;({{
                                    parseNumber(getXfraEquitiesValueInCurrentCurrency /
                                    getAllEquitiesValueInCurrentCurrency * 100) }}%)
                                  </td>
                                  <td v-else></td>
                                </tr>
                                <tr v-else>
                                  <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                  </td>
                                </tr>
                                <tr v-if="!isNaN(getEurEquitiesSharesCount)">
                                  <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{ getTotalBalanceLabel
                                    }}</td>
                                  <td v-if="0 !== totalBalance" class="equity_align_right_heavy"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter :value="getXfraEquitiesValueInCurrentCurrency / totalBalance * 100" min="0"
                                      max="100"></meter>&nbsp;({{ parseNumber(getXfraEquitiesValueInCurrentCurrency /
                                    totalBalance * 100) }}%)
                                  </td>
                                  <td v-else></td>
                                </tr>
                                <tr v-else>
                                  <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <button type="button" class="btn btn-primary w-90 m-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="openWalletBreakdownByExchange(3)">{{ getDetailsLabel }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade"
                    :class="{ 'active': 'Transactions' === activeMenuSelection, 'show': 'Transactions' === activeMenuSelection }"
                    id="Transactions" role="tabpanel" aria-labelledby="Transactions-tab">
                    <div class="card h-100">
                      <div class="card-body">
                        <div class="wallet-history">
                          <div v-if="1200 < getWindowsWidth" class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>{{ getTimestampLabel }}</th>
                                  <th>{{ getTypeLabel }}</th>
                                  <th>{{ getCurrencyLabel }}</th>
                                  <th>{{ getAmountLabel }}</th>
                                  <th>{{ getNoteLabel }}</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(transaction, index) in getAvailableTransactions" :key="index">
                                  <td>{{ getShortDate(transaction.ts) }}</td>
                                  <td style="text-align:center">{{ capitalizeFirstLetter(transaction.tt) }}</td>
                                  <td style="text-align:center">{{ transaction.cc }}</td>
                                  <td class="price-data">{{ formatPrice(transaction.am, '', 2).replace(' ',
                                    '&nbsp;') }}</td>
                                  <td>{{ transaction.note }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div v-else v-for="(transaction, index) in getAvailableTransactions" :key="index">
                            <div class="card h-100">
                              <div class="card-body xsmall-font-size">
                                <div class="container p-0">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <strong>{{ capitalizeFirstLetter(transaction.tt) }}</strong><br />
                                      {{ getDate(transaction.ts) }}<br />
                                      {{ formatPrice(transaction.am, transaction.cc, 2).replace(' ', '&nbsp;') }}
                                    </div>
                                    <div class="col-6 text-end my-auto">
                                      <button type="button" class="btn btn-primary"
                                        :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                        @click.prevent="openTransactionDetails(transaction)">&rarr;</button>&nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade"
                    :class="{ 'active': 'Orders' === activeMenuSelection, 'show': 'Orders' === activeMenuSelection }"
                    id="Orders" role="tabpanel" aria-labelledby="Orders-tab">
                    <div class="card h-100">
                      <div class="card-body">
                        <div class="wallet-history">
                          <div v-if="1200 < getWindowsWidth" class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>{{ getOrderIdLabel }}/{{ getTimestampLabel }}</th>
                                  <th>{{ getTypeLabel }}</th>
                                  <th>{{ getOrderTypeLabel }}</th>
                                  <th>{{ getCodeLabel }}</th>
                                  <th>{{ getNameLabel }}</th>
                                  <th>{{ getQuantityLabel }}</th>
                                  <th>{{ getCurrencyLabel }}</th>
                                  <th>{{ getPriceLabel }}</th>
                                  <th>{{ getStatusLabel }}</th>
                                  <th></th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="transaction in getAvailableOrders" :key="transaction.acquired">
                                  <td><strong>#{{ transaction.order_id }}<br /></strong>{{ getDate(transaction.acquired)
                                    }}</td>
                                  <td style="text-align:center">{{ getOrderType(transaction.action) }}</td>
                                  <td style="text-align:center">{{ capitalizeFirstLetter(transaction.type) }}</td>
                                  <td style="text-align:center">{{ transaction.code.toUpperCase() }}</td>
                                  <td>
                                    <div v-if="0 === 'BG'.localeCompare(getUserLanguage)">{{ transaction.nBG ?
                                      transaction.nBG :
                                      transaction.nEN }}
                                    </div>
                                    <div v-if="0 === 'EN'.localeCompare(getUserLanguage)">{{
                                      transaction.nEN }}</div>
                                  </td>
                                  <td class="price-data">{{ transaction.amount }}</td>
                                  <td style="text-align:center">{{ transaction.cc.toUpperCase() }}</td>
                                  <td class="price-data">
                                    <div
                                      v-if="transaction.price && 2 != getStockTypeId(transaction.code, transaction.xcode)">
                                      {{ formatPrice(transaction.price, transaction.cc).replace(' ', '&nbsp;') }}</div>
                                    <div
                                      v-if="transaction.price && 2 == getStockTypeId(transaction.code, transaction.xcode)">
                                      {{ formatBondsPrice(transaction.price).replace(' ', '&nbsp;') }}</div>
                                  </td>
                                  <td>{{ getOrderStatus(transaction) }}</td>
                                  <td>
                                    <div class="btn-group d-flex mtb15" role="group" v-if="3 >= transaction.status">
                                      <button class="btn" type="button"
                                        @click.prevent="confirmOrderCancel(transaction.order_id)">
                                        <svg id="Component_172_4" data-name="Component 172 – 4"
                                          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                          <path id="Path_3614" data-name="Path 3614" d="M0,0H24V24H0Z" fill="none" />
                                          <path id="Path_3954" data-name="Path 3954"
                                            d="M10,10,22.317,22.317m0-12.317L10,22.317"
                                            transform="translate(-4.158 -4.158)" fill="none" stroke="#000"
                                            stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                        </svg>
                                      </button>
                                      <button class="btn" type="button" @click.prevent="updateOrder(transaction)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                          viewBox="0 0 24 24">
                                          <g id="Component_171_1" data-name="Component 171 – 1"
                                            style="mix-blend-mode: normal;isolation: isolate">
                                            <path id="Path_3612" data-name="Path 3612" d="M0,0H24V24H0Z" fill="none" />
                                            <path id="Path_3613" data-name="Path 3613"
                                              d="M4,20H8L18.5,9.5a2.828,2.828,0,0,0-4-4L4,16v4" fill="none"
                                              stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                                              stroke-width="1.5" />
                                            <line id="Line_192" data-name="Line 192" x2="4" y2="4"
                                              transform="translate(13.5 6.5)" fill="none" stroke="#000"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                          </g>
                                        </svg>
                                      </button>
                                    </div>
                                  </td>
                                  <td>
                                    <button type="button" class="btn btn-primary"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                      @click.prevent="openOrderDetails(transaction)">&rarr;</button>&nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div v-else v-for="transaction in getAvailableOrders" :key="transaction.acquired">
                            <div class="card h-100">
                              <div class="card-body xsmall-font-size">
                                <div class="container p-0">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <strong>#{{ transaction.order_id }}</strong><br />
                                      {{ getDate(transaction.acquired) }}<br />
                                      {{ transaction.code.toUpperCase() }}@{{ transaction.xcode.toUpperCase() }}<br />
                                      {{ getOrderStatus(transaction) }}
                                    </div>
                                    <div class="col-6 text-end my-auto">
                                      <button type="button" class="btn btn-primary"
                                        :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                        @click.prevent="openOrderDetails(transaction)">&rarr;</button>&nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade"
                    :class="{ 'active': 'Trades' === activeMenuSelection, 'show': 'Trades' === activeMenuSelection }"
                    id="Trades" role="tabpanel" aria-labelledby="Trades-tab">
                    <div class="card h-100">
                      <div class="card-body">
                        <div class="wallet-history">
                          <div v-if="1200 < getWindowsWidth" class="table-responsive">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>{{ getOrderIdLabel }}/{{ getTimestampLabel }}</th>
                                  <th>{{ getTypeLabel }}</th>
                                  <th>{{ getOrderTypeLabel }}</th>
                                  <th>{{ getCodeLabel }}</th>
                                  <th>{{ getNameLabel }}</th>
                                  <th>{{ getQuantityLabel }}</th>
                                  <th>{{ getCurrencyLabel }}</th>
                                  <th>{{ getPriceLabel }}</th>
                                  <th>{{ getTotalAmountLabel }}</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(transaction, index) in getFilteredTrades" :key="index">
                                  <td><strong>#{{ transaction.oid }}</strong><br />{{ getDate(transaction.ts) }}</td>
                                  <td style="text-align:center">{{ capitalizeFirstLetter(getOrderType(transaction?.tt)) }}</td>
                                  <td style="text-align:center">{{ capitalizeFirstLetter(getOrderById(transaction.oid)?.type) }}</td>
                                  <td style="text-align:center">{{ transaction.sc.toUpperCase() }}</td>
                                  <td>
                                    <div v-if="0 === 'BG'.localeCompare(getUserLanguage)">{{ transaction?.nBG ?
                                      transaction?.nBG : transaction?.nEN }}</div>
                                    <div v-if="0 === 'EN'.localeCompare(getUserLanguage)">{{ transaction?.nEN }}</div>
                                  </td>
                                  <td class="price-data">{{ parseNumber(transaction?.qty, 0) }}</td>
                                  <td style="text-align:center">{{ transaction?.cc?.toUpperCase() }}</td>
                                  <td class="price-data"
                                    v-if="2 != getStockTypeId(transaction.code, transaction.xcode)">{{
                                    formatPrice(transaction?.sp, transaction?.cc).replace(' ', '&nbsp;') }}</td>
                                  <td class="price-data" v-else>{{ formatBondsPrice(transaction?.sp).replace(' ',
                                    '&nbsp;') }}</td>
                                  <td class="price-data">{{ formatPrice(transaction?.pt, transaction?.cc).replace(' ',
                                    '&nbsp;') }}</td>
                                  <td>
                                    <button type="button" class="btn btn-primary"
                                      :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                      @click.prevent="openTradeDetails(transaction)">&rarr;</button>&nbsp;
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div v-else v-for="(transaction, index) in getFilteredTrades" :key="index">
                            <div class="card h-100">
                              <div class="card-body xsmall-font-size">
                                <div class="container p-0">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <strong>#{{ transaction.oid }}</strong><br />
                                      {{ getDate(transaction.ts) }}<br />
                                      {{ transaction.sc.toUpperCase() }}@{{ transaction.xc.toUpperCase() }}<br />
                                      {{ getOrderStatus(getOrderById(transaction.oid)) }}
                                    </div>
                                    <div class="col-6 text-end my-auto">
                                      <button type="button" class="btn btn-primary"
                                        :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                        @click.prevent="openTradeDetails(transaction)">&rarr;</button>&nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/axios/http-common";
import Menu from '../components/Menu.vue';
import UiUtils from "@/assets/js/ui_utils.js";
import ApiUtils from "@/assets/js/api_utils.js";
import Translations from "@/lang/translations.js";
import GPieChart from "../components/GPieChart.vue";
//import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import TradeConfirmed from '@/components/TradeConfirmed.vue';
import Trading from '../components/Trading.vue';

export default {
  components: {
    Menu,
    GPieChart,
//    Datepicker,
    TradeConfirmed,
    Trading
  },
  data() {
    return {
      orders: [],
      trades: [],
      transactions: [],
      /* order ID to cancel or update */
      orderId: "",
      /* 0 - all exchanges, other: exchange id */
      selectedExchange: this.getSelectedExchangeCode,
      /* active modal dialog for order cancel */
      cancelOrderModalActive: false,
      /* show modal dialog for order cancel */
      cancelOrderModalShow: false,
      /* active modal dialog for order edit */
      editOrderModalActive: false,
      /* show modal dialog for order edit */
      editOrderModalShow: false,
      /* active modal dialog for order details */
      transactionDetailsModalActive: false,
      /* show modal dialog for order details */
      transactionDetailsModalShow: false,
      /* transaction details object */
      transactionDetails: null,
      /* transaction details name EN */
      transactionDetailsStockNameEn: null,
      /* transaction details name BG */
      transactionDetailsStockNameBg: null,
      /* transaction details stock code */
      transactionDetailsStockCode: null,
      /* transaction details stock currency */
      transactionDetailsStockCurrency: null,
      /* to date selection in unix timestamp */
      dateTo: this.convertSelectedDateToToUnixtime,
      dateToString: "",
      activeMenuSelection: this.latestActiveMenuSelection ? this.latestActiveMenuSelection : "Summary",
      selectedCurrency: "ALL",
      selectedStockInPositionsXbul: null,
      selectedStockInPositionsXetr: null,
      selectedStockInPositionTabXbul: "Information",
      selectedStockInPositionTabXetr: "Information",
      tradeConfirmedModalActive: false,
      tradeConfirmedModalShow: false,
      orderModel: {},
      minDateForWallet: "2023-01-01",
      minYearForWallet: 2023,
      hasReestablishingConnectionMessage: false,
      sseVerifier: null,
      hasEvents: false,
      sseClient: null,
    }
  },
  props: {
    latestActiveMenuSelection: {
      required: false,
      type: String,
      default: null
    },
    latestDisplayStock: {
      required: false,
      type: String,
      default: null
    }
  },
  watch: {
    dateToString: function(newVal) {
      this.dateTo = this.convertSelectedDateToToUnixtime;
      if (null !== newVal) {
        // call appropriate data retrieval
        this.$store.commit("setAppStateNotReady");
        if (0 === this.latestActiveMenuSelection.localeCompare('Orders')) {
          (async () => await this.getOrders())();
        } else if (0 === this.latestActiveMenuSelection.localeCompare('Trades')) {
          (async () => await this.getTrades())();
        } else if (0 === this.latestActiveMenuSelection.localeCompare('Transactions')) {
          (async () => await this.getTransactions())();
        }
        this.$store.commit("setAppStateReady");        
      }
    },
    getSelectedExchangeCode: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedExchange = parseInt(newVal);
      }
    },
    latestActiveMenuSelection: function(newVal) {
      this.activeMenuSelection = newVal || "Summary";
      // call appropriate data retrieval
      if (0 === newVal.localeCompare('Orders')) {
        (async () => await this.getOrders())();
      } else if (0 === newVal.localeCompare('Trades')) {
        (async () => await this.getTrades())();
      } else if (0 === newVal.localeCompare('Transactions')) {
        (async () => await this.getTransactions())();
      }
    },
    activeMenuSelection: function (newVal) {
      // call appropriate data retrieval
      if (0 === newVal.localeCompare('Equities')) {
        (async () => await Promise.all([this.getTrades()]))();
      } else if (0 === newVal.localeCompare('Orders')) {
        (async () => await this.getOrders())();
      } else if (0 === newVal.localeCompare('Trades')) {
        (async () => await this.getTrades())();
      } else if (0 === newVal.localeCompare('Transactions')) {
        (async () => await this.getTransactions())();
      }
    },
    stopSseState: function (newVal) {
      if (newVal) {
        if (this.sseClient) {
          try {
            this.sseClient.close();
          } catch (err) {
            console.error(err);
          }
          this.$store.commit("setStopSse", false);
        }
      }
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string
        ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
        : "";
    },
    openTradeDetails(trade) {
      // browser changes the type from object to string, so we perform a little hack passing string and building the object in the child vue
      this.$router.push({ name: "TradeDetails", params: { tradeDetails: JSON.stringify(trade), latestDisplayStock: this.latestDisplayStock } });
    },
    openOrderDetails(order) {
      // browser changes the type from object to string, so we perform a little hack passing string and building the object in the child vue
      this.$router.push({ name: "OrderDetails", params: { orderDetails: JSON.stringify(order) } });
    },
    openTransactionDetails(transaction) {
      // browser changes the type from object to string, so we perform a little hack passing string and building the object in the child vue
      this.$router.push({ name: "TransactionDetails", params: { transactionDetails: JSON.stringify(transaction), latestDisplayStock: this.latestDisplayStock } });
    },    
    openTrader(stock) {
      this.$store.commit("setSelectedTradingCode", stock.sc);
      this.$store.commit("setSelectedTradingCodeSegment", stock.sgm);
      this.$store.commit("setSelectedTradingCodeShareTypeId", parseInt(stock.tID));
      this.$store.commit("setSelectedTradingExchangeId", UiUtils.getExchangeIdByCode(this.$store.getters["getExchanges"], stock.ex));
      this.$store.commit("setSelectedTradingStockName", stock.nEN);
      this.$store.commit("setSelectedTradingAction", 'B');
      // this is either market or limit order
      this.$store.commit("setOrderIdToUpdate", "");
      const stocks = this.$store.getters["getStocks"];
      const mapped = stocks.filter(item => {
        return 0 === item.code.toString().toUpperCase().localeCompare(stock.sc.toUpperCase());
      });
      this.$store.commit("setSearchedStocksUserWatchlist", mapped);
      this.$router.push({ name: "Dashboard" });
    },
    getUserType() {
      return this.$store.getters["getUserType"];
    },
    openWalletBreakdownByExchange(exchangeId) {
      this.$router.push({ name: "WalletBreakdownByExchange", 
        params: { itemExchangeId: exchangeId, orders: JSON.stringify(this.orders), trades: JSON.stringify(this.trades) } });      
    },
    async subscribeForSse() {
      const user = this.$store.getters["getUser"];
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "set_sse",
            id: user.id,
            key: user.key,
            "quote-ex-sts": "off",
            "quote-tr-sts": "off",
            "depth-sts": "off",
            "top10-sts": "off",
            "top10-set": "",
            "top10-typ": "",
            "wallet-sts": "on",
            "phase-sts": "off",
            "quote-sts": "off",
            "quote-set": ""
          }
        });
        if (res) {
          // check answer
          if (0 !== res.data.ec) {
            this.$toast.error(this.getCouldNotSetSseMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotSetSseMessage,
              error: true
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },    
    setupStream() {
      const user = this.$store.getters["getUser"];
      if (!user) {
        return;
      }

      try {
        if (0 === this.getUserType()) {
          // sandbox
          this.sseClient = new EventSource("https://sse.sandbox.efocs.app/api/sse?vn=100&os=b&id=" + encodeURIComponent(user.id) +
            "&key=" + encodeURIComponent(user.key));
        } else {
          // production
          this.sseClient = new EventSource("https://sse.prod.efocs.app/api/sse?vn=100&os=b&id=" + encodeURIComponent(user.id) +
            "&key=" + encodeURIComponent(user.key));
        }

        // verify we have valid connection
        if (!this.sseClient) {
          return;
        }
        // show message if connection has been lost
        if (this.hasReestablishingConnectionMessage) {
          const reestablishConnectionMessage = this.getReestablishedConnectionLabel;
          // show user info
          this.$toast.success(reestablishConnectionMessage, {
            "duration": 5000,
            "pauseOnHover": true
          });
          this.$store.commit("addSystemMessage", {
            date: (new Date()).getTime(),
            mess: reestablishConnectionMessage,
            error: false
          });
          this.hasReestablishingConnectionMessage = false;
        }

        this.sseClient.addEventListener("HEARTBEAT", event => {
          this.hasEvents = true;
        }, { passive: true });
        this.sseClient.addEventListener("SESSION_CLOSED", event => {
          this.hasEvents = true;
          // logout
          ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1);
        }, { passive: true });
        this.sseClient.addEventListener("WALLET", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("setWallet", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("EXECUTED", event => {
          this.hasEvents = true;
          try {
            const orderData = JSON.parse(event.data);
            this.formOrderObject(orderData);
            this.tradeConfirmedModalShow = true;
            this.tradeConfirmedModalActive = true;
            let mess = "";
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = "Вашата поръчка №";
            } else {
              mess = "Your order #";
            }
            mess = mess + orderData.id + ", " + this.getDate(orderData.d) +
              ", " + this.getOrderType(orderData.t) + " " + orderData.sc + "(" + this.getStockName(orderData.sc) + ") " +
              orderData.qty + "@" + orderData.sp + " " + orderData.cc;
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = mess + " е изпълнена";
            } else {
              mess = mess + " is completed";
            }
            this.$toast.success(mess, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: mess,
              error: false
            });
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("REJECTED", event => {
          this.hasEvents = true;
          try {
            const orderData = JSON.parse(event.data);
            let mess = "";
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = "Вашата поръчка №";
            } else {
              mess = "Your order #";
            }
            const stockName = orderData.nBG
              ? orderData.nBG : orderData.nEN;
            mess = mess + orderData.oid + ", " + this.getDate(orderData.ts) +
              ", " + orderData.sc + "(" + stockName + ") " +
              orderData.qty + "@" + orderData.sp + " " + orderData.cc;
            if (0 === "BG".localeCompare(this.getUserLanguage)) {
              mess = mess + " е отказана";
            } else {
              mess = mess + " is rejected";
            }
            this.$toast.error(mess, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: mess,
              error: true
            });
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("QUOTE_TR", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("setQuoteTrading", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("DEPTH", event => {
          this.hasEvents = true;
          try {
            this.$store.commit("setDepth", JSON.parse(event.data));
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
        this.sseClient.addEventListener("error", event => {
          this.hasEvents = false;
          try {
            if (event.readyState == EventSource.CLOSED) {
              console.log(EventSource);
            }
          } catch (err) {
            console.error(err);
          }
        }, { passive: true });
      } catch (error) {
        console.error(error)
      }

    },    
    getStockName(stockCode) {
      const stocks = this.$store.getters["getStocks"];
      const indexedStocks = this.$store.getters["getIndexedStocks"];

      return UiUtils.getStockName(stocks, indexedStocks, stockCode, this.getUserLanguage); 
    },
    getStockTypeId(stockCode, exCode) {
      if (!stockCode || !exCode) {
        return 1;
      }
      const exchangeId = UiUtils.getExchangeIdByCode(this.getExchanges, exCode);
      const indexedStocks = this.$store.getters["getIndexedStocks"];
      const stocks = this.$store.getters["getStocks"];
      const stockIndex = indexedStocks.indexOf(exchangeId + stockCode);

      return stocks[stockIndex]?.tID || 1;
    },
    confirmOrderCancel(orderId) {
      this.orderId = orderId;
      this.cancelOrderModalActive = true;
      this.cancelOrderModalShow = true;
    },
    async updateOrder(order) {
      this.$store.commit("setOrderToEdit", order);
      this.$nextTick(() => {
        this.editOrderModalActive = true;
        this.editOrderModalShow = true;      
      });
    },
    getExchange(stockCode) {
      if (!stockCode) {
        return {
          code: "N/A",
          cc: "N/A"
        };
      }
      try {
        const exchanges = this.$store.getters["getExchanges"];
        const stocks = this.$store.getters["getStocks"];
        const indexedStocks = this.$store.getters["getIndexedStocks"];
        const stock = UiUtils.getStock(stocks, indexedStocks, stockCode);

        if (stock) {
          // find exchange 
          const exchange = exchanges.filter(item => {
            return parseInt(item.eID) === parseInt(stock.cMIC);
          })[0];
          return exchange;
        }
      } catch (error) {
        console.error(error);
      }
      return {
        code: "N/A",
        cc: "N/A"
      };
    },
    getDate(unix) {
      return UiUtils.parseUnixTime(unix);
    },
    getShortDate(unix) {
      return this.getDate(unix).slice(0, 9);
    },
    getDateFromMs(unix) {
      return new Date(unix).toISOString().slice(0, 19).replace('T', ' ');
    },
    getShortDateFromMs(unix) {
      return new Date(unix).toISOString().slice(0, 10);
    },
    getOrderType(ot) {
      return 'b' === ot || 'B' === ot ? 
        Translations.getBuyLabel(this.getUserLanguage) : 
        Translations.getSellLabel(this.getUserLanguage);
    },
    getBuyLabel() {
      return Translations.getBuyLabel(this.getUserLanguage);
    },
    getSellLabel() {
      return Translations.getSellLabel(this.getUserLanguage);
    },
    getOrderById(orderId) {
      const filtered = this.orders?.filter(item => {
        return 0 === orderId.localeCompare(item.order_id);
      });
      return filtered ? filtered[0] : null;
    },
    getOrderStatus(order) {
        if (!order) {
            return "N/A";
        }
        switch (order.status) {
            case 1:
            case 2:
            case 12:
            case 99: {
                return Translations.getProcessingLabel(this.getUserLanguage);
            }
            case 3: {
                return Translations.getPlacedLabel(this.getUserLanguage);
            }
            case 4: 
            case 5: {
                return Translations.getCancelledLabel(this.getUserLanguage);
            }
            case 6: 
            case 7: {
                return Translations.getFilledLabel(this.getUserLanguage);
            }
        }
        return "N/A";
    },
    async getOrders() {
      try {
        this.$store.commit("setAppStateNotReady");
        const user = this.$store.getters["getUser"];
        // some users have a HUGE history, so retrieving the entire orders in one call may be slow
        // we call it by chunchs fo all years until start
        const iters = (new Date()).getFullYear() - this.minYearForWallet;
        let all = [];
        for (let cnt = 1; cnt <= iters; cnt ++) {
          const endDate = new Date(new Date().setFullYear(new Date().getFullYear() - cnt + 1)).toISOString().slice(0, 10);
          const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - cnt)).toISOString().slice(0, 10);
          const res = await http.getApiClient(this.getUserType()).get("", {
            params: {
              a: "get_orders",
              id: user.id,
              key: user.key,
              from: startDate,
              to: !this.dateTo ? endDate : this.getShortDateFromMs(this.dateTo)
            }
          });
          if (res) {
            // check answer
            if (ApiUtils.isApiResponseInvalidSession(res)) {
              // try silent relogin and repeat order posting for live users only
              if (this.$store.getters["getReloginUsernameLive"] && this.$store.getters["getReloginPasswordLive"]) {
                // stop the SSE listener to avoid session closed message
                this.$store.commit("setStopSse", true);
                const payload = {
                    api: http,
                    username: this.$store.getters["getReloginUsernameLive"],
                    password: this.$store.getters["getReloginPasswordLive"],
                    demo: false
                };
                if (0 === await this.$store.dispatch("loginUser", payload)) {
                    // repeat posting the command
                    cnt = cnt - 1;
                    continue;
                }
              }

              this.$store.commit("setAppStateReady");
              // logout
              setTimeout(
                ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                500);
              return;
            }
            if (0 === res.data.ec) {
              if (0 === res.data.list.length) {
                // no more data
                break;
              }
              all.push(...res.data.list);
              this.$store.commit("setAppStateReady");
              // get min date and set in in date picker
              const firstOrder = this.orders[this.orders.length - 1];
              if (firstOrder && 0 === this.minDateForWallet.localeCompare("2023-01-01")) {
                // acquired + 1 day
                this.minDateForWallet = this.getShortDate(firstOrder.acquired);
              }
            } else {
              this.$store.commit("setAppStateReady");
              this.$toast.error(this.getCouldNotReadOrdersMessage, {
                "duration": 5000,
                "pauseOnHover": true
              });
              this.$store.commit("addSystemMessage", {
                date: (new Date()).getTime(),
                mess: this.getCouldNotReadOrdersMessage,
                error: true
              });
            }
          }          
        }
        this.orders = all;
      } catch (err) {
        console.error(err);
      }
    },
    async cancelOrder() {
      if (!this.orderId) {
        return;
      }
      try {
        this.$store.commit("setAppStateNotReady");
        const user = this.$store.getters["getUser"];
        let res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "cancel_order",
            id: user.id,
            key: user.key,
            oid: this.orderId
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
              // try silent relogin and repeat order posting for live users only
              if (this.$store.getters["getReloginUsernameLive"] && this.$store.getters["getReloginPasswordLive"]) {
                // stop the SSE listener to avoid session closed message
                this.$store.commit("setStopSse", true);
                const payload = {
                    api: http,
                    username: this.$store.getters["getReloginUsernameLive"],
                    password: this.$store.getters["getReloginPasswordLive"],
                    demo: false
                };
                if (0 === await this.$store.dispatch("loginUser", payload)) {
                  // repeat posting the command
                  res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                      a: "cancel_order",
                      id: user.id,
                      key: user.key,
                      oid: this.orderId
                    }
                  });
                  if (res && 0 === res.data.ec) {
                    this.$toast.success(this.getCancelledOrderMessage, {
                      "duration": 5000,
                      "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                      date: (new Date()).getTime(),
                      mess: this.getCancelledOrderMessage,
                      error: false
                    });
                    setTimeout(async () => {
                      // update orders
                      await this.getOrders();
                      this.$store.commit("setAppStateReady");
                    }, 1200);
                  } else {
                    this.$toast.error(this.getCouldNotCancelOrderMessage, {
                      "duration": 5000,
                      "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                      date: (new Date()).getTime(),
                      mess: this.getCouldNotCancelOrderMessage,
                      error: true
                    });
                  }

                  return;
                }
              }

            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }
          if (0 === res.data.ec) {
            this.$toast.success(this.getCancelledOrderMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCancelledOrderMessage,
              error: false
            });
            setTimeout(async () => {
              // update orders
              await this.getOrders();
              this.$store.commit("setAppStateReady");
            }, 1200);
          } else {
            this.$toast.error(this.getCouldNotCancelOrderMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotCancelOrderMessage,
              error: true
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getTrades() {
      try {
        this.$store.commit("setAppStateNotReady");
        const user = this.$store.getters["getUser"];
        // some users have a HUGE history, so retrieving the entire orders in one call may be slow
        // we call it by chunchs fo all years until start
        const iters = (new Date()).getFullYear() - this.minYearForWallet;
        let all = [];
        for (let cnt = 1; cnt <= iters; cnt++) {
          const endDate = new Date(new Date().setFullYear(new Date().getFullYear() - cnt + 1)).toISOString().slice(0, 10);
          const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - cnt)).toISOString().slice(0, 10);            
          const res = await http.getApiClient(this.getUserType()).get("", {
            params: {
              a: "get_trades",
              id: user.id,
              key: user.key,
              from: startDate,
              to: !this.dateTo ? endDate : this.getShortDateFromMs(this.dateTo)
            }
          });
          if (res) {
            // check answer
            if (ApiUtils.isApiResponseInvalidSession(res)) {
              // try silent relogin and repeat order posting for live users only
              if (this.$store.getters["getReloginUsernameLive"] && this.$store.getters["getReloginPasswordLive"]) {
                // stop the SSE listener to avoid session closed message
                this.$store.commit("setStopSse", true);
                const payload = {
                    api: http,
                    username: this.$store.getters["getReloginUsernameLive"],
                    password: this.$store.getters["getReloginPasswordLive"],
                    demo: false
                };
                if (0 === await this.$store.dispatch("loginUser", payload)) {
                    // repeat posting the command
                    cnt = cnt - 1;
                    continue;
                }
              }

              this.$store.commit("setAppStateReady");
              // logout
              setTimeout(
                ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                500);
              return;
            }          
            if (0 === res.data.ec) {
              if (0 === res.data.stocks.length) {
                break;
              }
              all.push(...res.data.stocks);
              this.$store.commit("setAppStateReady");
            } else {
              this.$store.commit("setAppStateReady");
              this.$toast.error(this.getCouldNotReadTradesMessage, {
                "duration": 5000,
                "pauseOnHover": true
              });
              this.$store.commit("addSystemMessage", {
                date: (new Date()).getTime(),
                mess: this.getCouldNotReadTradesMessage,
                error: true
              });
            }
          }
        }
        this.trades = all;
      } catch (err) {
        console.error(err);
      }
    },
    async getWallet() {
      const user = this.$store.getters["getUser"];
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "get_wallet",
            id: user.id,
            key: user.key,
            d: !this.dateTo ? Math.floor((new Date()).getTime() / 1000) : Math.floor(this.dateTo / 1000)
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            this.$store.commit("setWallet", res.data);
          } else {
            this.$toast.error(this.getCouldNotReadWalletMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotReadWalletMessage,
              error: true
            });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getTransactions() {
      const user = this.$store.getters["getUser"];
      try {
        this.$store.commit("setAppStateNotReady");
        // some users have a HUGE history, so retrieving the entire orders in one call may be slow
        // we call it by chunchs fo all years until start
        const iters = (new Date()).getFullYear() - this.minYearForWallet;
        let all = [];
        for (let cnt = 1; cnt <= iters; cnt++) {
          const endDate = new Date(new Date().setFullYear(new Date().getFullYear() - cnt + 1)).toISOString().slice(0, 10);
          const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - cnt)).toISOString().slice(0, 10);        
          const res = await http.getApiClient(this.getUserType()).get("", {
            params: {
              a: "get_transactions",
              id: user.id,
              key: user.key,
              from: startDate,
              to: !this.dateTo ? endDate : this.getShortDateFromMs(this.dateTo)
            }
          });
          if (res) {
            // check answer
            if (ApiUtils.isApiResponseInvalidSession(res)) {
              // try silent relogin and repeat order posting for live users only
              if (this.$store.getters["getReloginUsernameLive"] && this.$store.getters["getReloginPasswordLive"]) {
                // stop the SSE listener to avoid session closed message
                this.$store.commit("setStopSse", true);
                const payload = {
                    api: http,
                    username: this.$store.getters["getReloginUsernameLive"],
                    password: this.$store.getters["getReloginPasswordLive"],
                    demo: false
                };
                if (0 === await this.$store.dispatch("loginUser", payload)) {
                    // repeat posting the command
                    cnt = cnt - 1;
                    continue;
                }
              }

              this.$store.commit("setAppStateReady");
              // logout
              setTimeout(
                ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                500);
              return;
            }          
            if (0 === res.data.ec) {
              if (0 === res.data.t.length) {
                break;
              }
              all.push(...res.data.t);
              this.$store.commit("setAppStateReady");
            } else {
              this.$store.commit("setAppStateReady");
              this.$toast.error(this.getCouldNotReadWalletMessage, {
                "duration": 5000,
                "pauseOnHover": true
              });
              this.$store.commit("addSystemMessage", {
                date: (new Date()).getTime(),
                mess: this.getCouldNotReadWalletMessage,
                error: true
              });
            }
          }
        }
        this.transactions = all;
      } catch (err) {
        console.error(err);
      }
    },    
    parseNumber(num, afterDot = 2) {
      return UiUtils.formatNumber(num, afterDot);
    },
    openTransactionDetailsWindow(transaction, stock) {
      this.transactionDetails = transaction;
      this.transactionDetailsStockNameEn = stock.nEN;
      this.transactionDetailsStockNameBg = stock.nBG ? stock.nBG : stock.nEN;
      this.transactionDetailsStockCode = stock.sc;
      this.transactionDetailsStockCurrency = stock.cc;
      this.transactionDetailsModalShow = true;
      this.transactionDetailsModalActive = true;
    },
    getMoneyRatio(currency) {
      if (!currency) {
        return 0;
      }
      const wallet = this.$store.getters["getWallet"];

      if (!wallet || !wallet?.r) {
        return 0;
      }
      const rate = wallet.r.filter(item => {
        return 0 === item.c.toString().toUpperCase().localeCompare(currency.toString().toUpperCase() + '/BGN');
      });
      if (!rate || 0 === rate?.length) {
        return 0;
      }
      return rate[0].r;
    },
    getCurrencyAsPercentFromTotalMoney(curr) {
      if (0 === this.totalMoneyValue) {
        return 0;
      }
      if (0 === "BGN".localeCompare(curr.c.toUpperCase())) {
        return parseFloat((curr.a / this.totalMoneyValue) * 100);
      }
      const eur2curr = this.getMoneyRatio(curr.c);
      return parseFloat((curr.a * eur2curr / this.totalMoneyValue) * 100);
    },
    getCurrencyAsPercentFromTotalBalance(curr) {
      const totalBalance = this.totalMoneyValue + this.totalEquitiesValue;
      if (0 === totalBalance) {
        return 0;
      }
      if (0 === "BGN".localeCompare(curr.c.toUpperCase())) {
        return parseFloat((curr.a / totalBalance) * 100);
      }
      const eur2curr = this.getMoneyRatio(curr.c);
      return parseFloat((curr.a * eur2curr / totalBalance) * 100);
    },    
    formOrderObject(event) {
      this.orderModel.type = this.getOrderType(event.t);
      this.orderModel.ex = event.xc;
      this.orderModel.code = event.sc;
      this.orderModel.name = 0 === 'BG'.localeCompare(this.getUserLanguage)
        ? event.nBG
        : event.nEN;
      this.orderModel.amount = this.parseNumber(event.qty);
      this.orderModel.price = this.parseNumber(event.sp);
      this.orderModel.price_total = this.parseNumber(event.pt);
      this.orderModel.currency = event.cc;
      this.orderModel.oid = event.id;
      this.orderModel.fee = this.parseNumber(event.fee);
      this.orderModel.time = this.getDate(event.d);
      this.orderModel.total_amount = this.parseNumber(event.pt + event.fee);
    },
    formatBondsPrice(val, autoDigits = null) {
      return UiUtils.formatPrice(val, '%', this.getUserLanguage, autoDigits);
    },
    formatPrice(val, curr, autoDigits = null) {
      return UiUtils.formatPrice(val, curr, this.getUserLanguage, autoDigits);
    },
    getPercentDiff(marketPrice, avgPrice) {
      const diff = parseFloat(marketPrice) - parseFloat(avgPrice);
      return parseFloat(diff) / parseFloat(avgPrice) * 100;
    },     
  },
  computed: {
    getWindowsWidth() {
      return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
    },     
    getSelectedExchangeCode() {
      return this.$store.getters["getSelectedExchangeCode"];
    },
    getAllCurrencies() {
      const wallet = this.$store.getters["getWallet"];
      return wallet.r;
    },
    getUserCurrencies() {
      if (!this.transactions) {
        return [];
      }
      let allUserCurrenciesInWallet = [];
      this.transactions.forEach(element => {
        if (!allUserCurrenciesInWallet.includes(element.cc)) {
          allUserCurrenciesInWallet.push(element.cc);
        }
      });

      return allUserCurrenciesInWallet;
    },
    getAvailableXbulStocks() {
      const wallet = this.$store.getters["getWallet"];
      const exchanges = this.$store.getters["getExchanges"];
      let selectedExchanges = [];
      wallet.s.forEach(stock => {
        exchanges.forEach(exchange => {
          if (0 === exchange.cc.toString().toLowerCase().localeCompare(stock.cc.toString().toLowerCase())) {
            if (exchange.eID === 1) {
              selectedExchanges.push(stock);
            }
          }
        });
      });
      return selectedExchanges;
    },
    getAvailableXetrStocks() {
      const wallet = this.$store.getters["getWallet"];
      const exchanges = this.$store.getters["getExchanges"];
      let selectedExchanges = [];
      wallet.s.forEach(stock => {
        exchanges.forEach(exchange => {
          if (0 === exchange.cc.toString().toLowerCase().localeCompare(stock.cc.toString().toLowerCase())) {
            if (exchange.eID === 2) {
              selectedExchanges.push(stock);
            }
          }
        });
      });
      return selectedExchanges;
    },    
    getUserMoney() {
      const wallet = this.$store.getters["getWallet"];
      return wallet.m;
    },
    totalMoneyValue() {
      const currency = this.getCurrency;
      const userWallet = this.$store.getters["getWallet"];
      return UiUtils.getTotalMoney(userWallet, currency);
    },
    totalEquitiesValue() {
      const currency = this.getCurrency;
      const userWallet = this.$store.getters["getWallet"];

      return UiUtils.getTotalEquitiesAsMoney(userWallet, currency);
    },
    getAvailableTransactions() {
      // get selected currency
      if (0 === "ALL".localeCompare(this.selectedCurrency)) {
        return !this.transactions 
          ? []
          : this.transactions;
      }
      return this.transactions?.filter(item => {
        return 0 === item.cc?.toUpperCase().localeCompare(this.selectedCurrency);
      });
    },
    getAvailableOrders() {
      if (!this.orders) {
        return [];
      }
      // check selected exchange
      if (0 === parseInt(this.getSelectedExchangeCode)) {
        return this.orders;
      }
      const exchange = UiUtils.getExchangeCodeById(this.getExchanges, this.getSelectedExchangeCode);
      return this.orders.filter(item => {
        return 0 === item.xcode?.toUpperCase().localeCompare(exchange.toUpperCase());
      });
    },
    getUserLanguage() {
      return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
    },
    getSummaryMenuItem() {
      return Translations.getSummaryMenuItem(this.getUserLanguage); 
    }, 
    getPositionsMenuItem() {
      return Translations.getPositionsMenuItem(this.getUserLanguage);
    },
    getPositionsLabel() {
      return Translations.getPositionsLabel(this.getUserLanguage);
    }, 
    getTransactionsMenuItem() {
      return Translations.getTransactionsMenuItem(this.getUserLanguage);
    }, 
    getTransactionsLabel() {
      return Translations.getTransactionsLabel(this.getUserLanguage);
    },
    getTradesMenuItem() {
      return Translations.getTradesMenuItem(this.getUserLanguage);
    }, 
    getTradesLabel() {
      return Translations.getTradesLabel(this.getUserLanguage);
    },
    getOrdersMenuItem() {
      return Translations.getOrdersMenuItem(this.getUserLanguage);
    },
    getOrdersLabel() {
      return Translations.getOrdersLabel(this.getUserLanguage);
    },
    getOrderIdLabel() {
      return Translations.getOrderLabel(this.getUserLanguage);
    }, 
    getTypeLabel() {
      return Translations.getTypeLabel(this.getUserLanguage);
    },
    getOrderTypeLabel() {
      return Translations.getOrderTypeLabel(this.getUserLanguage);
    },
    getDateLabel() {
      return Translations.getDateLabel(this.getUserLanguage);
    },
    getTimestampLabel() {
      return Translations.getTimestampLabel(this.getUserLanguage);
    },
    getCurrencyLabel() {
      return Translations.getCurrencyLabel(this.getUserLanguage);
    },
    getQuantityLabel() {
      return Translations.getQuantityLabel(this.getUserLanguage);
    },
    getTotalAmountLabel() {
      return Translations.getTotalAmountLabel(this.getUserLanguage);
    },    
    getAmountLabel() {
      return Translations.getAmountLabel(this.getUserLanguage);
    },    
    getSumLabel() {
      return Translations.getSumLabel(this.getUserLanguage);
    },
    getPriceLabel() {
      return Translations.getPriceLabel(this.getUserLanguage);
    },  
    getPriceТotalLabel() {
      return Translations.getPriceТotalLabel(this.getUserLanguage);
    },
    getFeeLabel() {
      return Translations.getFeeLabel(this.getUserLanguage);
    },
    getNoteLabel() {
      return Translations.getNoteLabel(this.getUserLanguage);
    },
    getCodeNameLabel() {
      return Translations.getCodeNameLabel(this.getUserLanguage);
    },
    getCodeLabel() {
      return Translations.getCodeLabel(this.getUserLanguage);
    },
    getNameLabel() {
      return Translations.getNameLabel(this.getUserLanguage);
    },
    getQtyPriceLabel() {
      return Translations.getQuantityPriceLabel(this.getUserLanguage);
    },
    getStatusLabel() {
      return Translations.getStatusLabel(this.getUserLanguage);
    },
    getBalanceLabel() {
      return Translations.getBalanceLabel(this.getUserLanguage);
    },
    getMyPositionsLabel() {
      return Translations.getMyPositionsLabel(this.getUserLanguage);
    },
    getMyNumberOfInstrumentsLabel() {
      return Translations.getMyNumberOfInstrumentsLabel(this.getUserLanguage);
    },
    getChangeLabel() {
      return Translations.getChangeLabel(this.getUserLanguage);
    },
    getCouldNotReadOrdersMessage() {
      return Translations.getCouldNotReadOrdersMessage(this.getUserLanguage);
    },
    getCouldNotCancelOrderMessage() {
      return Translations.getCouldNotCancelOrderMessage(this.getUserLanguage);
    },
    getCancelledOrderMessage() {
      return Translations.getCancelledOrderMessage(this.getUserLanguage);
    },
    getCouldNotReadTradesMessage() {
      return Translations.getCouldNotReadTradesMessage(this.getUserLanguage);
    },
    getMoneyLabel() {
      return Translations.getMoneyLabel(this.getUserLanguage);
    },
    getAllExchangesLabel() {
      return Translations.getAllExchangesLabel(this.getUserLanguage);
    },
    getАllCurrenciesLabel() {
      return Translations.getАllCurrenciesLabel(this.getUserLanguage);
    },
    getInformationLabel() {
      return Translations.getInformationLabel(this.getUserLanguage);
    },
    getStockCodeLabel() {
      return Translations.getCodeLabel(this.getUserLanguage);
    },
    getStockNameLabel() {
      return Translations.getNameLabel(this.getUserLanguage);
    },
    getMarketPriceLabel() {
      return Translations.getMarketPriceLabel(this.getUserLanguage);
    },
    getMarketValueLabel() {
      return Translations.getMarketValueLabel(this.getUserLanguage);
    },
    getAveragePriceLabel() {
      return Translations.getAveragePriceLabel(this.getUserLanguage);
    },
    getConfirmDeleteValueLabel() {
      return Translations.getConfirmCancelOrderLabel(this.getUserLanguage);
    },
    getCloseLabel() {
      return Translations.getCloseLabel(this.getUserLanguage);
    },
    getConfirmLabel() {
      return Translations.getConfirmLabel(this.getUserLanguage);
    },
    getCancelLabel() {
      return Translations.getCancelLabel(this.getUserLanguage);
    },
    getUpdateLabel() {
      return Translations.getUpdateLabel(this.getUserLanguage);
    },
    getTransactionDetailsLabel() {
      return Translations.getInformationLabel(this.getUserLanguage);
    },
    getInvalidSessionLabel() {
      return Translations.getInvalidSessionLabel(this.getUserLanguage);
    },    
    getCurrency() {
      return this.$store.getters["getCurrency"].toUpperCase();
    },
    getXbulEquitiesValueInBgn() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xbul', 'bgn', userWallet);
    },
    getXbulEquitiesValueInEur() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xbul', 'eur', userWallet);
    }, 
    getXbulEquitiesValueInCurrentCurrency() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xbul', this.getCurrency, userWallet);
    }, 
    getXbulEquitiesAvgValueInBgn() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesAverageValue('xbul', 'bgn', userWallet);
    },
    getXbulEquitiesAvgValueInEur() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesAverageValue('xbul', 'eur', userWallet);
    },
    getXetrEquitiesValueInBgn() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xetr', 'bgn', userWallet);
    },
    getXetrEquitiesValueInEur() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xetr', 'eur', userWallet);
    },
    getXetrEquitiesValueInCurrentCurrency() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xetr', this.getCurrency, userWallet);
    },
    getXetrEquitiesAvgValueInBgn() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesAverageValue('xetr', 'bgn', userWallet);
    },
    getXetrEquitiesAvgValueInEur() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesAverageValue('xetr', 'eur', userWallet);
    },
    getXfraEquitiesValueInBgn() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xfra', 'bgn', userWallet);
    },
    getXfraEquitiesValueInEur() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xfra', 'eur', userWallet);
    },
    getXfraEquitiesValueInCurrentCurrency() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesValue('xfra', this.getCurrency, userWallet);
    },
    getXfraEquitiesAvgValueInBgn() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesAverageValue('xfra', 'bgn', userWallet);
    },
    getXfraEquitiesAvgValueInEur() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.extractExchangeEquitiesAverageValue('xfra', 'eur', userWallet);
    },
    getAllEquitiesValueInCurrentCurrency() {
      return parseFloat(parseFloat(this.getXbulEquitiesValueInCurrentCurrency) +
        parseFloat(this.getXetrEquitiesValueInCurrentCurrency) + parseFloat(this.getXfraEquitiesValueInCurrentCurrency));
    },
    getEquitiesBgnAsPercent() {
      const totalEquitiesValue = this.totalEquitiesValue;
      if (0 === totalEquitiesValue) {
        return 0;
      }
      const currency = this.getCurrency;
      if (0 === "BGN".localeCompare(currency.toUpperCase())) {
        return parseFloat((this.getXbulEquitiesValueInBgn / totalEquitiesValue) * 100);
      }
      const eur2bgn = this.getMoneyRatio("eur");
      return parseFloat((this.getXbulEquitiesValueInBgn * (1 / eur2bgn) / totalEquitiesValue) * 100);
    },
    getXbulEquitiesPositionsCount() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }

      return UiUtils.extractExchangePositionsCount('xbul', userWallet);
    },        
    getXbulEquitiesSharesCount() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }

      return UiUtils.extractExchangeEquitiesCount('xbul', userWallet);
    },
    getXetrEquitiesPositionsCount() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }

      return UiUtils.extractExchangePositionsCount('xetr', userWallet);
    },
    getXetrEquitiesSharesCount() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }

      return UiUtils.extractExchangeEquitiesCount('xetr', userWallet);
    },
    getXfraEquitiesPositionsCount() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }

      return UiUtils.extractExchangePositionsCount('xfra', userWallet);
    },
    getXfraEquitiesSharesCount() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }

      return UiUtils.extractExchangeEquitiesCount('xfra', userWallet);
    },      
    getEurEquitiesSharesCount() {
      const userWallet = this.$store.getters["getWallet"];
      let totalShares = 0;
      userWallet.s?.forEach(item => {
        if (0 === item.cc.toString().toLowerCase().localeCompare("eur")) {
          totalShares += item.am;
        }
      });

      return totalShares;
    },
    getTotalSharesCount() {
      return this.getXbulEquitiesSharesCount + this.getEurEquitiesSharesCount;
    },
    getExchanges() {
      return this.$store.getters["getExchanges"];
    },
    totalAssetsValue() {
      const currency = this.getCurrency;
      const userWallet = this.$store.getters["getWallet"];
      return UiUtils.getTotalMoney(userWallet, currency);
    },
    totalBalance() {
      return parseFloat(this.totalEquitiesValue) + parseFloat(this.getFreeFunds);
    },
    getMoneyPercentage() {
      const totalBalance = this.totalBalance;      
      return 0 === totalBalance
        ? 0
        : this.parseNumber(parseFloat(this.totalAssetsValue) / parseFloat(totalBalance) * 100);
    },
    getEquitiesPercentage() {
      const totalBalance = this.totalBalance;      
      return 0 === totalBalance
        ? 0
        : this.parseNumber(parseFloat(this.totalEquitiesValue) / parseFloat(totalBalance) * 100);
    },
    convertSelectedDateFromToUnixtime() {      
      return "" !== this.dateFromString ? (new Date(this.dateFromString)).getTime() : 0;
    },
    convertSelectedDateToToUnixtime() {
      return "" !== this.dateToString ? (new Date(this.dateToString)).getTime() : 0;
    },
    getMinWalletDate() {
      const transactions = this.transactions;
      if (!transactions){
        return new Date().toISOString().slice(0, 10);
      }
      // get first transaction
      let firstTransaction = null;
      transactions.forEach(trans => {
        if (null === firstTransaction) {
          firstTransaction = trans;
        } else {
          if (parseInt(firstTransaction.ts) > parseInt(trans.ts)) {
            firstTransaction = trans;
          }
        }
      });
      return null !== firstTransaction ?
        this.getShortDate(firstTransaction.ts) :
        new Date().toISOString().slice(0, 10);
    },
    getSelectedExchange() {
      const exchanges = this.$store.getters["getExchanges"];
      return exchanges.filter(item => {
        return parseInt(item.eID) === parseInt(this.getSelectedExchangeCode);
      })[0];
    },
    getFilteredTrades() {
      let tradesToReturn = this.trades;
      if (!tradesToReturn) {
        return [];
      }
      // filter by exchange
      const selectedExchange = this.getSelectedExchange;
      if (selectedExchange) {
        tradesToReturn = tradesToReturn.filter(item => {
          return 0 === item?.xc?.toLowerCase().localeCompare(selectedExchange.code.toLowerCase());
        });
      }

      return tradesToReturn;
    },
    getReestablishingConnectionLabel() {
      return Translations.getReestablishingConnectionLabel(this.getUserLanguage);
    },
    getReestablishedConnectionLabel() {
      return Translations.getReestablishedConnectionLabel(this.getUserLanguage);
    },
    getTradeLabel() {
      return Translations.getTradeLabel(this.getUserLanguage);
    },
    getGPieChartData() {
      const series = [];
      // money
      series.push({
        name: this.getMoneyLabel,
        data: [parseFloat(this.totalMoneyValue)]
      });
      // equities
      series.push({
        name: this.getPositionsLabel,
        data: [parseFloat(this.totalEquitiesValue)]
      });

      return series;
    },
    getEquitiesLabel() {
      return Translations.getEquitiesLabel(this.getUserLanguage);
    },
    getBondsLabel() {
      return Translations.getBondsLabel(this.getUserLanguage);
    },
    getCompInstrumentsLabel() {
      return Translations.getCompInstrumentsLabel(this.getUserLanguage);
    },
    getFundsLabel() {
      return Translations.getFundsLabel(this.getUserLanguage);
    },
    getReitsLabel() {
      return Translations.getReitsLabel(this.getUserLanguage);
    },
    getRightsLabel() {
      return Translations.getRightsLabel(this.getUserLanguage);
    },
    getCfdLabel() {
      return Translations.getCfdLabel(this.getUserLanguage);
    },
    getMarginLabel() {
      return Translations.getMarginLabel(this.getUserLanguage);
    },
    getNettingLabel() {
      return Translations.getNettingLabel(this.getUserLanguage);
    },
    getRepoLabel() {
      return Translations.getRepoLabel(this.getUserLanguage);
    },
    getAssetLabel() {
      return Translations.getAssetLabel(this.getUserLanguage);
    },
    getCountLabel() {
      return Translations.getCountLabel(this.getUserLanguage);
    },
    getBalanceSummaryLabel() {
      return Translations.getBalanceSummaryLabel(this.getUserLanguage);
    }, 
    getTotalBalanceLabel() {
      return Translations.getTotalBalanceLabel(this.getUserLanguage);
    }, 
    getCashLabel() {
      return Translations.getCashLabel(this.getUserLanguage);
    },
    getPositionsBalanceLabel() {
      return Translations.getPositionsBalanceLabel(this.getUserLanguage);
    }, 
    getBaseCurrencyLabel() {
      return Translations.getBaseCurrencyLabel(this.getUserLanguage);
    },
    getPerformanceLabel() {
      return Translations.getPerformanceLabel(this.getUserLanguage);
    },
    getReturnLabel() {
      return Translations.getReturnLabel(this.getUserLanguage);
    },
    getDetailsLabel() {
      return Translations.getDetailsLabel(this.getUserLanguage);
    },
    getInSettlementLabel() {
        return Translations.getInSettlementLabel(this.getUserLanguage);
    },
    getFreeFundsLabel() {
        return Translations.getFreeFundsLabel(this.getUserLanguage);
    },
    getGuaranteeDepositLabel() {
        return Translations.getGuaranteeDepositLabel(this.getUserLanguage);
    },
    getExcessFundsLabel() {
        return Translations.getExcessFundsLabel(this.getUserLanguage);
    },
    getAvailableToWithdrawLabel() {
        return Translations.getAvailableToWithdrawLabel(this.getUserLanguage);
    },
    getTotalEquitiesInBuySettlementAsMoney() {
        const userWallet = this.$store.getters["getWallet"];
        if (!userWallet) {
            return 0;
        }
        return UiUtils.getTotalEquitiesInBuySettlementAsMoney(userWallet, this.getCurrency);
    },
    getTotalEquitiesInSellSettlementAsMoney() {
        const userWallet = this.$store.getters["getWallet"];
        if (!userWallet) {
            return 0;
        }
        return UiUtils.getTotalEquitiesInSellSettlementAsMoney(userWallet, this.getCurrency);
    },
    getFreeFunds() {
        // cash - getTotalEquitiesInBuySettlementAsMoney + getTotalEquitiesInSellSettlementAsMoney
        return parseFloat(this.totalAssetsValue)
            + parseFloat(this.getTotalEquitiesInBuySettlementAsMoney)
            + parseFloat(this.getTotalEquitiesInSellSettlementAsMoney);
    },
    getGuaranteeDeposit() {
      const userWallet = this.$store.getters["getWallet"];
      if (!userWallet) {
        return 0;
      }
      return UiUtils.getGuaranteeDeposit(userWallet, this.getCurrency);
    },
    getExcessFunds() {
      const exFunds = parseFloat(this.getFreeFunds) - parseFloat(this.getGuaranteeDeposit);
      return exFunds < 0
        ? 0
        : exFunds;
    },
    getAvailableToWithdraw() {
      const available = parseFloat(this.totalAssetsValue)
        + parseFloat(this.getTotalEquitiesInBuySettlementAsMoney)
        - parseFloat(this.getGuaranteeDeposit);
      return available < 0
        ? 0
        : available;
    },
    stopSseState() {
      return this.$store.getters["getStopSse"];
    }
  },
  async mounted() {
    // reload current menu selected when returning from details
    if (this.latestActiveMenuSelection) {
      // call appropriate data retrieval
      if (0 === this.latestActiveMenuSelection.localeCompare('Orders')) {
        (async () => await this.getOrders())();
      } else if (0 === this.latestActiveMenuSelection.localeCompare('Trades')) {
        (async () => await this.getTrades())();
      } else if (0 === this.latestActiveMenuSelection.localeCompare('Transactions')) {
        (async () => await this.getTransactions())();
      }      
    }
    await this.subscribeForSse();
    // start sse
    this.setupStream();
    // start sse monitor
    this.sseVerifier = window.setInterval(function () {
      if (EventSource.OPEN !== this.sseClient?.readyState || !this.hasEvents) {
        // close current client if any
        if (this.sseClient) {
          try {
            this.sseClient.close();
          } catch (err) {
            console.error(err);
          }
          this.sseClient = null;
        }
        this.setupStream();
      }
    }.bind(this), 3000);
  },
  async unmounted() {
    // stop verifier
    if (this.sseVerifier) {
      clearInterval(this.sseVerifier);
    }
    // close sse
    if (this.sseClient) {
      try {
        this.sseClient.close();
      } catch (err) {
        console.error(err);
      }
    }
    // restore wallet
    this.dateTo = 0;
    await this.getWallet();
  }
}
</script>

<style scoped>
  th {
    text-align: left
  }
  .xsmall-font-size {
    font-size: x-small;
  }

  #user-stocks {
    max-height: 35rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  span {
    display: inline-block;
    width: 11rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  .greenColor {
    color: #74b21f;
  }

  .redColor {
    color: #ff0000;
  }

  .data-column {
    min-width: 4.5rem;
    vertical-align: middle;
    text-align: center;
  }

  .data-column-large {
    min-width: 4.5rem;
    vertical-align: middle;
    text-align: center;
    font-size: large;
  }

  .equity_align_right {
    vertical-align: middle;
    text-align: right;
  }
  .equity_align_right_heavy {
    vertical-align: middle;
    text-align: right;
    font-weight: bold;
  }
  .equity_align_left {
    vertical-align: middle;
    text-align: left;
  }

  a.disabled {
    pointer-events: none;
  }

  .price-data {
    vertical-align: middle;
    text-align: end;
    display: table-cell;
  }

  .clickable_item {
    cursor:  pointer;
  }
.dot_money {
    height: 10px;
    width: 10px;
    background-color: #00E396;
    border-radius: 50%;
    display: inline-block;
}
.dot_instruments {
    height: 10px;
    width: 10px;
    background-color: #008FFB;
    border-radius: 50%;
    display: inline-block;
}

  .ion-md-close-circle {
    cursor: pointer;
  }

  .ion-ios-refresh {
    cursor: pointer;
  }

  .table-responsive {
    overflow: auto;
    max-height: 45rem;
  }

  .card-img-top {
      width: 100%;
      height: 4vw;
      object-fit: scale-down;
  }

  .card-img-top-responsive {
      width: 100%;
      height: 15vw;
      object-fit: scale-down;
  }  

  .show_list_horizontal {
      overflow-x: auto; 
      max-width: 100%; 
      max-height: auto; 
      flex: 1; 
      flex-wrap: nowrap;
  } 
  
.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
}   
</style>