<template>
    <apexchart type="pie" :options="options" :series="series" :width="width" :height="height" />
</template>

<script>
import UiUtils from "@/assets/js/ui_utils.js";

export default {
    name: "GPieChart",
    props: {
        data: {
            required: true,
            type: Array
        },
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 200
        },
        lbls: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            series: this.data,
            options: {
                legend: {
                    show: false
                },
                labels: this.lbls,
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return UiUtils.formatNumber(val);
                        },
                        title: {
                            formatter: function (seriesName) {
                                return seriesName;
                            }
                        }
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -10,
                            minAngleToShowLabel: 10
                        }
                    },
                },
            },
        }
    },
    watch: {
        data: function (newData) {
            this.series = newData;
        },
    }
}
</script>