import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/css/ionicons.min.css";
import "./assets/scss/style.scss";
import router from "./router";
import store from "./store";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueApexCharts from 'vue3-apexcharts';

createApp(App)
    .use(store)
    .use(router)
    .use(VueToast, {
        position: "top-right"
    })
    .use(VueApexCharts)
    .mount("#app");
