import axios, { AxiosInstance } from "axios";

const apiClientSandbox: AxiosInstance = axios.create({
    baseURL: "https://api.sandbox.efocs.app/api/actions",
    params: { vn: "100", os: "b", vs: "demoapp" },
    headers: {
        "Content-type": "application/json",
    },
});
// add a request interceptor for axios
apiClientSandbox.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    console.error(error)
    return Promise.reject(error);
});

// add a response interceptor for axios
apiClientSandbox.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.error(error)
    return Promise.reject(error);
});

const apiClientProduction: AxiosInstance = axios.create({
    baseURL: "https://api.prod.efocs.app/api/actions",
    params: { vn: "100", os: "b", vs: "demoapp" },
    headers: {
        "Content-type": "application/json",
    },
});

// add a request interceptor for axios
apiClientProduction.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    console.error(error)
    return Promise.reject(error);
});

// add a response interceptor for axios
apiClientProduction.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.error(error)
    return Promise.reject(error);
});

// export appropriate client
function getApiClient(userType: number) {
    switch (userType) {
        case 1: {
            return apiClientProduction;
        }
        default: {
            return apiClientSandbox;
        }
    }
}

export default {
    getApiClient: getApiClient
};