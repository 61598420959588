<template>
    <!-- large viewport -->
    <table v-if="1200 < getWindowsWidth" class="table w-100">
        <thead>
            <tr>
                <th></th>
                <th>{{ getExchangeLabel.toUpperCase() }}</th>
                <th>{{ getCodeLabel.toUpperCase() }}</th>
                <th>{{ getNameLabel.toUpperCase() }}</th>
                <th class="w-20">{{ getChangeLabel.toUpperCase() }} (&Delta;)</th>
                <th class="w-20">{{ getChangeLabel.toUpperCase() }} (&Delta;%)</th>
                <th class="w-20">{{ getLastDealLabel.toUpperCase() }}</th>
                <th class="w-20">{{ getTurnoverLabel.toUpperCase() }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in items" :key="item.ex + item.sc">
                <!-- Modal add to watchlist -->
                <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addToWatchlistLabel"
                    aria-hidden="true"
                    :class="{ 'show': watchlistSelectionModalShow, 'd-block': watchlistSelectionModalActive }">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="addToWatchlistLabel">{{ getWatchlistSelectionLabel }}</h5>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="btn-group d-flex mtb15" role="group">
                                        <input type="radio" class="btn-check" :id="'newwl' + item.sc" autocomplete="off"
                                            value="new" v-model="newWatchlistSelected">
                                        <label class="btn btn-outline-primary" :for="'newwl' + item.sc">{{
                                            getNewWatchlistNameLabel
                                            }}</label>
                                        <input type="radio" class="btn-check" :id="'existwl' + item.sc" value="old"
                                            v-model="newWatchlistSelected" autocomplete="off">
                                        <label class="btn btn-outline-primary" :for="'existwl' + item.sc">{{
                                            getExistingWatchlistNameLabel
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row" v-show="'new' === newWatchlistSelected">
                                    <input :id="'watchlistName' + item.sc" type="text" class="form-control"
                                        :placeholder="getWatchlistNamePlaceholder" v-model="watchlistName" />
                                </div>
                                <div class="row" v-show="'new' !== newWatchlistSelected">
                                    <label :htmlFor="'watchlistName' + item.sc">{{ getWatchlistSelectionLabel }}</label>
                                    <select :id="'watchlistName' + item.sc" class="form-select" v-model="watchlistName">
                                        <option v-for="(watchlist) in getWatchlists" :key="watchlist.wln"
                                            :value="watchlist.wln">{{ watchlist.wln }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal"
                                    @click="updateWatchlist()" :disabled="'' === watchlistName">{{
                                    getSaveLabel
                                    }}</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                                    @click="closeModal()">{{
                                    getCloseLabel
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <td>
                    <div v-show="!isStockPartOfDefaultWatchlist(item.sc, item.ex)" class="icon">
                        <i @click="updateDefaultWatchlist(item.sc, item.ex)" class="icon ion-md-add"></i>
                    </div>
                    <div v-show="isStockPartOfWatchlist(item.sc, item.ex)" class="icon">
                        <i @click="removeFromWatchlist(item.sc, item.ex)" class="icon ion-md-remove-circle"></i>
                    </div>
                </td>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)">{{ item.ex.toString().toUpperCase()
                    }}</td>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)">{{ item.sc.toString().toUpperCase()
                    }}</td>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)"><strong>{{ getStockName(item.sc,
                        item.ex) }}</strong>
                </td>
                <td v-if="!item.ch || 0 == parseFloat(item.ch)"></td>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)" v-if="0 < parseFloat(item.ch)"
                    class="align-text-right" :class="getIndexItemColor(item.ch)">
                    +{{ parseNumber(item.ch * item.lp / 100) }}
                </td>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)" v-if="0 > parseFloat(item.ch)"
                    class="align-text-right" :class="getIndexItemColor(item.ch)">
                    {{ parseNumber(item.ch * item.lp / 100) }}
                </td>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)" v-if="0 < parseFloat(item.ch)"
                    class="align-text-right" :class="getIndexItemColor(item.ch)">
                    +{{ parseNumber(item.ch) }}%
                </td>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)" v-if="0 > parseFloat(item.ch)"
                    class="align-text-right" :class="getIndexItemColor(item.ch)">
                    {{ parseNumber(item.ch) }}%
                </td>
                <td v-if="!item.ch || 0 == parseFloat(item.ch)"></td>
                <td v-show="isNaN(parseFloat(item.lp))" align="center" colspan="4">
                    <Preloader></Preloader>
                </td>
                <td class="align-text-right">{{ parseFloat(item.lp) }}</td>
                <td class="align-text-right">{{ parseFloat(item.dv) }}</td>
            </tr>
        </tbody>
    </table>
    <!-- small viewport -->
    <table v-else class="table w-100">
        <thead>
            <tr>
                <th class="center-column xsmall-font-size">{{ getStockLabel.toUpperCase() }}</th>
                <th class="center-column xsmall-font-size">{{ getLastDealLabel.toUpperCase() }}</th>
                <th class="center-column xsmall-font-size">{{ getTurnoverLabel.toUpperCase() }}</th>
                <th class="center-column xxsmall-font-size"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in items" :key="item.ex + item.sc" class="w-75">
                <!-- Modal add to watchlist -->
                <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addToWatchlistLabel"
                    aria-hidden="true"
                    :class="{ 'show': watchlistSelectionModalShow, 'd-block': watchlistSelectionModalActive }">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="addToWatchlistLabel">{{ getWatchlistSelectionLabel }}</h5>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="btn-group d-flex mtb15" role="group">
                                        <input type="radio" class="btn-check" :id="'newwl' + item.sc" autocomplete="off"
                                            value="new" v-model="newWatchlistSelected">
                                        <label class="btn btn-outline-primary" :for="'newwl' + item.sc">{{
                                            getNewWatchlistNameLabel
                                            }}</label>
                                        <input type="radio" class="btn-check" :id="'existwl' + item.sc" value="old"
                                            v-model="newWatchlistSelected" autocomplete="off">
                                        <label class="btn btn-outline-primary" :for="'existwl' + item.sc">{{
                                            getExistingWatchlistNameLabel
                                            }}</label>
                                    </div>
                                </div>
                                <div class="row" v-show="'new' === newWatchlistSelected">
                                    <input :id="'watchlistName' + item.sc" type="text" class="form-control"
                                        :placeholder="getWatchlistNamePlaceholder" v-model="watchlistName" />
                                </div>
                                <div class="row" v-show="'new' !== newWatchlistSelected">
                                    <label :htmlFor="'watchlistName' + item.sc">{{ getWatchlistSelectionLabel }}</label>
                                    <select :id="'watchlistName' + item.sc" class="form-select" v-model="watchlistName">
                                        <option v-for="(watchlist) in getWatchlists" :key="watchlist.wln"
                                            :value="watchlist.wln">{{ watchlist.wln }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-dismiss="modal"
                                    @click="updateWatchlist()" :disabled="'' === watchlistName">{{
                                    getSaveLabel
                                    }}</button>
                                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                                    @click="closeModal()">{{
                                    getCloseLabel
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <td @click="openExtendedInfo(item.sc, item.ex, item.sgm, item.tID)" class="quote-item">
                    <div class="xxsmall-font-size d-flex justify-content-start">
                        <div>{{ item.sc.toString().toUpperCase() }}@{{ item.ex.toString().toUpperCase() }}&nbsp;</div>
                        <div v-if="0 < parseFloat(item.ch)" class="align-text-right"
                            :class="getIndexItemColor(item.ch)">+{{ parseNumber(item.ch) }}%&nbsp;</div>
                        <div v-if="0 > parseFloat(item.ch)" class="align-text-right"
                            :class="getIndexItemColor(item.ch)">{{ parseNumber(item.ch) }}%&nbsp;</div>
                        <div v-if="0 < parseFloat(item.ch)" class="align-text-right"
                            :class="getIndexItemColor(item.ch)">(+{{ parseNumber(item.ch * item.lp / 100) }})&nbsp;
                        </div>
                        <div v-if="0 > parseFloat(item.ch)" class="align-text-right"
                            :class="getIndexItemColor(item.ch)">({{ parseNumber(item.ch * item.lp / 100) }})&nbsp;</div>
                    </div>
                    <div class="row">
                        <span style="max-width: 8rem;"><strong>{{ getStockName(item.sc, item.ex) }}</strong></span>
                    </div>
                </td>
                <td v-show="isNaN(parseFloat(item.ch))" align="center" colspan="2">
                    <Preloader></Preloader>
                </td>
                <td class="align-text-right">{{ parseFloat(item.lp) }}</td>
                <td class="align-text-right">{{ parseFloat(item.dv) }}</td>
                <td v-if="!isStockPartOfDefaultWatchlist(item.sc, item.ex)" class="icon">
                    <i @click="updateDefaultWatchlist(item.sc, item.ex)" class="icon ion-md-add"></i>
                </td>
                <td v-else class="icon">
                    <i @click="removeFromWatchlist(item.sc, item.ex)" class="icon ion-md-remove-circle"></i>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import http from "@/axios/http-common";
import UiUtils from "@/assets/js/ui_utils.js";
import ApiUtils from "@/assets/js/api_utils.js";
import Preloader from "../components/Preloader.vue";
import Translations from "@/lang/translations.js";

export default {
    components: {
        Preloader
    },    
    data() {
        return {
            /* active modal dialog for watchlist selection */
            watchlistSelectionModalActive: false,
            /* show modal dialog for watchlist selection */
            watchlistSelectionModalShow: false,
            newWatchlistSelected: "new",
            watchlistName: "",
        }
    },
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    methods: {
        getIndexItemColor(change) {
            if (isNaN(parseFloat(change)) || 0 === parseFloat(change)) {
                return "blackColor";
            }
            if (0 > parseFloat(change)) {
                return "redColor";
            }
            return "greenColor";
        },
        parseNumber(num) {
            return UiUtils.formatNumber(num);
        },
        openExtendedInfo(itemCode, itemExchangeCode, itemSegment, itemTypeId) {
            // extract item exchange if
            const exchanges = this.$store.getters["getExchanges"];
            const itemExchangeId = UiUtils.getExchangeIdByCode(exchanges, itemExchangeCode);
            this.$store.commit("setSelectedTradingCode", itemCode);
            this.$store.commit("setSelectedTradingCodeSegment", itemSegment);
            this.$store.commit("setSelectedTradingCodeShareTypeId", itemTypeId);            
            this.$store.commit("setSelectedTradingExchangeId", itemExchangeId);
            this.$store.commit("setSelectedTradingStockName", this.getStockName(itemCode, itemExchangeCode));
            this.$router.push({ name: "ExtendedStock", params: { stockCode: itemCode, itemExchangeId: itemExchangeId, itemStockCodeSegment: itemSegment } });
        },
        choseWatchlist() {
            this.watchlistSelectionModalActive = true;
            this.watchlistSelectionModalShow = true;
        },
        closeModal() {
            this.watchlistSelectionModalActive = false;
            this.watchlistSelectionModalShow = false;
        },
        async updateWatchlist(itemCode, itemExchangeCode) {
            const updateResponse = await ApiUtils.saveWatchlist(
                http, UiUtils, this.$store, itemExchangeCode, itemCode, "new" === this.newWatchlistSelected, this.watchlistName);
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 1: {
                            // occupied name
                            this.$toast.error(this.getWatchlistNameOccupiedMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistNameOccupiedMessage,
                                error: true
                            });
                            break;
                        }
                        case 2: {
                            // watchlist contains element
                            this.$toast.error(this.getWatchlistContainsStockMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistContainsStockMessage,
                                error: true
                            });
                            break;
                        }
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.$toast.success(this.getAddedToWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getAddedToWatchlistMessage,
                        error: false
                    });
                }
            }
            this.watchlistSelectionModalActive = false;
            this.watchlistSelectionModalShow = false;
        },
        async updateDefaultWatchlist(itemCode, itemExchangeCode) {
            const updateResponse = await ApiUtils.updateDefaultWatchlist(http, UiUtils, this.$store, itemExchangeCode, itemCode);
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 2: {
                            // watchlist contains element
                            this.$toast.error(this.getWatchlistContainsStockMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistContainsStockMessage,
                                error: true
                            });
                            break;
                        }
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.$toast.success(this.getAddedToWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getAddedToWatchlistMessage,
                        error: false
                    });
                }
            }

            this.watchlistSelectionModalActive = false;
            this.watchlistSelectionModalShow = false;
        },
        async removeFromWatchlist(itemCode, itemExchangeCode) {
            const updateResponse = await ApiUtils.removeFromWatchlist(http, UiUtils, this.$store, itemExchangeCode, itemCode);
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.$toast.success(this.getRemovedFromWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getRemovedFromWatchlistMessage,
                        error: false
                    });
                }
            }
        },
        formatPrice(val, curr) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage);
        },
        getStockName(stockCode, exchangeCode) {
            const stocks = this.$store.getters["getStocks"];
            const indexedStocks = this.$store.getters["getIndexedStocks"];
            const exchanges = this.$store.getters["getExchanges"];
            const exchangeId = UiUtils.getExchangeIdByCode(exchanges, exchangeCode);
            return UiUtils.getStockName(stocks, indexedStocks, stockCode, exchangeId, this.getUserLanguage);
        },
        getExchangeCurrency(itemExchangeCode) {
            const exchanges = this.$store.getters["getExchanges"];
            return UiUtils.getExchangeCurrencyByCode(exchanges, itemExchangeCode);
        },
        isStockPartOfWatchlist(itemCode, itemExchangeCode) {
            if ("" === itemCode) {
                return false;
            }
            const user = this.$store.getters["getUser"];
            let hasItem = false;
            for (let cnt = 0; cnt < user.wls.length; cnt++) {
                const wl = user.wls[cnt];
                hasItem = UiUtils.verifyPairListContainsElement(wl.sc, UiUtils.createExchangeStockCodePairObject(itemExchangeCode, itemCode));
                if (hasItem) { break; }
            }
            return hasItem;
        },
        isStockPartOfDefaultWatchlist(itemCode, itemExchangeCode) {
            if ("" === itemCode) {
                return false;
            }
            const user = this.$store.getters["getUser"];
            let hasItem = false;
            for (let cnt = 0; cnt < user.wls.length; cnt++) {
                const wl = user.wls[cnt];
                if (0 == wl.wln.toString().toLowerCase().localeCompare("default")) {
                    hasItem = UiUtils.verifyPairListContainsElement(wl.sc, UiUtils.createExchangeStockCodePairObject(itemExchangeCode, itemCode));
                    if (hasItem) { break; }
                }
            }
            return hasItem;
        }        
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },        
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getWatchlistSelectionLabel() {
            return Translations.getWatchlistSelectionLabel(this.getUserLanguage);
        },
        getCloseLabel() {
            return Translations.getCloseLabel(this.getUserLanguage);
        },
        getSaveLabel() {
            return Translations.getSaveLabel(this.getUserLanguage);
        },
        getNewWatchlistNameLabel() {
            return Translations.getNewWatchlistNameLabel(this.getUserLanguage);
        },
        getExistingWatchlistNameLabel() {
            return Translations.getExistingWatchlistNameLabel(this.getUserLanguage);
        },
        getWatchlistNameLabel() {
            return Translations.getWatchlistLabel(this.getUserLanguage);
        },
        getWatchlistNamePlaceholder() {
            return Translations.getWatchlistNamePlaceholder(this.getUserLanguage);
        },
        getCouldNotUpdateWatchlistMessage() {
            return Translations.getCouldNotUpdateWatchlistMessage(this.getUserLanguage);
        },
        getWatchlistNameOccupiedMessage() {
            return Translations.getWatchlistNameOccupiedMessage(this.getUserLanguage);
        },
        getWatchlistContainsStockMessage() {
            return Translations.getWatchlistContainsStockMessage(this.getUserLanguage);
        },
        getRemovedFromWatchlistMessage() {
            return Translations.getRemovedFromWatchlistMessage(this.getUserLanguage);
        },
        getAddedToWatchlistMessage() {
            return Translations.getAddedToWatchlistMessage(this.getUserLanguage);
        },
        getStockLabel() {
            return Translations.getStockLabel(this.getUserLanguage);
        },
        getLastDealLabel() {
            return Translations.getLastDealLabel(this.getUserLanguage);
        },
        getTurnoverLabel() {
            return Translations.getTurnoverLabel(this.getUserLanguage);
        },
        getExchangeLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage);
        },
        getCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage);
        },
        getChangeLabel() {
            return Translations.getChangeLabel(this.getUserLanguage);
        },
        getNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage);
        },                
        getWatchlists() {
            const user = this.$store.getters["getUser"];
            return user.wls ? user?.wls : [];
        },
    }
}
</script>

<style scoped>
tr {
    width: 100%;
    vertical-align: middle;
}

.table td, .table th { padding: 0.75rem; }

.small-first {
    max-width: 4rem;
}

.stock-data {
    min-width: 4.5rem;
    max-width: 6.5rem;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
}

.greenColor {
    color: #74b21f;
}

.redColor {
    color: #ff0000;
}

.whiteColor {
    color: #ffffff;
}

.blackColor {
    color: #000000;
}

.greenBackgroundColor {
    background: #74b21f;
    border: 1px solid #74b21f;
    border-radius: 8px;
}

.redBackgroundColor {
    background: #ff0000;
    border: 1px solid #ff0000;
    border-radius: 8px;
}

.whiteBackgroundColor {
    background: #ffffff;
    border: 1px solid black;
    border-radius: 8px;
}

span {
    display: inline-block;
    width: 16rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
}

.align-text-right {
    text-align: right !important;
}

.align-text-center {
    text-align: center !important;
}

.quote-item {
    cursor: pointer;
}
.ion-md-add {
    cursor: pointer;
}

.ion-md-add-circle {
    cursor: pointer;
}

.ion-md-remove-circle {
    cursor: pointer;
}

.wls_buttons_top10 {
    max-width: 2rem;
    padding-right: 3rem;
}

  .center-column {
    vertical-align: middle;
    text-align: center;
  }
</style>