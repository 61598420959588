/*
 * Company: WHIZ Ltd.
 * Project: TA
 * Module: WebApplication
 * Author: sales@whiz.bg
 */

/**
 * Returns user type from store
 * @param {*} store 
 * @returns 0 for demo and 1 for live user
 */
function getUserType(store) {
    return store.getters["getUserType"];
}

/**
 * 
 * @param {*} store vuex instance
 * @param {*} http axios instance
 * @param {*} quotesCodes list of <XC><SC> codes
 */
function runDelayedQuoteUpdate(store, http, quotesCodes) {
    if (!quotesCodes) {
        return;
    }
    const payload = {
        api: http,
        quotes: quotesCodes
    }
    setTimeout(() => {
        (async () => await store.dispatch("getQuotes", payload))();
        (async () => await store.dispatch("setSseQuotes", payload))();
    }, 50);
}

/**
 * Updates searched stocks in users watchlists
 * @param {*} store vuex instance
 * @param {*} http axios instance
 * @param {*} UiUtils custom ui_utils.js reference
 * @param {*} stocks List of stock objects
 * @param {*} concerned List of stocks to add to state
 */
function updateSearchedStocksListUserWatchlist(store, http, UiUtils, stocks, concerned) {
    const exchanges = store.getters["getExchanges"];
    let quotesCodes = [];
    stocks?.forEach(element => {
        const xCode = UiUtils.getExchangeCodeById(exchanges, element.cMIC);
        quotesCodes.push(UiUtils.combineExchangeStockPair(xCode, element.code));
    });
    runDelayedQuoteUpdate(store, http, quotesCodes);
    // update list
    if (0 < concerned.length) {
        store.commit("addSearchedStockUserWatchlist", {
            reset: true,
            stock: concerned[0]
        });
        for (let cnt = 1; cnt < concerned.length; cnt++) {
            setTimeout(() => {
                store.commit("addSearchedStockUserWatchlist", {
                    reset: false,
                    stock: concerned[cnt]
                });
            }, 50);
        }
    } else {
        store.commit("addSearchedStockUserWatchlist", {
            reset: true,
            stock: null
        });
    }
}

/**
 * Updates searched stocks in index watchlists
 * @param {*} store vuex instance
 * @param {*} http axios instance
 * @param {*} UiUtils custom ui_utils.js reference
 * @param {*} stocks List of stocks to request from API
 * @param {*} concerned List of stocks to add to state
 */
function updateSearchedStocksListIndexWatchlist(store, http, UiUtils, stocks, concerned) {
    const exchanges = store.getters["getExchanges"];
    let quotesCodes = [];
    stocks.forEach(element => {
        const xCode = UiUtils.getExchangeCodeById(exchanges, element.cMIC);
        quotesCodes.push(UiUtils.combineExchangeStockPair(xCode, element.code));
    });
    runDelayedQuoteUpdate(store, http, quotesCodes);
    // update list
    if (0 < concerned.length) {
        store.commit("addSearchedStockIndexWatchlist", {
            reset: true,
            stock: concerned[0]
        });
        for (let cnt = 1; cnt < concerned.length; cnt++) {
            setTimeout(() => {
                store.commit("addSearchedStockIndexWatchlist", {
                    reset: false,
                    stock: concerned[cnt]
                });
            }, 50);
        }
    } else {
        store.commit("addSearchedStockIndexWatchlist", {
            reset: true,
            stock: null
        });
    }
}

/**
 * Stores a new watchlist or updates existing one
 * @param {*} http axios instance
 * @param {*} UiUtils custom ui_utils.js reference
 * @param {*} store vuex instance
 * @param {*} xCode exchange code
 * @param {*} itemStockCode stock code
 * @param {*} isNew flag indicating new watchlist to be created
 * @param {*} watchlistName the watchlist name
 * @returns JSON
 */
async function saveWatchlist(http, UiUtils, store, xCode, itemStockCode, isNew, watchlistName) {
    const user = store.getters["getUser"];
    let wlMembers = [];

    if (isNew) {
        // check we have this name already
        const currentMembers = user.wls.filter(item => {
            return 0 === item.wln.toString().toUpperCase().localeCompare(watchlistName.toUpperCase());
        });
        if (currentMembers && 0 < currentMembers?.length) {
            return {
                error: true,
                type: 1 // occupied name
            };
        }

        // add watchlist
        wlMembers.push(UiUtils.combineExchangeStockPair(xCode, itemStockCode));
    } else {
        // existing
        const currentMembers = user.wls.filter(item => {
            return 0 === item.wln.toString().toUpperCase().localeCompare(watchlistName.toUpperCase());
        })[0];
        // check selected watchlist already has this stock code
        if (currentMembers) {
            currentMembers.sc.forEach(pair => {
                if (0 === pair.sc.toString().toUpperCase().localeCompare(itemStockCode.toUpperCase()) &&
                    0 === pair.xc.toString().toUpperCase().localeCompare(xCode.toUpperCase())) {
                    return {
                        error: true,
                        type: 2 // watchlist contains element
                    };
                }
            });
            // combine exchange and stock code
            currentMembers.sc.forEach(pair => {
                wlMembers.push(UiUtils.combineExchangeStockPair(pair.xc, pair.sc));
            });
            wlMembers.push(UiUtils.combineExchangeStockPair(xCode, itemStockCode));
            wlMembers = [...new Set(wlMembers)];
        }
    }
    // save watchlist
    try {
        let res = await http.getApiClient(getUserType(store)).get("", {
            params: {
                a: "save_watchlist",
                id: user.id,
                key: user.key,
                sc: wlMembers.join(),
                wln: watchlistName
            }
        });
        if (res) {
            // check answer
            if (0 === res.data.ec) {
                // update user watchlists
                res = await http.getApiClient(getUserType(store)).get("", {
                    params: {
                        a: "get_watchlist",
                        id: user.id,
                        key: user.key
                    }
                });
                if (0 === res.data.ec) {
                    store.commit("updateWatchlists", res.data.wls);
                    const updatedWatchlists = res.data.wls;
                    // get selected watchlist
                    const selectedWatchlist = updatedWatchlists.filter(wl => {
                        return 0 === wl.wln.toUpperCase().localeCompare(watchlistName.toUpperCase());
                    })[0];
                    // update stock list with this watchlist codes
                    const stocks = store.getters["getStocks"];
                    const exchanges = store.getters["getExchanges"];
                    const mapped = UiUtils.extractStocksInWatchlist(stocks, selectedWatchlist, exchanges);
                    // extract info for new searched stocks and call API for update
                    updateSearchedStocksListUserWatchlist(store, http, UiUtils, mapped, mapped);
                }
            } else {
                return {
                    error: true,
                    type: 3 // API error
                };
            }
        }
    } catch (e) {
        console.error(e);
        return {
            error: true,
            type: 4 // other error
        };
    }
    return {
        error: false
    }
}

/**
 * Updates the default watchlist with new item
 * @param {*} http axios instance
 * @param {*} UiUtils custom ui_utils.js reference
 * @param {*} store vuex instance
 * @param {*} xCode exchange code
 * @param {*} itemStockCode stock code
 * @returns JSON
 */
async function updateDefaultWatchlist(http, UiUtils, store, xCode, itemStockCode) {
    const user = store.getters["getUser"];
    let wlMembers = [];
    // default
    const currentMembers = user.wls.filter(item => {
        return 0 === item.wln.toString().toUpperCase().localeCompare("DEFAULT");
    })[0];
    // combine exchange and stock code
    let containsElement = false;
    const pairedElement = UiUtils.createExchangeStockCodePairObject(xCode, itemStockCode);
    currentMembers?.sc?.forEach(pair => {
        if (UiUtils.compareExchangeStockPairs(pairedElement, pair)) {
            containsElement = true;
        }
        wlMembers.push(UiUtils.combineExchangeStockPair(pair.xc, pair.sc));
    });
    if (containsElement) {
        return {
            error: true,
            type: 2 // watchlist contains element
        };
    }
    wlMembers.push(UiUtils.combineExchangeStockPair(xCode, itemStockCode));
    wlMembers = [...new Set(wlMembers)];
    // save watchlist
    try {
        let res = await http.getApiClient(getUserType(store)).get("", {
            params: {
                a: "save_watchlist",
                id: user.id,
                key: user.key,
                sc: wlMembers.join(),
                wln: "default"
            }
        });
        if (res) {
            // check answer
            if (0 === res.data.ec) {
                // update user watchlists
                res = await http.getApiClient(getUserType(store)).get("", {
                    params: {
                        a: "get_watchlist",
                        id: user.id,
                        key: user.key
                    }
                });
                if (0 === res.data.ec) {
                    store.commit("updateWatchlists", res.data.wls);
                    const updatedWatchlists = res.data.wls;
                    // get selected watchlist
                    const selectedWatchlist = updatedWatchlists.filter(wl => {
                        return 0 === wl.wln.toUpperCase().localeCompare("DEFAULT");
                    })[0];
                    // update stock list with this watchlist codes
                    const stocks = store.getters["getStocks"];
                    const exchanges = store.getters["getExchanges"];
                    const mapped = UiUtils.extractStocksInWatchlist(stocks, selectedWatchlist, exchanges);
                    //                    store.commit("setSearchedStocksUserWatchlist", mapped);
                    let quotesCodes = [];
                    mapped?.forEach(element => {
                        const xCode = UiUtils.getExchangeCodeById(exchanges, element.cMIC);
                        quotesCodes.push(UiUtils.combineExchangeStockPair(xCode, element.code));
                    });
                    store.getters["getSearchedStocksIndexWatchlist"]?.forEach(element => {
                        const xCode = UiUtils.getExchangeCodeById(exchanges, element.cMIC);
                        quotesCodes.push(UiUtils.combineExchangeStockPair(xCode, element.code));
                    });
                    runDelayedQuoteUpdate(store, http, quotesCodes);
                    // extract info for new searched stocks and call API for update
                    //                    updateSearchedStocksListUserWatchlist(store, http, UiUtils, mapped, mapped);
                    return {
                        error: false
                    }
                }
            } else {
                return {
                    error: true,
                    type: 3 // API error
                };
            }
        }
    } catch (e) {
        console.error(e);
        return {
            error: true,
            type: 4 // other error
        };
    }
}

/**
 * Removes a stock from all watchlists
 * @param {*} http axios instance
 * @param {*} UiUtils custom ui_utils.js reference
 * @param {*} store vuex instance
 * @param {*} xCode exchange code
 * @param {*} itemStockCode stock code
 * @returns JSON
 */
async function removeFromWatchlist(http, UiUtils, store, xCode, itemStockCode) {
    const user = store.getters["getUser"];
    let res;
    const itemToRemove = {
        sc: itemStockCode,
        xc: xCode
    };
    for (let cnt = 0; cnt < user.wls.length; cnt++) {
        const item = user.wls[cnt];
        let wlMembers = [];
        if (UiUtils.verifyPairListContainsElement(item.sc, itemToRemove)) {
            item.sc?.forEach(code => {
                if (!UiUtils.compareExchangeStockPairs(code, itemToRemove)) {
                    wlMembers.push(UiUtils.combineExchangeStockPairFromObject(code));
                }
            });
            wlMembers = [...new Set(wlMembers)];
            // save watchlist
            try {
                res = await http.getApiClient(getUserType(store)).get("", {
                    params: {
                        a: "save_watchlist",
                        id: user.id,
                        key: user.key,
                        sc: wlMembers.join(),
                        wln: item.wln
                    }
                });
            } catch (e) {
                console.error(e);
                return {
                    error: true,
                    type: 3 // API error
                };
            }
        }
    }
    // get watchlist
    try {
        res = await http.getApiClient(getUserType(store)).get("", {
            params: {
                a: "get_watchlist",
                id: user.id,
                key: user.key
            }
        });
        if (0 === res.data.ec) {
            store.commit("updateWatchlists", res.data.wls);
            // update stock codes to diplay
            let currentCodes = store.getters["getSearchedStocksUserWatchlist"];
            // get exchange ID
            const exchanges = store.getters["getExchanges"];
            const exchangeId = UiUtils.getExchangeIdByCode(exchanges, xCode);
            currentCodes = currentCodes.filter(item => {
                return 0 !== item.code.toString().toUpperCase().localeCompare(itemStockCode.toUpperCase()) ||
                    parseInt(item.cMIC) !== parseInt(exchangeId);
            });
            store.commit("setSearchedStocksUserWatchlist", currentCodes);
            //            updateSearchedStocksListUserWatchlist(store, http, UiUtils, currentCodes, currentCodes);
        }
    } catch (e) {
        console.error(e);
        return {
            error: true,
            type: 3 // API error
        };
    }

    return {
        error: false
    };
}

/**
 * Sets quote trading
 * @param {*} http 
 * @param {*} UiUtils 
 * @param {*} store 
 * @param {*} xCode 
 * @param {*} itemStockCode 
 * @returns 
 */
async function setQuoteTrading(http, UiUtils, store, xCode, itemStockCode) {
    if ("" === xCode || "" === itemStockCode) {
        return {
            error: false
        };
    }
    try {
        const user = store.getters["getUser"];
        // get quote tr
        let res = await http.getApiClient(getUserType(store)).get("", {
            params: {
                a: "get_quote_tr",
                id: user.id,
                key: user.key,
                sc: UiUtils.combineExchangeStockPair(xCode, itemStockCode)
            }
        });
        if (0 === res.data.ec) {
            store.commit("setQuoteTrading", res.data);
        }
        // set sse
        res = await http.getApiClient(getUserType(store)).get("", {
            params: {
                a: "set_sse",
                id: user.id,
                key: user.key,
                "quote-tr-set": UiUtils.combineExchangeStockPair(xCode, itemStockCode),
                "quote-tr-sts": "on"
            }
        });
    } catch (e) {
        console.error(e);
        return {
            error: true,
            type: 3 // API error
        };
    }
    return {
        error: false
    };
}
/**
 * This method verifies axios response from API for invalid session error
 * @param {*} resp 
 */
function isApiResponseInvalidSession(resp) {
    if (9208 === parseInt(resp?.data?.ec) || 9209 === parseInt(resp?.data?.ec)) {
        return true;
    }
    return false;
}

/**
 * Clean app state and redirect to login
 * @param {*} store vuex store handle
 * @param {*} router vue-router handle
 */
function cleanAndLogoutOnError(store, router, message, messageType) {
    // reset store to avoid users access to other user's data
    store.commit("resetStoreState", null);
    store.commit("setUser", null);
    router.push({ name: "Login", params: { message: message, messageType: messageType } });
}

export default {
    runDelayedQuoteUpdate: runDelayedQuoteUpdate,
    updateSearchedStocksListUserWatchlist: updateSearchedStocksListUserWatchlist,
    updateSearchedStocksListIndexWatchlist: updateSearchedStocksListIndexWatchlist,
    saveWatchlist: saveWatchlist,
    updateDefaultWatchlist: updateDefaultWatchlist,
    removeFromWatchlist: removeFromWatchlist,
    setQuoteTrading: setQuoteTrading,
    isApiResponseInvalidSession: isApiResponseInvalidSession,
    cleanAndLogoutOnError: cleanAndLogoutOnError
}