<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="row sm-gutters">
            <!-- Menu -->
            <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
                <Menu></Menu>
            </div>
            <div class="col-sm-12" :class="{ 'col-md-10': 1200 < getWindowsWidth }">
                <!-- header -->
                <div class="row">
                    <div class="col-sm-6">
                        <!-- back button -->
                        <button class="btn btn btn-outline-secondary btn-light mb-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="goBack()">&larr;</button>
                    </div>
                    <div class="col-sm-6">
                        <!-- view header -->
                        <h5 v-if="1200 < getWindowsWidth">{{ getTradesLabel }} - #{{ trade?.oid }}</h5>
                        <h6 v-else>{{ getTradesLabel }} - #{{ trade?.oid }}</h6>
                    </div>
                </div>
                <!-- trade details -->
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTimestampLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getDate(trade?.ts) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getOrderIdLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">#{{
                                    trade?.oid }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getExchangeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.xc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTypeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getOrderType(trade?.tt) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCodeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.sc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getNameLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BG'.localeCompare(getUserLanguage)">{{ trade?.nBG ? trade?.nBG :
                                    trade?.nEN }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EN'.localeCompare(getUserLanguage)">{{ trade?.nEN }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getQuantityLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.qty }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCurrencyLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.cc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPriceLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.sp) }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.sp) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getAmountLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.pt) }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.pt) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getFeeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber((trade?.fee || 0)) }} лв.
                                </td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber((trade?.fee || 0))
                                    }}</td>
                            </tr>
                            <tr v-if="0 === 'b'.localeCompare(trade?.tt)">
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTotalAmountLabel }}
                                </td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.pt + (trade?.fee
                                    || 0))
                                    }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.pt +
    (trade?.fee || 0)) }}</td>
                            </tr>
                            <tr v-else>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTotalAmountLabel }}
                                </td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.pt - (trade?.fee
                                    || 0))
                                    }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.pt -
    (trade?.fee || 0)) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '../components/Menu.vue';
import Translations from "@/lang/translations.js";
import UiUtils from "@/assets/js/ui_utils.js";

export default {
    components: {
        Menu
    },
    data() {
        return {
            trade: JSON.parse(this.tradeDetails)
        }
    },
    props: {
        tradeDetails: {
            required: true,
            type: String,
            default: null
        },
        latestDisplayStock: {
            required: false,
            type: String,
            default: null
        }          
    },
    methods: {
        getDate(unix) {
            return UiUtils.parseUnixTime(unix);
        },
        getOrderType(ot) {
            return 'b' === ot || 'B' === ot ?
                Translations.getBuyLabel(this.getUserLanguage) :
                Translations.getSellLabel(this.getUserLanguage);
        },
        parseNumber(num) {
            return UiUtils.formatNumber(num);
        },
        goBack() {
            // if latestDisplayStock is not null the this is details for exchanges breakdown and we need it to display appropriate list of trades
            this.$router.push({ name: "Wallet", params: { latestActiveMenuSelection: "Trades", latestDisplayStock: this.latestDisplayStock } });
        }
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },          
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getTradesLabel() {
            return Translations.getTradesLabel(this.getUserLanguage);
        },
        getTimestampLabel() {
            return Translations.getTimestampLabel(this.getUserLanguage);
        },
        getCurrencyLabel() {
            return Translations.getCurrencyLabel(this.getUserLanguage);
        },
        getQuantityLabel() {
            return Translations.getQuantityLabel(this.getUserLanguage);
        },
        getTotalAmountLabel() {
            return Translations.getTotalAmountLabel(this.getUserLanguage);
        },
        getAmountLabel() {
            return Translations.getAmountLabel(this.getUserLanguage);
        },
        getOrderIdLabel() {
            return Translations.getOrderLabel(this.getUserLanguage);
        },
        getTypeLabel() {
            return Translations.getTypeLabel(this.getUserLanguage);
        },
        getPriceLabel() {
            return Translations.getPriceLabel(this.getUserLanguage);
        }, 
        getFeeLabel() {
            return Translations.getFeeLabel(this.getUserLanguage);
        }, 
        getExchangeLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage);
        }, 
        getCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage);
        },
        getNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage);
        },
    },
    mounted() {
        window.onpopstate = function (event) {
            this.goBack();
        }.bind(this);
    }
}
</script>

<style scoped>
    .prop-value {
        text-align: right;
        font-weight: bold;
    }

.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
}       
</style>