<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="card border-dark">
            <div class="card-body">
                <!-- search box -->
                <div class="row">
                    <div class="col-md-12">
                        <div id="tradeStockName" class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-sm">
                                    <i class="icon ion-md-search"></i>
                                </span>
                            </div>
                            <input id="modalSearchBox" type="text" class="form-control"
                                :placeholder="getSearchPlaceholder" v-model="search" />
                        </div>
                    </div>
                </div>
                <!-- list view -->
                <div class="row">
                    <div class="col-md-12">
                        <ul id="stocks" class="settings-nav nav nav-pills nav-tabs">
                            <li v-for="stock in itemsToDisplay" :key="stock.cMIC + stock.code" class="nav-item">
                                <a href="#" class="nav-link d-flex justify-content-between align-items-center"
                                    type="button" @click="selectStock(stock)">
                                    <div class="d-flex">
                                        <div>
                                            <div class="row">
                                                <div class="col-sm-10 cold-lg-10">
                                                    <h5>{{ stock.code }}</h5>
                                                </div>
                                            </div>
                                            <p>
                                                <span v-if="0 === 'BG'.localeCompare(getUserLanguage)">{{ stock.nBG ?
                                                    stock.nBG : stock.nEN }}</span>
                                                <span v-if="0 === 'EN'.localeCompare(getUserLanguage)">{{ stock.nEN
                                                    }}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <h5 class="text-end">{{ getExchange(stock.cMIC) }}</h5>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Translations from "@/lang/translations.js";

export default {
    data() {
        return {
            /* the searching user typed in input */
            search: "",
            /* array of once searched stocks to increase search on type performance */
            alreadyFoundStocksOnSearch: [],
            /* array of items that match the searched name */
            itemsToDisplay: [],
        }
    },
    watch: {
        search: function (newVal, oldVal) {
            // check search
            if (oldVal != newVal) {
                // when typing new letters in input we update alreadyFoundStocksOnSearch and search in reduced list
                // when deleting from search input we should perform the search again
                // if search starts with / the we search stock codes only
                // if search starts with // we search name only
                // else we search everything
                let trimmedNewVal = newVal.trim();
                if (oldVal.trim().length > trimmedNewVal.length) {
                    this.alreadyFoundStocksOnSearch = [];
                }
                let stocks = 0 === this.alreadyFoundStocksOnSearch.length ? this.$store.getters["getStocks"] : this.alreadyFoundStocksOnSearch;
                // filter exchange code
                const selectedExchange = parseInt(this.$store.getters["getSelectedExchangeCode"]);
                if (0 !== selectedExchange) {
                    stocks = stocks.filter(item => selectedExchange == parseInt(item.cMIC));
                }
                // filter stocks
                if (newVal.startsWith("//")) {
                    // names
                    trimmedNewVal = trimmedNewVal.substring(2);
                    stocks = stocks.filter(item => {
                        if (item.nEN && item.nBG) {
                            return -1 < item.nEN.toLowerCase().indexOf(trimmedNewVal.toLowerCase()) ||
                                -1 < item.nBG.toLowerCase().indexOf(trimmedNewVal.toLowerCase());
                        } else if (item.nEN) {
                            return -1 < item.nEN.toLowerCase().indexOf(trimmedNewVal.toLowerCase());
                        }
                    });                      
                } else if (newVal.startsWith("/")) {
                    // codes
                    trimmedNewVal = trimmedNewVal.substring(1);
                    stocks = stocks.filter(item => {
                        if (item.code) {
                            return -1 < item.code.toLowerCase().indexOf(trimmedNewVal.toLowerCase());
                        } else {
                            return -1;
                        }
                    });
                } else {
                    stocks = stocks.filter(item => {
                        if (item.code && item.nEN && item.nBG) {
                            return -1 < item.code.toLowerCase().indexOf(trimmedNewVal.toLowerCase()) ||
                                -1 < item.nEN.toLowerCase().indexOf(trimmedNewVal.toLowerCase()) ||
                                -1 < item.nBG.toLowerCase().indexOf(trimmedNewVal.toLowerCase());
                        } else if (item.code && item.nEN) {
                            return -1 < item.code.toLowerCase().indexOf(trimmedNewVal.toLowerCase()) ||
                                -1 < item.nEN.toLowerCase().indexOf(trimmedNewVal.toLowerCase());
                        }
                    });
                }
                // save search
                this.alreadyFoundStocksOnSearch = stocks;
                let itemsToAdd = [];
                if (3 <= trimmedNewVal.length) {
                    stocks.forEach(element => {
                        if (!this.checkHasThisStock(itemsToAdd, element.code, element.cMIC)) {
                            itemsToAdd.push(element);
                        }
                    });
                } else {
                    itemsToAdd = stocks.slice(0, 10);
                }
                this.itemsToDisplay = itemsToAdd;
            }
        }        
    },
    methods: {
        checkHasThisStock(stocks, stockCode, exchangeId) {
            const items = stocks.filter(item => {
                return 0 === item.code.toString().toLowerCase().localeCompare(stockCode.toString().toLowerCase()) &&
                    parseInt(exchangeId) === parseInt(item.cMIC);
            });
            return (items && 0 < items?.length);
        },
        getExchange(cMIC) {
            if (!cMIC) {
                return "N/A";
            }
            try {
                const exchanges = this.$store.getters["getExchanges"];
                // find exchange 
                const exchange = exchanges.filter(item => {
                    return parseInt(item.eID) === parseInt(cMIC);
                })[0];
                return exchange.code;
            } catch (error) {
                console.error(error);
            }
            return "N/A";
        },
        selectStock(stock) {
            const stockName = 0 === 'BG'.localeCompare(this.getUserLanguage) ?
                stock.nBG ? stock.nBG : stock.nEN :
                stock.nEN;
            this.$store.commit("setSelectedTradingCode", stock.code);
            this.$store.commit("setSelectedTradingCodeSegment", stock.sgm);
            this.$store.commit("setSelectedTradingCodeShareTypeId", parseInt(stock.tID));            
            this.$store.commit("setSelectedTradingExchangeId", parseInt(stock.cMIC));
            this.$store.commit("setSelectedTradingStockName", stockName);
            // this is either market or limit order
            this.$store.commit("setOrderIdToUpdate", "");
            this.search = stockName;
        }
    },
    computed: {
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getSearchPlaceholder() {
            return Translations.getSearchPlaceholder(this.getUserLanguage);
        },
        getSearchModalLabel() {
            return Translations.getSearchModalLabel(this.getUserLanguage);
        },
    }
}
</script>

<style scoped>
#stocks {
    max-height: 20rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
}
</style>