<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="row sm-gutters">
            <!-- Menu -->
            <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
                <Menu></Menu>
            </div>
            <div class="col-sm-12" :class="{ 'col-md-10': 1200 < getWindowsWidth }">
                <!-- header -->
                <div class="row">
                    <div class="col-sm-6">
                        <!-- back button -->
                        <button class="btn btn btn-outline-secondary btn-light mb-2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="goBack()">&larr;</button>
                    </div>
                    <div class="col-sm-6">
                        <!-- view header -->
                        <h5 v-if="1200 < getWindowsWidth">{{ getTransactionsLabel }} - #{{ transaction?.id }}</h5>
                        <h6 v-else>{{ getTransactionsLabel }} - #{{ formatTransactionId(transaction?.id) }}</h6>
                    </div>
                </div>
                <!-- transaction details -->
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTimestampLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getDate(transaction?.ts) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTypeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    capitalizeFirstLetter(transaction.tt) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCurrencyLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ transaction?.cc
                                }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getAmountLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                    {{ formatPrice(transaction.am, transaction.cc).replace(' ', '&nbsp;') }}
                                </td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }" colspan="2">
                                    {{ getNoteLabel }}<br />
                                    <strong>{{ transaction?.note }}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '../components/Menu.vue';
import Translations from "@/lang/translations.js";
import UiUtils from "@/assets/js/ui_utils.js";

export default {
    components: {
        Menu
    },
    data() {
        return {
            transaction: JSON.parse(this.transactionDetails)
        }
    },
    props: {
        transactionDetails: {
            required: true,
            type: String,
            default: null
        },
        latestDisplayStock: {
            required: false,
            type: String,
            default: null
        }
    },
    methods: {
        getDate(unix) {
            return UiUtils.parseUnixTime(unix);
        },
        getOrderType(ot) {
            return 'b' === ot || 'B' === ot ?
                Translations.getBuyLabel(this.getUserLanguage) :
                Translations.getSellLabel(this.getUserLanguage);
        },
        parseNumber(num) {
            return UiUtils.formatNumber(num);
        },
        goBack() {
            // if latestDisplayStock is not null the this is details for exchanges breakdown and we need it to display appropriate list of transactions
            this.$router.push({ name: "Wallet", params: { latestActiveMenuSelection: "Transactions", latestDisplayStock: this.latestDisplayStock } });
        },
        capitalizeFirstLetter(string) {
            return string
                ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
                : "";
        },
        formatPrice(val, curr) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage);
        },
        formatTransactionId(id) {
            if (16 > id.length) {
                return id;
            }
            return id.slice(16) + "..."
        }
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getTransactionsLabel() {
            return Translations.getTransactionsLabel(this.getUserLanguage);
        },
        getTimestampLabel() {
            return Translations.getTimestampLabel(this.getUserLanguage);
        },
        getCurrencyLabel() {
            return Translations.getCurrencyLabel(this.getUserLanguage);
        },
        getAmountLabel() {
            return Translations.getAmountLabel(this.getUserLanguage);
        },
        getTypeLabel() {
            return Translations.getTypeLabel(this.getUserLanguage);
        },
        getNoteLabel() {
            return Translations.getNoteLabel(this.getUserLanguage);
        },
    },
    mounted() {
        window.onpopstate = function (event) {
            this.goBack();
        }.bind(this);
    }
}
</script>

<style scoped>.prop-value {
    text-align: right;
    font-weight: bold;
}

.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
}</style>