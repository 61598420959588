/* eslint-disable */
<template>
  <div v-if="!$store.getters.isAppReady" class="hollow-dots-spinner center">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
  <Header v-if="$store.getters.isUserLogged" :class="{ blurBody: !$store.getters.isAppReady }" />
  <div id="languageSelector" v-if="!$store.getters.isUserLogged" class="position-fixed">
    <img v-if="currentLanguage === 'EN'" width="30" src="./assets/images/Bulgarian.svg" class="lang_flag" alt="language" @click.prevent="updateUserLanguage();" />
    <img v-else width="30" src="./assets/images/English.svg" class="lang_flag" alt="language" @click.prevent="updateUserLanguage();"/>    
  </div>  
  <router-view :class="{ blurBody: !$store.getters.isAppReady }" />
</template>

<script>
import Header from "../src/components/Header.vue";

export default {
  components: { 
    Header
  },
  data() {
    return {
      /* responsive monitoring */
      widowWidth: window.innerWidth,
      currentLanguage: this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN"
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.widowWidth = window.innerWidth;
      this.$store.commit("setAppWindowWidth", window.innerWidth);
    },
    updateUserLanguage() {
      if (0 === "EN".localeCompare(this.currentLanguage)) {
        this.currentLanguage = "BG";
      } else {
        this.currentLanguage = "EN";
      }
      this.$store.commit('setNonLoggedInLanguage', this.currentLanguage);
    }    
  }
};
</script>

<style scoped>

.logo-container {
  display: flex;
  align-items: flex-end;
}

#ef-logo {
  max-height: 2rem;
  width: auto;
}

#whiz-logo {
  max-height: 2rem;
  width: auto;
}

#logo {
  position: fixed;
  bottom: 0.5rem;
  right: 2rem;
  height: 1rem;
  max-height: 1rem;
}

#powered-by {
  position: fixed;
  bottom: 0.5rem;
  left: 2rem;
  height: 1rem;
  max-height: 1rem;
}

#product_of {
  text-align: right;
  font-size: 1rem;
  margin-bottom: 0.15rem;
}

.span_wrap {
  white-space: nowrap;
  word-wrap: hyphenate;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 1rem;
  min-height: 1rem;
  max-height: 1rem;
  padding: 0.75rem 0.5rem;
}

.center {
  position: fixed;
  /* or absolute */
  top: calc(50% - 7px);
  left: calc(50% - 45px);
}
.blurBody {
  opacity: 0.2;
  pointer-events: none;
}
.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}

.hollow-dots-spinner {
  height: 15px;
  width: calc(30px * 3);
}

.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 calc(15px / 2);
  border: calc(15px / 5) solid #0D6EFD;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: calc(300ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: calc(300ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(300ms * 3);

}

.st0 {
  fill: #ED1C24;
}

#languageSelector {
  position: fixed;
  top:2%;
  left: 2%;
}

@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>