/* eslint-disable */
<template>
    <div class="container-fluid no-fluid">
        <!-- Modal search stock -->
        <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addToWatchlistLabel" aria-hidden="true"
            :class="{ 'show': searchStockModalShow, 'd-block': searchStockModalActive }">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="addToWatchlistLabel">{{
                            getSearchModalLabel }}</h5>
                    </div>
                    <div class="modal-body">
                        <StockSearch />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            @click="searchStockModalActive = false; searchStockModalShow = false;">{{
                            getCloseLabel
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="searchStockModalActive" class="modal-backdrop fade show">
        </div>
        <!-- Modal placed order -->
        <div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
            :class="{ 'show': orderPlacedModalShow, 'd-block': orderPlacedModalActive }">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <OrderPlaced :order="orderModel" />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                            @click="orderPlacedModalActive = false; orderPlacedModalShow = false;">{{
                            getCloseLabel
                            }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="orderPlacedModalActive" class="modal-backdrop fade show">
        </div>
        <div class="row sm-gutters align-trade-buttons">
            <div class="col-sm-12 col-md-12">
                <div class="row">
                    <div class="btn-group d-flex mtb15" role="group">
                        <input type="radio" class="btn-check" id="otsell" value="S" v-model="orderAction"
                            autocomplete="off">
                        <label class="btn" :class="{ 'btn-sell': 'S' === orderAction }" for="otsell">{{
                            getSellLabel.toUpperCase() }}</label>
                        <input type="radio" class="btn-check" id="otbuy" autocomplete="off" value="B"
                            v-model="orderAction">
                        <label class="btn" :class="{'btn-buy' : 'B' === orderAction }" for="otbuy">{{
                            getBuyLabel.toUpperCase() }}</label>
                    </div>
                </div>
                <div class="row mt5">
                    <div class="col-md-12">
                        <label htmlFor="selectMarketOption">{{ getTypeLabel }}</label>
                        <select id="selectMarketOption" class="form-select" v-model="orderType">
                            <option value="put_market_order_ex">{{ getMarketOption }}</option>
                            <option value="put_limit_order_ex">{{ getLimitOption }}</option>
                        </select>
                    </div>
                </div>
                <div class="row mt15">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-sm-4">
                                <label htmlFor="tradeStockCode">{{ getCodeLabel }}</label>
                                <input id="tradeStockCode" type="text" class="form-control"
                                    v-model="getSelectedItemCode" disabled />
                            </div>
                            <div class="col-sm-4">
                                <label htmlFor="tradeStockExchangeCode">{{ getExchangeLabel }}</label>
                                <input id="tradeStockExchangeCode" type="text" class="form-control"
                                    v-model="getSelectedItemExchangeCode" disabled />
                            </div>
                            <div class="col-sm-4">
                                <label htmlFor="tradeStockSegmentCode">{{ getSegmentLabel }}</label>
                                <input id="tradeStockSegmentCode" type="text" class="form-control"
                                    v-model="getSelectedItemSegment" disabled />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt15">
                        <label htmlFor="tradeStockName">{{ getNameLabel }}</label>
                        <div id="tradeStockName" class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroup-sizing-sm">
                                    <i class="icon ion-md-search"></i>
                                </span>
                            </div>
                            <input type="text" class="form-control" :placeholder="getCodePlaceholder"
                                @focus="showSearchModal" v-model="getStockName" />
                        </div>
                    </div>
                </div>
                <div class="row mt15">
                    <div class="col-md-12">
                        {{ getDynamicRangeLabel }} {{ getSelectedTradingCodeShareTypeId }}
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm">
                            <thead>
                                <tr class="text-center">
                                    <th scope="col" v-if="2 !== getSelectedTradingCodeShareTypeId">{{
                                        getMinPriceLabel }}</th>
                                    <th scope="col" v-if="2 !== getSelectedTradingCodeShareTypeId">{{
                                        getMaxPriceLabel }}</th>
                                    <th scope="col">{{ getTickSizeLabel }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-center">
                                    <td v-if="2 !== getSelectedTradingCodeShareTypeId">{{
                                        formatPrice(getQuoteTrading.minp || 0, getStockCurrency()).replace(' ', '&nbsp;') }}
                                    </td>
                                    <td v-if="2 !== getSelectedTradingCodeShareTypeId">{{
                                        formatPrice(getQuoteTrading.maxp || 0, getStockCurrency()).replace(' ', '&nbsp;') }}
                                    </td>
                                    <td>{{ parseNumber(calculateTickSize,
                                        calculateNumberOfMeaningfulDigits(calculateTickSize)) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row mt15 text-center">
                    <div class="col-md-2">
                        <button class="btn btn-primary" @click="decreaseOrderStockCount"
                            :disabled="0 === getStockName?.length">-</button>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-12">
                                <input style="text-align: center;" id="tradeStockcount" type="text" inputmode="numeric"
                                    class="form-control" v-model.number="orderStockCount"
                                    @keypress="onlyForCurrencyOrderStockCount"
                                    :disabled="(0 === getAvailableStocks && 'S' === orderAction) || 0 === getStockName?.length"
                                    :placeholder="2 !== getSelectedTradingCodeShareTypeId ? getNumberOfSharesLabel : getFaceValueLabel" />
                            </div>
                        </div>
                        <div class="row text-center">
                            <div class="col-md-12">
                                {{ getYouHaveLabel }} {{ getAvailableStocks }}
                            </div>
                        </div>
                        <div v-if="2 === getSelectedTradingCodeShareTypeId" class="row text-center">
                            <div class="col-md-12 mt5">
                                %&nbsp;{{ calculatePriceAsPercent }}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button class="btn btn-primary" @click="increaseOrderStockCount"
                            :disabled="(0 === getAvailableStocks && 'S' === orderAction) || 0 === getStockName?.length">+</button>
                    </div>
                </div>
                <div v-if="'put_market_order_ex' === orderType" class="row mt15">
                    <div class="col-md-12">
                        {{ getApproximateAmountLabel }}: {{ formatPrice(getEstimatedTotalAsMoney(),
                        getStockCurrency(false), 2).replace(' ', '&nbsp;') }}
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <label v-if="2 !== getSelectedTradingCodeShareTypeId" htmlFor="limitOrderPrice">{{ getPriceLabel }}</label>
                                <label v-else htmlFor="limitOrderPrice">{{ getPriceLabel }} (%)</label>
                                <input id="limitOrderPrice" type="number" min="0.00" class="form-control" step="0.01"
                                    v-model="limitOrderPrice" @keypress="onlyForCurrencyLimitedOrderPrice"
                                    @focusin="limitPriceFocusGained()" @focusout="limitPriceFocusLost()" />
                            </div>
                        </div>
                        <div class="col-md-12"> {{
                            getApproximateAmountLabel }}: {{ formatPrice(getEstimatedTotalAsMoney(),
                            getStockCurrency(false), 2).replace(' ', ' &nbsp;') }} </div>
                    </div>
                </div>
                <!-- Modal date select -->
                <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="datePickerModalLabel"
                    aria-hidden="true" :class="{ 'show': dateSelectionModalShow, 'd-block': dateSelectionModalActive }">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="datePickerModalLabel">{{ getChoseExpiryDateLabel }}</h5>
                            </div>
                            <div class="modal-body">
                                <Datepicker v-model="orderSelectedDate" format="yyyy-MM-dd" placeholder="yyyy-mm-dd"
                                    :minDate="new Date()" showNowButton>
                                </Datepicker>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal"
                                    @click="dateSelectionModalActive = false; dateSelectionModalShow = false;">{{
                                    getCloseLabel
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="dateSelectionModalActive" class="modal-backdrop fade show"></div>
                <div class="row mt15">
                    <div class="col-md-12">
                        <label htmlFor="selectValidity">{{ getChooseValidityLabel }}</label>
                        <select id="selectValidity" class="form-select" v-model="orderValidity"
                            :disabled="0 === getStockName?.length">
                            <option value="1">{{ getDailyLabel }}</option>
                            <option value="2">{{ getChoseDateLabel }}</option>
                            <option value="3">{{ getGoodTillCancelledLabel }}</option>
                            <!--<option value="4">IOC (Immediate-or-Cancel)</option>
                            <option value="5">FOK (Fill-or-Kill)</option>
                            <option value="6">BOC (Book-or-Cancel)</option>-->
                        </select>
                    </div>
                </div>
                <div class="row mt15">
                    <div class="col-md-12">
                        <label htmlFor="selectRestriction">{{ getRestrictionsLabel }}</label>
                        <select id="selectRestriction" class="form-select" v-model="orderRestriction"
                            :disabled="0 === getStockName?.length">
                            <option value="">{{ getNoRestrictionsLabel }}</option>
                            <option value="OA">OA (Opening Auction)</option>
                            <option value="AU">AU (Auction-only)</option>
                            <option value="CA">CA (Closing Auction)</option>
                            <option value="EA">EA (End-of-day Auction-only)</option>
                            <option value="MT">MT (Main Trading Phase)</option>
                            <option value="MA">MA (Main Trading Auctions)</option>
                        </select>
                    </div>
                </div>
                <div class="row"
                    :class="{ 'align-trade-button-market': 'put_market_order_ex' === orderType, 'align-trade-button-limit': 'put_market_order_ex' !== orderType }">
                    <div class="col">
                        <input type="submit" class="btn btn-primary w-100" :value="getSubmitLabel"
                            :disabled="canSubmitOrder" @click.prevent="submitOrder" />
                    </div>
                    <div class="col">
                        <input type="button" class="btn btn-outline-primary w-100" :value="getClearLabel"
                            @click.prevent="clearTradeForm" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/axios/http-common";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import UiUtils from "@/assets/js/ui_utils.js";
import ApiUtils from "@/assets/js/api_utils.js";
import Translations from "@/lang/translations.js";
import StockSearch from "./StockSearch.vue";
import OrderPlaced from "@/components/OrderPlaced.vue";

// exchange liquidity price levels
const sdLiqPriceLevels = [0, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000];
// exchange tick sizes for limit prices
const sdMaxTickSizes = [500, 500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5];

export default {
    components: {
        Datepicker,
        StockSearch,
        OrderPlaced
    },
    props: {
        stockCode: {
            required: false,
            type: String,
            default: null
        },
        requestedQuantity: {
            required: false,
            type: Number,
            default: 0
        },
        requestedPrice: {
            required: false,
            type: Number,
            default: 0
        },
        requestedAction: {
            required: false,
            type: String,
            default: null
        }
    },
    data() {
        return {
            /* operation type, B(uy) or S(ell) */
            orderAction: this.requestedAction 
                ? this.requestedAction 
                : "B", 
            /* market or limit order as request action */
            orderType: 2 === parseInt(this.$store.getters["getSelectedTradingCodeShareTypeId"]) 
                ? "put_limit_order_ex" 
                : 0 !== this.requestedPrice
                    ? "put_limit_order_ex" 
                    : "put_market_order_ex", 
            /* required stocks count */
            orderStockCount: this.requestedQuantity 
                ? this.requestedQuantity 
                : null,
            /* expected minimum price */
            orderMinExpectedPrice: 0,
            /* expected maximum price */ 
            orderMaxExpectedPrice: 0,
            /* order validity code */ 
            orderValidity: 1, 
            /* order market restriction */
            orderRestriction: "",
            /* user required price for limit orders */
            limitOrderPrice: 0 !== this.requestedPrice 
                ? this.requestedPrice 
                : 0, 
            /* user selected order expiry date */
            orderSelectedDate: null,
            /* active modal dialog for date selection */
            dateSelectionModalActive: false, 
            /* show modal dialog for date selection */
            dateSelectionModalShow: false,
            /* active modal dialog for stock search */
            searchStockModalActive: false,
            /* show modal dialog for stock search */
            searchStockModalShow: false,  
            orderPlacedModalActive: false,
            orderPlacedModalShow: false,
            orderModel: {},
        }
    },
    watch: {
        orderValidity: function (newVal, oldVal) {
            if (parseInt(newVal) !== parseInt(oldVal) && 2 === parseInt(newVal)) {
                // open modal
                const body = document.querySelector("body");
                this.dateSelectionModalActive = true;
                this.dateSelectionModalShow = true;
                body.classList.add("modal-open");
            }
        },
        getSelectedTradingAction: function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.orderAction = newVal;
            }
        },
        getSelectedItemCode: function (newVal, oldVal) {
            if (oldVal != newVal) {
                // get exchange code
                const exchanges = this.$store.getters["getExchanges"];
                const itemExchangeCode = UiUtils.getExchangeCodeById(exchanges, this.$store.getters["getSelectedTradingExchangeId"]);                
                (async () => await ApiUtils.setQuoteTrading(http, UiUtils, this.$store, itemExchangeCode, newVal))();
                (async () => await this.getDepth())();
                // if modal search is open, close it
                this.searchStockModalActive = false; 
                this.searchStockModalShow = false;
            }
        },
        orderStockCount: function (newVal, oldVal) {
            if (parseInt(newVal) !== parseInt(oldVal)) {
                if (this.getQuoteTrading.minp) {
                    this.orderMinExpectedPrice = (this.getQuoteTrading?.minp * newVal);
                    this.orderMaxExpectedPrice = (this.getQuoteTrading?.maxp * newVal);
                }                
            }
        },
        getOrderToEdit: function (newVal) {
            if (newVal) {                
                this.orderAction = newVal.action;
                this.orderType = 0 === newVal.type.toUpperCase().localeCompare("MARKET")
                    ? "put_market_order_ex"
                    : "put_limit_order_ex";
                this.$store.commit("setSelectedTradingExchangeId",
                    UiUtils.getExchangeIdByCode(this.$store.getters["getExchanges"], newVal.xcode));
                this.$store.commit("setSelectedTradingCode", newVal.code);
                this.$store.commit("setSelectedTradingCodeSegment", newVal.sgm);
                this.$store.commit("setSelectedTradingCodeShareTypeId", parseInt(newVal.tID));
                this.$store.commit("setOrderIdToUpdate", newVal.order_id);
                this.$store.commit("setSelectedTradingStockName",
                    0 === 'BG'.localeCompare(this.getUserLanguage())
                        ? newVal.nBG
                            ? newVal.nBG
                            : newVal.nEN
                        : newVal.nEN);
                this.orderStockCount = newVal.amount;
                this.limitOrderPrice = 0 === newVal.type.toUpperCase().localeCompare("MARKET")
                    ? 0
                    : newVal.price;
            }
        }
    },
    methods: {
        formatPrice(val, curr, autoDigits) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage(), autoDigits);
        },
        calculateNumberOfMeaningfulDigits(val) {
            return UiUtils.calculateNumberOfMeaningfulDigits(val);
        },
        getUserType() {
            return this.$store.getters["getUserType"];
        },
        increaseOrderStockCount() {
            if (2 != this.getSelectedTradingCodeShareTypeId) {
                this.orderStockCount++;
            } else {
                // increase by nominal
                if (0 !== 'xbul'.localeCompare(this.getSelectedItemExchangeCode.toLowerCase())) {
                    this.orderStockCount += 100;
                } else {
                    // get stock
                    const allStocks = this.$store.getters["getStocks"];
                    const stock = allStocks.filter(item => {
                        return 0 === item.code.toString().toLowerCase().localeCompare(this.getSelectedItemCode.toLowerCase())
                            && item.cMIC === this.$store.getters["getSelectedTradingExchangeId"];
                    })[0];
                    console.log(JSON.stringify(stock))
                    
                    this.orderStockCount += parseFloat(stock?.nominal || 1);
                }
            }
        },
        decreaseOrderStockCount() {         
            if (0 < this.orderStockCount) {
                if (2 != this.getSelectedTradingCodeShareTypeId) {
                    this.orderStockCount--;
                } else {
                    // increase by nominal
                    if (0 !== 'xbul'.localeCompare(this.getSelectedItemExchangeCode.toLowerCase())) {
                        this.orderStockCount -= 100;
                    } else {
                        // get stock
                        const allStocks = this.$store.getters["getStocks"];
                        const stock = allStocks.filter(item => {
                            return 0 === item.code.toString().toLowerCase().localeCompare(this.getSelectedItemCode.toLowerCase())
                                && item.cMIC === this.$store.getters["getSelectedTradingExchangeId"];
                        })[0];
                        console.log(JSON.stringify(stock))
                        this.orderStockCount -= parseFloat(stock?.nominal || 1);
                    }
                }   
            }
        },
        getExpirationDateByOrderValidity() {
            const nowDate = new Date();
            const prevDate = new Date(nowDate.getTime());            
            switch (parseInt(this.orderValidity)) {
                case 1:
                case 4:
                case 5:
                case 6: {
                    return nowDate.toISOString().slice(0, 10);
                }
                case 2: {
                    return new Date(this.orderSelectedDate).toISOString().slice(0, 10);
                }
                case 3: {
                    prevDate.setDate(nowDate.getDate() + 360);
                    return prevDate.toISOString().slice(0, 10);
                }
            }

            return nowDate.toISOString().slice(0, 10);
        },
        getDate(unix) {
            return UiUtils.parseUnixTime(unix);
        },
        getEstimatedTotalAsMoney(fee = 0) {
            // estimated cost should be calculated by the following algo:
            // 1. if we are buying on market price, the fee is added to the multiplication of asked price and stocks
            // 2. if we are selling on market, the fee is substracted from the multiplication of bid price and stocks
            // 3. if we are buying on required price, the fee is added to the multiplication of required price and stocks
            // 4. if we are selling on required price, the fee is substracted from the multiplication of required price and stocks
            // 5. bonds are in percent, so we should take that into consideration also
            // 5.1 bonds for XFRA are calculated as face_value * price in %
            // 5.2 bonds for XBUL are calculated as face_value * price in % 
            let estimatedTotal = 0;
            if (this.orderType.toUpperCase().includes('MARKET')) {
                // market order
                if (2 !== this.getSelectedTradingCodeShareTypeId) {
                    // not a bond
                    estimatedTotal = 0 === this.orderAction.toUpperCase().localeCompare('B') 
                        ? parseFloat(this.getQuoteTrading.a || this.getQuoteTrading.ltp) * this.orderStockCount + parseFloat(fee)
                        : parseFloat(this.getQuoteTrading.b || this.getQuoteTrading.ltp) * this.orderStockCount - parseFloat(fee);
                } else {
                    // bond
                    estimatedTotal = 0 === this.orderAction.toUpperCase().localeCompare('B') 
                        ? parseFloat(this.getQuoteTrading.a || this.getQuoteTrading.ltp) * this.orderStockCount / 100 + parseFloat(fee)
                        : parseFloat(this.getQuoteTrading.b || this.getQuoteTrading.ltp) * this.orderStockCount / 100 - parseFloat(fee);
                }
            } else {
                // limit order
                if (2 !== this.getSelectedTradingCodeShareTypeId) {
                    // not a bond
                    estimatedTotal = 0 === this.orderAction.toUpperCase().localeCompare('B') 
                        ? parseFloat(this.limitOrderPrice || 0) * this.orderStockCount + parseFloat(fee)
                        : parseFloat(this.limitOrderPrice || 0) * this.orderStockCount - parseFloat(fee);
                } else {
                    // bond
                    estimatedTotal = 0 === this.orderAction.toUpperCase().localeCompare('B') 
                        ? parseFloat(this.limitOrderPrice || 0) * this.orderStockCount / 100 + parseFloat(fee)
                        : parseFloat(this.limitOrderPrice || 0) * this.orderStockCount / 100 - parseFloat(fee);
                }
            }

            return estimatedTotal;
        },
        formOrderObject(orderId, fee, time) {
            this.orderModel.action = 0 === this.orderAction.toUpperCase().localeCompare('B')
                ? this.getBuyLabel
                : this.getSellLabel;
            this.orderModel.type = -1 !== this.orderType.toUpperCase().includes('MARKET')
                ? this.getMarketOption
                : this.getLimitOption;
            this.orderModel.ex = this.getSelectedItemExchangeCode;
            this.orderModel.code = this.getSelectedItemCode;
            this.orderModel.name = this.getStockName;
            this.orderModel.amount = this.orderStockCount;
            this.orderModel.validity = this.orderValidity;
            this.orderModel.restriction = this.orderRestriction;
            this.orderModel.expiry_date = this.getExpirationDateByOrderValidity();
            this.orderModel.oid = orderId;
            this.orderModel.fee = this.parseNumber(fee);
            this.orderModel.time = this.getDate(time);
            this.orderModel.price = this.getEstimatedTotalAsMoney(fee);
        },
        async submitOrder() {
            const user = this.$store.getters["getUser"];
            try {
                this.$store.commit("setAppStateNotReady");
                let res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "" !== this.$store.getters["getOrderIdToUpdate"] ? "update_order" : this.orderType,
                        id: user.id,
                        key: user.key,
                        action: this.orderAction,
                        code: UiUtils.combineExchangeStockPair(this.getSelectedItemExchangeCode, this.getSelectedItemCode),
                        amount: 2 !== this.getSelectedTradingCodeShareTypeId 
                            ? this.orderStockCount
                            : 0 !== 'xbul'.localeCompare(this.getSelectedItemExchangeCode.toLowerCase())
                                ? this.orderStockCount / 100
                                : this.orderStockCount,
                        validity: this.orderValidity,
                        restr_code: "" === this.orderRestriction ? null : this.orderRestriction,
                        price:  0 < this.limitOrderPrice ? this.limitOrderPrice  : null,
                        exp_date: this.getExpirationDateByOrderValidity(),
                        oid: "" !== this.$store.getters["getOrderIdToUpdate"] ? this.$store.getters["getOrderIdToUpdate"] : null
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        // try silent relogin and repeat order posting for live users only
                        if (this.$store.getters["getReloginUsernameLive"] && this.$store.getters["getReloginPasswordLive"]) {
                            // stop the SSE listener to avoid session closed message
                            this.$store.commit("setStopSse", true);
                            const payload = {
                                api: http,
                                username: this.$store.getters["getReloginUsernameLive"],
                                password: this.$store.getters["getReloginPasswordLive"],
                                demo: false
                            };                            
                            if (0 === await this.$store.dispatch("loginUser", payload)) {
                                // repeat posting the order
                                res = await http.getApiClient(this.getUserType()).get("", {
                                    params: {
                                        a: "" !== this.$store.getters["getOrderIdToUpdate"] ? "update_order" : this.orderType,
                                        id: user.id,
                                        key: user.key,
                                        action: this.orderAction,
                                        code: UiUtils.combineExchangeStockPair(this.getSelectedItemExchangeCode, this.getSelectedItemCode),
                                        amount: 2 !== this.getSelectedTradingCodeShareTypeId 
                                            ? this.orderStockCount
                                            : 0 !== 'xbul'.localeCompare(this.getSelectedItemExchangeCode.toLowerCase())
                                                ? this.orderStockCount / 100
                                                : this.orderStockCount,
                                        validity: this.orderValidity,
                                        restr_code: "" === this.orderRestriction ? null : this.orderRestriction,
                                        price:  0 < this.limitOrderPrice ? this.limitOrderPrice  : null,
                                        exp_date: this.getExpirationDateByOrderValidity(),
                                        oid: "" !== this.$store.getters["getOrderIdToUpdate"] ? this.$store.getters["getOrderIdToUpdate"] : null
                                    }
                                });
                                if (res && 0 === res.data.ec) {
                                    // show modal
                                    this.formOrderObject(res.data.order_id, res.data.fee, res.data.ts);
                                    this.orderPlacedModalActive = true;
                                    this.orderPlacedModalShow = true;                        
                                    this.$store.commit("setAppStateReady");
                                    this.$toast.success(this.getOrderSentMessageLabel, {
                                        "duration": 5000,
                                        "pauseOnHover": true
                                    });
                                    this.$store.commit("addSystemMessage", {
                                        date: (new Date()).getTime(),
                                        mess: this.getOrderSentMessageLabel,
                                        error: false
                                    });
                                    // remove previous order so user could not click multiple times and send the same order
                                    this.orderStockCount = 0;
                                    this.orderAction = "B";
                                    this.orderMinExpectedPrice = 0;
                                    this.orderMaxExpectedPrice = 0;
                                    this.orderRestriction = "";
                                    this.orderValidity = 1;
                                } else {
                                    this.$store.commit("setAppStateReady");
                                    this.$toast.error(this.getOrderNotSentMessageLabel + res.data.ec, {
                                        "duration": 5000,
                                        "pauseOnHover": true
                                    });
                                    this.$store.commit("addSystemMessage", {
                                        date: (new Date()).getTime(),
                                        mess: this.getOrderNotSentMessageLabel + res.data.ec,
                                        error: true
                                    });
                                }
                                return;
                            }
                        }

                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(
                            ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1), 
                            500);
                        return;
                    }                    
                    if (0 === res.data.ec) {
                        // show modal
                        this.formOrderObject(res.data.order_id, res.data.fee, res.data.ts);
                        this.orderPlacedModalActive = true;
                        this.orderPlacedModalShow = true;                        
                        this.$store.commit("setAppStateReady");
                        this.$toast.success(this.getOrderSentMessageLabel, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getOrderSentMessageLabel,
                            error: false
                        });
                        // remove previous order so user could not click multiple times and send the same order
                        this.orderStockCount = 0;
                        this.orderAction = "B";
                        this.orderMinExpectedPrice = 0;
                        this.orderMaxExpectedPrice = 0;
                        this.orderRestriction = "";
                        this.orderValidity = 1;
                        /*this.$store.commit("setSelectedTradingCode", "");
                        this.$store.commit("setSelectedTradingExchangeId", 0);
                        this.$store.commit("setSelectedTradingStockName", "");
                        this.$store.commit("setSelectedTradingAction", "B");*/

                    } else {
                        this.$store.commit("setAppStateReady");
                        this.$toast.error(this.getOrderNotSentMessageLabel + res.data.ec, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getOrderNotSentMessageLabel + res.data.ec,
                            error: true
                        });
                    }
                }                
            } catch (err) {
                console.error(err);
            } 
            // remove the update order id
            this.$store.commit("setOrderIdToUpdate", "");
        },
        clearTradeForm() {
            // remove previous order so user could not click multiple times and send the same order
            this.orderStockCount = 0;
            this.orderAction = "B";
            this.orderMinExpectedPrice = 0;
            this.orderMaxExpectedPrice = 0;
            this.orderRestriction = "";
            this.orderValidity = 1;
            this.orderType = "put_market_order_ex";
            this.$store.commit("setSelectedTradingCode", "");
            this.$store.commit("setSelectedTradingExchangeId", 0);
            this.$store.commit("setSelectedTradingStockName", "");
            this.$store.commit("setSelectedTradingAction", "B");
            this.$store.commit("setSelectedTradingCodeShareTypeId", 1);
            this.$store.commit("setOrderIdToUpdate", "");
        },
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        parseNumber(num, afterDot = 2) {
            return UiUtils.formatNumber(num, afterDot);
        }, 
        onlyForCurrencyLimitedOrderPrice($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.limitOrderPrice.toString().indexOf('.') != -1)) { // 46 is dot
                $event.preventDefault();
            }

            // restrict to 4 decimal places
            if (this.limitOrderPrice != null && this.limitOrderPrice.toString().indexOf(".") > -1 && (this.limitOrderPrice.toString().split('.')[1].length > 3)) {
                $event.preventDefault();
            }
        },
        onlyForCurrencyOrderStockCount($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            if (!this.orderStockCount && 48 === keyCode) {
                $event.preventDefault();
            }

            // only allow number
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                $event.preventDefault();
            }
        },        
        limitPriceFocusGained() {
            if (0 === this.limitOrderPrice) {
                this.limitOrderPrice = null;
            }
        },
        limitPriceFocusLost() {
            if (null === this.limitOrderPrice) {
                this.limitOrderPrice = 0;
            }
        },
        showSearchModal() {
            // open modal
            const body = document.querySelector("body");
            this.searchStockModalActive = true;
            this.searchStockModalShow = true;
            body.classList.add("modal-open");
            this.$nextTick(() => {
                document.getElementById("modalSearchBox")?.focus();
            });            
        },
        async getQuoteTr() {
            if ("" === this.getSelectedItemCode) {
                return;
            }
            // get quote trading for selected item
            const user = this.$store.getters["getUser"];
            // get exchange code
            const exchanges = this.$store.getters["getExchanges"];
            const itemExchangeCode = UiUtils.getExchangeCodeById(exchanges, this.$store.getters["getSelectedTradingExchangeId"]);
            try {
                const res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "get_quote_tr",
                        id: user.id,
                        key: user.key,
                        sc: UiUtils.combineExchangeStockPair(itemExchangeCode, this.getSelectedItemCode)
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(ApiUtils.cleanAndLogoutOnError(this.$store, this.$router), 500);
                        return;
                    }                    
                    if (0 === res.data.ec) {
                        // set quote tr
                        this.quoteTrading = res.data;
                        this.$store.commit("setQuoteTrading", res.data);
                    } else {
                        this.$toast.error(this.getCouldNotReadQuotesMessage, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getCouldNotReadQuotesMessage,
                            error: true
                        });
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getDepth() {
            if ("" === this.getSelectedItemCode) {
                return;
            }          
            const user = this.$store.getters["getUser"];
            // get exchange code
            const exchanges = this.$store.getters["getExchanges"];
            const itemExchangeCode = UiUtils.getExchangeCodeById(exchanges, this.$store.getters["getSelectedTradingExchangeId"]);
            try {
                let res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "get_depth",
                        id: user.id,
                        key: user.key,
                        sc: UiUtils.combineExchangeStockPair(itemExchangeCode, this.getSelectedItemCode)
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(
                            ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                            500);
                        return;
                    }
                    if (0 === res.data.ec) {
                        // set in vuex
                        this.$store.commit("setDepth", res.data);
                    } else {
                        this.$toast.error(this.getCouldNotSetQuoteMessage, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getCouldNotSetQuoteMessage,
                            error: true
                        });
                    }
                }
                // set sse
                res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "set_sse",
                        id: user.id,
                        key: user.key,
                        "depth-set": UiUtils.combineExchangeStockPair(itemExchangeCode, this.getSelectedItemCode),
                        "depth-sts": "on"
                    }
                })
            } catch (err) {
                console.error(err);
            }
        },
        getStockCurrency(isSpecial = true) {
            if ("" === this.getSelectedItemCode) {
                return "";
            }

            const stocks = this.$store.getters["getStocks"];
            const matchingItems = stocks.filter(item => {
                return 0 === item.code.toString().toUpperCase().localeCompare(this.getSelectedItemCode.toUpperCase()) 
                    && item.cMIC === this.$store.getters["getSelectedTradingExchangeId"]
            });

            return isSpecial
                ? parseInt(matchingItems[0]?.tID) == 2
                    ? '%'
                    : matchingItems[0]?.cc || ''
                : matchingItems[0]?.cc || '';
        }, 
    },
    computed: {
        getOrderToEdit() {
            return this.$store.getters["getOrderToEdit"];
        },
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },        
        getStockName() {
            return this.$store.getters["getSelectedTradingStockName"];
        },        
        getCurrency() {
            return this.$store.getters["getCurrency"].toUpperCase();
        },
        canSubmitOrder() {
            return !("" !== this.getSelectedItemCode && 0 < this.orderMinExpectedPrice);
        },
        getAvailableStocks() {
            if ("" === this.getSelectedItemCode) {
                return 0;
            }

            const wallet = this.$store.getters["getWallet"];
            const matchingItems = wallet.s.filter(item => {
                return 0 === item.sc.toString().toUpperCase().localeCompare(this.getSelectedItemCode.toUpperCase());
            });
            if (!matchingItems || 0 === matchingItems?.length) {
                return 0;
            }
            return matchingItems[0].am;
        }, 
        getQuoteTrading() {
            const quoteTr = this.$store.getters["getQuoteTrading"];
            if (!quoteTr || !quoteTr.minp) {
                return { minp: 0, maxp: 0, tick: 0 }
            }

            return quoteTr;
        },
        getStoredDepth() {
            const depth = this.$store.getters["getDepth"];
            return depth && depth.bcp ?
                depth :
                { bcp: 0, acp: 0, dif: 0, ch: 0, ldc: 0, ldp: 0, dv: 0, B: [], A: [] };
        },
        getBestPriceFromDepth() {
            const depth = this.getStoredDepth;
            let max;
            if ("S" === this.orderAction) {
                // get best buy price
                max = 0 !== depth.B.length 
                    ? depth.B.reduce((prev, current) => (prev && prev.p > current.p) ? prev : current)
                    : null;
            } else {
                // get best sell price
                max = 0 !== depth.A.length
                    ? depth.A.reduce((prev, current) => (prev && prev.p > current.p) ? prev : current)
                    : null;
            }

            return max?.p || 0;
        },
        getSelectedItemCode() {
            return this.$store.getters["getSelectedTradingCode"];
        },
        getSelectedItemSegment() {
            return this.$store.getters["getSelectedTradingCodeSegment"];
        },
        getSelectedTradingCodeShareTypeId() {
            return this.$store.getters["getSelectedTradingCodeShareTypeId"];
        },
        getSelectedItemExchangeCode() {
            const exchangeCode = this.$store.getters["getSelectedTradingExchangeId"];
            const exchanges = this.$store.getters["getExchanges"];
            return UiUtils.getExchangeCodeById(exchanges, exchangeCode);
        },
        getSelectedTradingAction() {
            return this.$store.getters["getSelectedTradingAction"];
        },
        getOrderSentMessageLabel() {            
            return Translations.getOrderSentMessage(this.getUserLanguage());
        },
        getOrderNotSentMessageLabel() {
            return Translations.getOrderNotSentMessage(this.getUserLanguage());
        },
        getBuyLabel() {
            return Translations.getBuyLabel(this.getUserLanguage());
        },
        getSellLabel() {
            return Translations.getSellLabel(this.getUserLanguage());
        },
        getTypeLabel() {
            return Translations.getTypeLabel(this.getUserLanguage());
        },
        getMarketOption() {
            return Translations.getMarketLabel(this.getUserLanguage());
        },
        getLimitOption() {
            return Translations.getLimitLabel(this.getUserLanguage());
        },
        getCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage());
        },
        getNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage());
        },
        getCodePlaceholder() {
            return Translations.getCodePlaceholder(this.getUserLanguage());
        },
        getDynamicRangeLabel() {
            return Translations.getDynamicRangeLabel(this.getUserLanguage());
        },
        getMinPriceLabel() {
            return Translations.getMinPriceLabel(this.getUserLanguage());
        },
        getMaxPriceLabel() {
            return Translations.getMaxPriceLabel(this.getUserLanguage());
        },
        getTickSizeLabel() {
            return Translations.getTickSizeLabel(this.getUserLanguage());
        },
        getYouHaveLabel() {
            return Translations.getYouHaveLabel(this.getUserLanguage());
        },
        getStocksLabel() {
            return Translations.getStocksLabel(this.getUserLanguage());
        }, 
        getTotalEstimatedAmountLabel() {
            return Translations.getTotalEstimatedAmountLabel(this.getUserLanguage());
        },
        getApproximateAmountLabel() {
            return Translations.getApproximateAmountLabel(this.getUserLanguage());
        },
        getPriceLabel() {
            return Translations.getPriceLabel(this.getUserLanguage());
        },
        getChoseExpiryDateLabel() {
            return Translations.getChoseExpiryDateLabel(this.getUserLanguage());
        },
        getCloseLabel() {
            return Translations.getCloseLabel(this.getUserLanguage());
        },
        getChooseValidityLabel() {
            return Translations.getChooseValidityLabel(this.getUserLanguage());
        },
        getDailyLabel() {
            return Translations.getDailyLabel(this.getUserLanguage());
        },
        getDaysLabel() {
            return Translations.getDaysLabel(this.getUserLanguage());
        }, 
        getChoseDateLabel() {
            return Translations.getChoseDateLabel(this.getUserLanguage());
        },
        getGoodTillCancelledLabel() {
            return Translations.getGoodTillCancelledLabel(this.getUserLanguage());
        },
        getRestrictionsLabel() {
            return Translations.getRestrictionsLabel(this.getUserLanguage());
        },
        getNoRestrictionsLabel() {
            return Translations.getNoRestrictionsLabel(this.getUserLanguage());
        },
        getAcceptTCLabel() {
            return Translations.getAcceptTCLabel(this.getUserLanguage());
        },
        getSubmitLabel() {
            return Translations.getSubmitLabel(this.getUserLanguage());
        },
        getClearLabel() {
            return Translations.getClearLabel(this.getUserLanguage());
        },
        getSearchModalLabel() {
            return Translations.getSearchModalLabel(this.getUserLanguage());
        }, 
        getCouldNotReadQuotesMessage() {
            return Translations.getCouldNotReadQuotesMessage(this.getUserLanguage());
        },
        getNumberOfSharesLabel() {
            return Translations.getNumberOfSharesLabel(this.getUserLanguage());
        },
        getInvalidSessionLabel() {
            return Translations.getInvalidSessionLabel(this.getUserLanguage());
        },
        getCouldNotSetQuoteMessage() {
            return Translations.getCouldNotSetQuoteMessage(this.getUserLanguage());
        },
        getExchangeLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage());
        },
        getSegmentLabel() {
            return Translations.getSegmentLabel(this.getUserLanguage());
        },
        getFaceValueLabel() {
            return Translations.getFaceValueLabel(this.getUserLanguage());
        },
        calculateTickSize() {
            // algo from trader
            const quoteTr = this.$store.getters["getQuoteTrading"];
            if (!quoteTr) {
                return 0.0;
            }
            
            var orderSharePrice = quoteTr.a;
            var liqBandNumber = quoteTr.lbn;

            if (0 >= orderSharePrice) {
                orderSharePrice = quoteTr.ltp;
            }
            if (0 >= orderSharePrice) {
                orderSharePrice = quoteTr.clp;
            }
            if (64 === liqBandNumber && 1 === orderSharePrice) return 0.001;
            switch (liqBandNumber) {
                case 82: return 0.001;
                case 83: return 0.0001;
                case 84: return 0.001;
                case 85: return 0.001;
                case 86: return 0.001;
            }

            switch (liqBandNumber) {
                case 33: liqBandNumber = 1; break;
                case 34: liqBandNumber = 2; break;
                case 35: liqBandNumber = 3; break;
                case 37: liqBandNumber = 4; break;
                case 38: liqBandNumber = 5; break;
                case 39: liqBandNumber = 6; break;
                case 40: liqBandNumber = 7; break;
                case 41: liqBandNumber = 8; break;
                case 42: liqBandNumber = 9; break;
                case 43: liqBandNumber = 10; break;
                case 90: liqBandNumber = 2; break;
                case 91: liqBandNumber = 1; break;
            }
            if (0 >= liqBandNumber || 10 < liqBandNumber) return 0;

            let tickSize = sdMaxTickSizes[liqBandNumber];

            for (let i = 18; i > 0; i--) {
                if (orderSharePrice >= sdLiqPriceLevels[i]) {
                    break;
                }
                if (tickSize >= 500) tickSize = 200;
                else if (tickSize >= 200) tickSize = 100;
                else if (tickSize >= 100) tickSize = 50;
                else if (tickSize >= 50) tickSize = 20;
                else if (tickSize >= 20) tickSize = 10;
                else if (tickSize >= 10) tickSize = 5;
                else if (tickSize >= 5) tickSize = 2;
                else if (tickSize >= 2) tickSize = 1;
                else if (tickSize >= 1) tickSize = 0.5;
                else if (tickSize >= 0.5) tickSize = 0.2;
                else if (tickSize >= 0.2) tickSize = 0.1;
                else if (tickSize >= 0.1) tickSize = 0.05;
                else if (tickSize >= 0.05) tickSize = 0.02;
                else if (tickSize >= 0.02) tickSize = 0.01;
                else if (tickSize >= 0.01) tickSize = 0.005;
                else if (tickSize >= 0.005) tickSize = 0.002;
                else if (tickSize >= 0.002) tickSize = 0.001;
                else if (tickSize >= 0.001) tickSize = 0.0005;
                else if (tickSize >= 0.0005) tickSize = 0.0002;
                else if (tickSize >= 0.0002) tickSize = 0.0001;
            }
            return tickSize;
        },
        calculatePriceAsPercent() {
            if (0 !== 'xbul'.localeCompare(this.getSelectedItemExchangeCode.toLowerCase())) {
                return 0 === this.orderAction.toUpperCase().localeCompare('B')
                    ? parseFloat(this.getQuoteTrading.a || this.getQuoteTrading.ltp)
                    : parseFloat(this.getQuoteTrading.b || this.getQuoteTrading.ltp)
            }
            // get stock
            const allStocks = this.$store.getters["getStocks"];
            const stock = allStocks.filter(item => {
                return 0 === item.code.toString().toLowerCase().localeCompare(this.getSelectedItemCode.toLowerCase())
                    && item.cMIC === this.$store.getters["getSelectedTradingExchangeId"];
            })[0];
            const nominal = parseFloat(stock?.nominal || 1);

            return 0 === this.orderAction.toUpperCase().localeCompare('B')
                ? parseFloat(nominal * parseFloat(this.getQuoteTrading.a || this.getQuoteTrading.ltp) || 1)
                : parseFloat(nominal * parseFloat(this.getQuoteTrading.b || this.getQuoteTrading.ltp) || 1);
        }
    },
    async mounted() {
        await this.getQuoteTr();
    },
}
</script>

<style scoped>
    .align-trade-buttons {
        margin-top: -1rem;
    }

    .align-trade-button-market {
        margin-top: 3.8rem;
    }

    .align-trade-button-limit {
        margin-top: 2.4rem;
    }
  .btn-buy {
    border: 2px solid #74b21f;
    border-radius: 5px;    
    background-color: #fff;
    color: #74b21f;
  }
  .btn-sell {
    border: 2px solid red;
    border-radius: 5px;    
    background-color: fff;
    color: red;
  }
</style>