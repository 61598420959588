<template>
    <div class="col-sm-12 col-md-12">
        <div class="table-responsive">
            <table>
                <thead v-if="1200 < getWindowsWidth">
                    <tr style="text-align: center;">
                        <th style="width: 0.02rem;"></th>
                        <th style="width: 1rem;">{{ getExchangeLabel.toUpperCase() }}</th>
                        <th v-if="'EN' === getUserLanguage()" style="width: 6rem;">{{ getCodeLabel.toUpperCase() }}</th>
                        <th v-else style="width: 25rem;">{{ getCodeLabel.toUpperCase() }}</th>
                        <th v-if="'EN' === getUserLanguage()" style="width: 150rem;">{{ getNameLabel.toUpperCase() }}</th>
                        <th v-else style="width: 280rem;">{{ getNameLabel.toUpperCase() }}</th>
                        <th v-if="'EN' === getUserLanguage()" style="width: 20rem;">{{ getChangeLabel.toUpperCase() }}</th>
                        <th v-else style="width: 30rem;">{{ getChangeLabel.toUpperCase() }}</th>
                        <th v-if="'EN' === getUserLanguage()" style="width: 20rem;">%&nbsp;{{ getChangeLabel.toUpperCase() }}</th>
                        <th v-else style="width: 3rem;">%&nbsp;{{ getChangeLabel.toUpperCase() }}</th>
                        <th v-if="'EN' === getUserLanguage()" style="width: 10rem;">{{ getSellLabel.toUpperCase() }}</th>
                        <th v-else style="width: 3rem;">{{ getSellLabel.toUpperCase() }}</th>
                        <th v-if="'EN' === getUserLanguage()" style="width: 10rem;">{{ getBuyLabel.toUpperCase() }}</th>
                        <th v-else style="width: 3rem;">{{ getBuyLabel.toUpperCase() }}</th>
                    </tr>
                </thead>
                <thead v-else>
                    <tr>
                        <th style="min-width:11.5rem;" class="center-column xsmall-font-size">{{ getStockLabel.toUpperCase() }}</th>
                        <th style="width:15rem;" class="center-column xsmall-font-size">{{ getBuyLabel.toUpperCase() }}</th>
                        <th style="width:25rem;" class="center-column xsmall-font-size">{{ getSellLabel.toUpperCase() }}</th>
                        <th style="width:3rem;" class="center-column"></th>
                    </tr>
                </thead>
                <tbody v-if="0 !== filteredItems.length" class="stocks-list">
                    <QuoteListItem v-for="(item, index) in filteredItems" :key="userWatchlists + index + item.cMIC + item.code"
                        :itemStockCode="item.code" :itemExchangeId="item.cMIC" :itemStockCodeSegment="item.sgm" :itemStockCodeTypeId="item.tID" />
                </tbody>
                <tbody v-else class="stocks-list">
                    {{ getNoItemsInWatchlist }}
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import QuoteListItem from '../components/QuoteListItem.vue';
import Translations from "@/lang/translations.js";

// https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
function dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export default {
    components: {
        QuoteListItem
    },
    data() {
        return {
            processedTime: 0,
            activeSortingOption: this.getWatchlistSortingOrder
        }
    },
    props: {
        userWatchlists: {
            required: false,
            type: Boolean,
            default: true
        }
    },
    watch: {
        getWatchlistSortingOrder: function(newVal) {
            this.activeSortingOption = newVal;
        }
    },    
    methods: {
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },          
        filterItems() {
            let searchedItems = this.userWatchlists 
                ? this.$store.getters["getSearchedStocksUserWatchlist"].slice()
                : this.$store.getters["getSearchedStocksIndexWatchlist"].slice();

            // filter by exchange code
            const exchangeCode = this.$store.getters["getSelectedExchangeCode"];
            if (0 !== parseInt(exchangeCode)) {
                // XFRA should make use of XETR indexes
                const wlstName = this.$store.getters["getClickedWatchlistName"]?.toUpperCase();
                const knownIndexes = this.$store.getters["getKnownExchangeIndexes"];
                let myEx = knownIndexes.includes(wlstName) && 3 === parseInt(exchangeCode)
                    ? 2
                    : parseInt(exchangeCode);
                searchedItems = searchedItems.filter(stock => {
                    return myEx === parseInt(stock.cMIC);
                });
            }
            // sort items 
            switch (this.activeSortingOption) {
                case 0: return searchedItems;
                case 1: {
                    // symbol asc
                    return searchedItems.sort(dynamicSort('code'));
                }
                case 2: {
                    // symbol desc
                    return searchedItems.sort(dynamicSort('-code'));
                }
                case 3: {
                    // name asc
                    return searchedItems.sort(dynamicSort('nEN'));
                }
                case 4: {
                    // name desc
                    return searchedItems.sort(dynamicSort('-nEN'));
                }
            }                       
            return 0 === searchedItems.length ? [] : searchedItems;
        },
        filteredItems() {
            return this.filterItems;
        },
        getWatchlistSortingOrder() {
            return this.$store.getters["getWatchlistSortingOption"];
        },
        getNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage());
        },
        getBuyLabel() {
            return Translations.getBuyLabel(this.getUserLanguage());
        },
        getSellLabel() {
            return Translations.getSellLabel(this.getUserLanguage());
        }, 
        getNoItemsInWatchlist() {
            return Translations.getNoItemsInWatchlistMessage(this.getUserLanguage());
        }, 
        getExchangeLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage());
        }, 
        getCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage());
        }, 
        getChangeLabel() {
            return Translations.getChangeLabel(this.getUserLanguage());
        },
        getStockLabel() {
            return Translations.getStockLabel(this.getUserLanguage());
        }
    }
}
</script>
<style scoped>
    th {
        text-align: left
    }
  thead,
  tbody {
    display: block;
  }

  tbody {
    overflow-y: auto;
    overflow-x: hidden;
  }

  th {
    width: 100%;
  }

  .center-column {
    vertical-align: middle;
    text-align: center;
    min-width: 4.5rem;
  }
  .stocks-list {
    max-height: 41rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .scroller {
      height: 100%;
  }

.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
}  
</style>
