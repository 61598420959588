<template>
  <div class="vh-100 d-flex justify-content-center">
    <div class="form-access my-auto">
      <form>
        <span>Reset password</span>
        <input type="email" class="form-control" placeholder="Enter Your Email Address" v-model="email" required />
        <button type="submit" class="btn btn-primary" :disabled="!submitEnabled"
          @click.prevent="resetPassword()">Reset</button>
        <h2>
          Remember Password?
          <router-link to="/login"> Login</router-link>
        </h2>
      </form>
    </div>
  </div>
</template>

<script>
import http from "@/axios/http-common";
import Translations from "@/lang/translations.js";

export default {
  data() {
    return {
      email: ""
    }
  },
  methods: {
    getUserType() {
      return this.$store.getters["getUserType"];
    },    
    async resetPassword() {
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "reset_password",
            em: encodeURIComponent(this.email)
          }
        });
        // reset email to avoid clicking on button
        this.email = "";
        if (res) {
          // check answer
          if (0 === res.data.ec) {
            this.$toast.success(this.getPasswordResetMessage, {
              "duration": 10000,
              "pauseOnHover": true
            });
            this.$router.push({ name: "Login" });
          } else {
            this.$toast.error(this.getCouldNotResetPasswordMessage + ": " + res.data.ec, {
              "duration": 5000,
              "pauseOnHover": true
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    }    
  },
  computed: {
    submitEnabled() {
      return 0 < this.email.length;
    },
    getUserLanguage() {
      return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
    },
    getCouldNotResetPasswordMessage() {
      return Translations.getCouldNotUpdatePasswordMessage(this.getUserLanguage);
    },
    getPasswordResetMessage() {
      return Translations.getPasswordResetMessage(this.getUserLanguage);
    }
  }
}
</script>