<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="row sm-gutters">
            <!-- Menu -->
            <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
                <Menu></Menu>
            </div>
            <!-- exchange breakdown -->
            <div v-if="'ExchangeBreakdown' === currentVuewName" class="col-sm-12"
                :class="{'col-md-10' : 1200 < getWindowsWidth }">
                <div class="row align-items-center">
                    <div class="col-sm-1">
                        <button class="btn btn-outline-secondary btn-light mb-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="goBack()">&larr;</button>
                    </div>
                    <div class="col-sm-11 text-left">
                        <h5>{{ getPositionsLabel }} - {{ getExchangeName }}</h5>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td v-if="1 === selectedItemExchangeId && 1200 < getWindowsWidth"><img
                                        class="card-img-top" src="../assets/images/bfb_logo.png"
                                        alt="Bulgarian Fund Exchange"></td>
                                <td v-if="2 === selectedItemExchangeId && 1200 < getWindowsWidth"><img
                                        class="card-img-top" src="../assets/images/Deutsche_Boerse_Xetra_Logo.png"
                                        alt="Deutsche Boerse Xetra"></td>
                                <td v-if="3 === selectedItemExchangeId && 1200 < getWindowsWidth"><img
                                        class="card-img-top" src="../assets/images/xfra_logo.png"
                                        alt="Boerse Frankfurt"></td>
                                <td>
                                    <img v-if="1 === selectedItemExchangeId && 1200 > getWindowsWidth"
                                        class="card-img-top-responsive" src="../assets/images/bfb_logo.png"
                                        alt="Bulgarian Fund Exchange">
                                    <img v-if="2 === selectedItemExchangeId && 1200 > getWindowsWidth"
                                        class="card-img-top" src="../assets/images/Deutsche_Boerse_Xetra_Logo.png"
                                        alt="Deutsche Boerse Xetra">
                                    <img v-if="3 === selectedItemExchangeId && 1200 > getWindowsWidth"
                                        class="card-img-top" src="../assets/images/xfra_logo.png"
                                        alt="Boerse Frankfurt">
                                    <br />
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td v-if="1200 < getWindowsWidth">{{ getPositionsLabel }} ({{
                                                    getBaseCurrencyLabel }})</td>
                                                <td v-else :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                                    getBaseCurrencyLabel }}</td>
                                                <td class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    {{ formatPrice(getExchangeEquitiesValueInBaseCurrency,
                                                    getCurrency).replace(' ', '&nbsp;') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                                    getPositionsLabel }}</td>
                                                <td class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    {{ formatPrice(getExchangeEquitiesValueInExchangeCurrency,
                                                    getExchangeCurrency).replace(' ', '&nbsp;') }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                                    getMyNumberOfInstrumentsLabel }}</td>
                                                <td class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                                    parseNumber(getExchangePositionsCount, 0) }}</td>
                                            </tr>
                                            <tr v-if="0 < parseFloat(getExchangeEquitiesValue)">
                                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                                    getReturnLabel }}</td>
                                                <td v-if="getExchangeEquitiesValue - getExchangeAverageEquitiesValue > 0"
                                                    class="greenColor equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    +{{ parseNumber(getExchangeEquitiesValue -
                                                    getExchangeAverageEquitiesValue) }}
                                                    (+{{ parseNumber(((getExchangeEquitiesValue -
                                                    getExchangeAverageEquitiesValue) /
                                                    getExchangeAverageEquitiesValue)
                                                    * 100) }}%)
                                                </td>
                                                <td v-if="getExchangeEquitiesValue - getExchangeAverageEquitiesValue < 0"
                                                    class="redColor equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    {{ parseNumber(getExchangeEquitiesValue -
                                                    getExchangeAverageEquitiesValue) }}
                                                    ({{ parseNumber(((getExchangeEquitiesValue -
                                                    getExchangeAverageEquitiesValue) /
                                                    getExchangeAverageEquitiesValue)
                                                    * 100) }}%)
                                                </td>
                                            </tr>
                                            <tr v-else>
                                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                                    getReturnLabel }}</td>
                                                <td class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                                    parseNumber(getExchangeEquitiesValue -
                                                    getExchangeAverageEquitiesValue) }}(-%)
                                                </td>
                                            </tr>
                                            <tr v-if="!isNaN(getExchangeSharesCount)">
                                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                                    getPositionsBalanceLabel }}</td>
                                                <td v-if="0 !== getExchangeEquitiesValue && 0 !== getAllExchangesEquitiesAsMoneyDefaultCurrency"
                                                    class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    <meter
                                                        :value="getExchangeEquitiesValue / (getAllExchangesEquitiesAsMoneyDefaultCurrency) * 100"
                                                        min="0" max="100"></meter>&nbsp;({{
                                                    parseNumber(getExchangeEquitiesValue /
                                                    (getAllExchangesEquitiesAsMoneyDefaultCurrency) * 100) }}%)
                                                </td>
                                                <td v-else class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    <meter value="0" min="0" max="100"></meter>&nbsp;({{ parseNumber(0)
                                                    }}%)
                                                </td>
                                            </tr>
                                            <tr v-else>
                                                <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                                </td>
                                            </tr>
                                            <tr v-if="!isNaN(getExchangeSharesCount)">
                                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%{{
                                                    getTotalBalanceLabel }}</td>
                                                <td v-if="0 !== getExchangeEquitiesValue && 0 !== totalBalance"
                                                    class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    <meter :value="getExchangeEquitiesValue / totalBalance * 100"
                                                        min="0" max="100"></meter>&nbsp;({{
                                                    parseNumber(getExchangeEquitiesValue / totalBalance * 100) }}%)
                                                </td>
                                                <td v-else class="equity_align_right_heavy"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    <meter value="0" min="0" max="100"></meter>&nbsp;({{ parseNumber(0)
                                                    }}%)
                                                </td>
                                            </tr>
                                            <tr v-else>
                                                <td colspan="2" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">
                                                    <meter value="0" min="0" max="100"></meter>&nbsp;(0%)
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="1200 < getWindowsWidth" class="table-responsive">
                    <table class="table is-fullwidth is-hoverable">
                        <thead>
                            <tr>
                                <th scope="col">{{ getTypeLabel }}</th>
                                <th scope="col">{{ getStockNameLabel }}</th>
                                <th scope="col">{{ getStockCodeLabel }}</th>
                                <th scope="col">{{ getMyPositionsLabel }}</th>
                                <th scope="col">{{ getCurrencyLabel }}</th>
                                <th scope="col">{{ getPurchasePriceLabel }}</th>
                                <th scope="col">{{ getTotalAmountLabel }}</th>
                                <th scope="col">{{ getMarketPriceLabel }}</th>
                                <th scope="col">{{ getValueLabel }}</th>
                                <th scope="col">{{ getReturnLabel }}</th>
                                <th v-if="0 === 'BG'.localeCompare(getUserLanguage)" scope="col">{{ getReturnLabel
                                    }}&nbsp;%</th>
                                <th v-else scope="col">%&nbsp;{{ getReturnLabel }}</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="center_verticaly" v-for="stock in getExchangeAvailableStocks" :key="stock.sc">
                                <td>{{ getStockType(stock.stid) }}</td>
                                <td v-if="0 === 'BG'.localeCompare(getUserLanguage)">{{ stock.nBG ? stock.nBG :
                                    stock.nEN }}</td>
                                <td v-if="0 === 'EN'.localeCompare(getUserLanguage)">{{ stock.nEN }}</td>
                                <td style="text-align:center">{{ stock.sc }}</td>
                                <td class="equity_align_right">{{ parseNumber(stock.am, 0) }}</td>
                                <td style="text-align:center">{{ stock.cc }}</td>
                                <td v-if="2 !== stock.stid" class="equity_align_right">{{
                                    formatPrice(getTradeAvgPrice(stock), stock.cc).replace(' ', '&nbsp;') }}</td>
                                <td v-else class="equity_align_right">{{ formatPrice(getTradeAvgPrice(stock),
                                    '%').replace(' ', '&nbsp;') }}</td>
                                <td class="equity_align_right">{{ formatPrice(getTradePurchasePrice(stock),
                                    stock.cc).replace(' ', '&nbsp;') }}</td>
                                <td v-if="2 !== stock.stid" class="equity_align_right">{{ formatPrice(stock.mp,
                                    stock.cc).replace(' ', '&nbsp;') }}</td>
                                <td v-else class="equity_align_right">{{ formatPrice(stock.mp, '%').replace(' ',
                                    '&nbsp;') }}</td>
                                <td class="equity_align_right">{{ formatPrice(getTradeMarketPrice(stock),
                                    stock.cc).replace(' ', '&nbsp;') }}</td>
                                <td v-if="getTradeMarketPrice(stock) - getTradePurchasePrice(stock) > 0"
                                    class="equity_align_right greenColor">{{ parseNumber(getTradeMarketPrice(stock) -
                                    getTradePurchasePrice(stock)) }}</td>
                                <td v-else-if="getTradeMarketPrice(stock) - getTradePurchasePrice(stock) < 0" class="equity_align_right redColor">{{ parseNumber(getTradeMarketPrice(stock)
                                    - getTradePurchasePrice(stock)) }}</td>
                                <td v-else class="equity_align_right">{{ parseNumber(getTradeMarketPrice(stock)
                                    - getTradePurchasePrice(stock)) }}</td>
                                <td v-if="getTradeMarketPrice(stock) - getTradePurchasePrice(stock) > 0"
                                    class="equity_align_right greenColor">{{
                                    parseNumber(getPercentDiff(getTradeMarketPrice(stock),
                                    getTradePurchasePrice(stock))) }}%</td>
                                <td v-else-if="getTradeMarketPrice(stock) - getTradePurchasePrice(stock) < 0" class="equity_align_right redColor">{{
                                    parseNumber(getPercentDiff(getTradeMarketPrice(stock),
                                    getTradePurchasePrice(stock))) }}%</td>
                                <td v-else class="equity_align_right">{{
                                    parseNumber(getPercentDiff(getTradeMarketPrice(stock),
                                    getTradePurchasePrice(stock))) }}%</td>
                                <td class="btn-group">
                                    <button type="button" class="btn btn-primary w-50"
                                        @click.prevent="selectedItem = stock; currentVuewName = 'TradesList'">&rarr;</button>&nbsp;
                                    <button type="button" class="btn btn-primary w-50"
                                        @click.prevent="selectedItem = stock; currentVuewName = 'InstrumentDetails'">&uarr;</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <div class="card" v-for="stock in getExchangeAvailableStocks" :key="stock.sc">
                        <div class="card-body xsmall-font-size">
                            <div class="container p-0">
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <span v-if="0 === 'BG'.localeCompare(getUserLanguage)"><strong>{{ stock.nBG ?
                                                stock.nBG : stock.nEN }}</strong></span>
                                        <span v-if="0 === 'EN'.localeCompare(getUserLanguage)"><strong>{{ stock.nEN
                                                }}</strong></span>
                                        <br />
                                        {{ getStockType(stock.stid) }}<br />
                                        {{ stock.sc.toUpperCase() }}<br />
                                        {{ getAmountLabel }}: {{ parseNumber(stock.am, 0) }}
                                    </div>
                                    <div class="col-6 text-end my-auto">
                                        <td class="btn-group">
                                            <button type="button" class="btn btn-sm btn-primary w-50"
                                                @click.prevent="selectedItem = stock; currentVuewName = 'TradesList'">&rarr;</button>&nbsp;
                                            <button type="button" class="btn btn-sm btn-primary w-50"
                                                @click.prevent="selectedItem = stock; currentVuewName = 'InstrumentDetails'">&uarr;</button>
                                        </td>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="'TradesList' === currentVuewName" class="col-sm-12 col-md-10">
                <!-- header -->
                <div class="row align-items-center">
                    <div class="col-sm-1">
                        <!-- back button -->
                        <button class="btn btn-outline-secondary btn-light mb-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="currentVuewName = 'ExchangeBreakdown'">&larr;</button>
                    </div>
                    <div class="col-sm-11 text-left">
                        <!-- view header -->
                        <h5 v-if="1200 < getWindowsWidth">{{ getPositionsLabel }} - {{ getExchangeName }} - {{
                            selectedItem?.sc }}, {{ 0 === 'BG'.localeCompare(getUserLanguage) && selectedItem?.nBG ?
                            selectedItem?.nBG : selectedItem?.nEN }} - {{ getTradesLabel }}</h5>
                        <h6 v-else>{{ getPositionsLabel }} - ... - {{ getTradesLabel }}</h6>
                    </div>
                </div>
                <div class="card" v-if="1200 < getWindowsWidth">
                    <div class="card-body">
                        <h5 class="card-title">{{ getTradesLabel }}</h5>
                        <div class="wallet-history">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>{{ getOrderIdLabel }}/{{ getTimestampLabel }}</th>
                                            <th>{{ getTypeLabel }}</th>
                                            <th>{{ getOrderTypeLabel }}</th>
                                            <th>{{ getCodeLabel }}</th>
                                            <th>{{ getNameLabel }}</th>
                                            <th>{{ getQuantityLabel }}</th>
                                            <th>{{ getCurrencyLabel }}</th>
                                            <th>{{ getPriceLabel }}</th>
                                            <th class="price-data">{{ getTotalAmountLabel }}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="center_verticaly" v-for="(transaction, index) in getFilteredTrades"
                                            :key="index">
                                            <td><strong>#{{ transaction.oid }}</strong><br />{{ getDate(transaction.ts)
                                                }}</td>
                                            <td style="text-align:center">{{ capitalizeFirstLetter(getPurchaseType(tradeListOrders.filter(item => {
                                                return 0 === transaction?.oid.localeCompare(item.oid);
                                                })[0]?.data?.type)) }}</td>
                                            <td style="text-align:center">{{ capitalizeFirstLetter(getOrderType(tradeListOrders.filter(item => {
                                                return 0 === transaction?.oid.localeCompare(item.oid);
                                                })[0]?.data?.action)) }}</td>
                                            <td style="text-align:center">{{ transaction.sc.toUpperCase() }}</td>
                                            <td>
                                                <div v-if="0 === 'BG'.localeCompare(getUserLanguage)" class="row">{{
                                                    transaction?.nBG ? transaction?.nBG : transaction?.nEN }}</div>
                                                <div v-if="0 === 'EN'.localeCompare(getUserLanguage)" class="row">{{
                                                    transaction?.nEN }}</div>
                                            </td>
                                            <td style="text-align:right" class="price-data">{{ parseNumber(transaction?.qty, 0) }}</td>
                                            <td style="text-align:center">{{ transaction?.cc?.toUpperCase() }}</td>
                                            <td style="text-align:right" class="price-data">{{ formatPrice(transaction?.sp,
                                                transaction?.cc).replace(' ', '&nbsp;') }}</td>
                                            <td style="text-align:right" class="price-data">{{ formatPrice(transaction?.pt,
                                                transaction?.cc).replace(' ', '&nbsp;') }}</td>
                                            <td>
                                                <button type="button" class="btn btn-primary"
                                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                                    @click.prevent="trade = transaction; currentVuewName = 'TradeDetails'">&rarr;</button>&nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else v-for="(transaction, index) in getFilteredTrades" :key="index">
                    <div class="card">
                        <div class="card-body xsmall-font-size">
                            <div class="container p-0">
                                <div class="row justify-content-between">
                                    <div class="col-6">
                                        <strong>#{{ transaction.oid }}</strong><br />
                                        {{ getDate(transaction.ts) }}<br />
                                        {{ transaction.sc.toUpperCase() }}@{{ transaction.xc.toUpperCase() }}<br />
                                        {{ getOrderStatus(getOrderById(transaction.oid)) }}
                                    </div>
                                    <div class="col-6 text-end my-auto">
                                        <button type="button" class="btn btn-primary"
                                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                            @click.prevent="trade = transaction; currentVuewName = 'TradeDetails'">&rarr;</button>&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="'TradeDetails' === currentVuewName" class="col-sm-12 col-md-10">
                <!-- header -->
                <div class="row align-items-center">
                    <div class="col-sm-1">
                        <!-- back button -->
                        <button class="btn btn-outline-secondary btn-light mb-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="currentVuewName = 'TradesList'">&larr;</button>
                    </div>
                    <div class="col-sm-11 text-left">
                        <!-- view header -->
                        <h5 v-if="1200 < getWindowsWidth">{{ getPositionsLabel }} - {{ getExchangeName }} - {{
                            selectedItem?.sc }}, {{ 0 === 'BG'.localeCompare(getUserLanguage) && selectedItem?.nBG ?
                            selectedItem?.nBG : selectedItem?.nEN }} - {{ getTradesLabel }} - #{{ trade?.oid }}</h5>
                        <h6 v-else>{{ getPositionsLabel }} - ... - #{{ trade?.oid }}</h6>
                    </div>
                </div>
                <!-- trade details -->
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTimestampLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getDate(trade?.ts) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getOrderIdLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">#{{
                                    trade?.oid }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getExchangeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.xc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTypeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getOrderType(trade?.tt) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getOrderTypeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    tradeListOrders.filter(item => { return 0 === trade?.oid.localeCompare(item.oid);
                                    })[0]?.data?.type }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCodeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.sc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getNameLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BG'.localeCompare(getUserLanguage)">{{ trade?.nBG ? trade?.nBG :
                                    trade?.nEN }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EN'.localeCompare(getUserLanguage)">{{ trade?.nEN }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getQuantityLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.qty }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCurrencyLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    trade?.cc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPriceLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.sp) }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.sp) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getAmountLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.pt) }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.pt) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getFeeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.fee) }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.fee) }}</td>
                            </tr>
                            <tr v-if="0 === 'b'.localeCompare(trade?.tt)">
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTotalAmountLabel }}
                                </td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.pt + trade?.fee)
                                    }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.pt +
                                    trade?.fee) }}</td>
                            </tr>
                            <tr v-else>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTotalAmountLabel }}
                                </td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BGN'.localeCompare(trade?.cc)">{{ parseNumber(trade?.pt - trade?.fee)
                                    }} лв.</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EUR'.localeCompare(trade?.cc)">&euro;{{ parseNumber(trade?.pt -
                                    trade?.fee) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="'InstrumentDetails' === currentVuewName" class="col-sm-12 col-md-10">
                <div class="row align-items-center">
                    <div class="col-sm-1">
                        <button class="btn btn-outline-secondary btn-light mb-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="currentVuewName = 'ExchangeBreakdown'">&larr;</button>
                    </div>
                    <div class="col-sm-11 text-left">
                        <h5 v-if="1200 < getWindowsWidth">{{ getPositionsLabel }} - {{ getExchangeName }} - {{
                            selectedItem?.sc }}, {{ 0 === 'BG'.localeCompare(getUserLanguage) && selectedItem?.nBG ?
                            selectedItem?.nBG : selectedItem?.nEN }} - {{ getInformationLabel }}</h5>
                        <h6 v-else>{{ getPositionsLabel }} - ... - {{ getInformationLabel }}</h6>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTypeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getStockType(selectedItem?.stid) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getExchangeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getExchangeName }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getStockCodeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    selectedItem?.sc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getStockNameLabel }}</td>
                                <td v-if="0 === 'BG'.localeCompare(getUserLanguage)" class="prop-value"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ selectedItem?.nBG ?
                                    selectedItem?.nBG : selectedItem?.nEN }}</td>
                                <td v-if="0 === 'EN'.localeCompare(getUserLanguage)" class="prop-value"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ selectedItem?.nEN }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getMyPositionsLabel }}
                                </td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(selectedItem?.am, 0) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCurrencyLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    selectedItem?.cc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getAveragePriceLabel }}
                                </td>
                                <td v-if="2 !== selectedItem?.stid" class="prop-value"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getTradeAvgPrice(selectedItem), selectedItem?.cc).replace(' ', '&nbsp;')
                                    }}</td>
                                <td v-else class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getTradeAvgPrice(selectedItem), '%').replace(' ', '&nbsp;') }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTotalAmountLabel }}
                                </td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getTradePurchasePrice(selectedItem), selectedItem?.cc).replace(' ',
                                    '&nbsp;') }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getMarketPriceLabel }}
                                </td>
                                <td v-if="2 !== selectedItem?.stid" class="prop-value"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(selectedItem?.mp, selectedItem?.cc).replace(' ', '&nbsp;') }}</td>
                                <td v-else class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(selectedItem?.mp, '%').replace(' ', '&nbsp;') }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getValueLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getTradeMarketPrice(selectedItem), selectedItem?.cc).replace(' ',
                                    '&nbsp;') }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getReturnLabel }}</td>
                                <td class="prop-value greenColor"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="getTradeMarketPrice(selectedItem) > getTradePurchasePrice(selectedItem)">+{{
                                    parseNumber(getTradeMarketPrice(selectedItem) - getTradePurchasePrice(selectedItem))
                                    }}</td>
                                <td class="prop-value redColor" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-else>{{ parseNumber(getTradeMarketPrice(selectedItem) -
                                    getTradePurchasePrice(selectedItem)) }}</td>
                            </tr>
                            <tr>
                                <td v-if="0 === 'BG'.localeCompare(getUserLanguage)"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getReturnLabel }}&nbsp;%
                                </td>
                                <td v-else :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%&nbsp;{{
                                    getReturnLabel }}</td>
                                <td class="prop-value greenColor"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="getTradeMarketPrice(selectedItem) > getTradePurchasePrice(selectedItem)">+{{
                                    parseNumber(getPercentDiff(getTradeMarketPrice(selectedItem),
                                    getTradePurchasePrice(selectedItem))) }}%</td>
                                <td class="prop-value redColor" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-else>{{ parseNumber(getPercentDiff(getTradeMarketPrice(selectedItem),
                                    getTradePurchasePrice(selectedItem))) }}%</td>
                            </tr>
                            <tr>
                                <td v-if="0 === 'BG'.localeCompare(getUserLanguage)"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPortfolioLabel
                                    }}&nbsp;%</td>
                                <td v-else :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">%&nbsp;{{
                                    getPortfolioLabel }}</td>
                                <td v-if="0 !== getAllExchangesEquitiesAsMoneyDefaultCurrency" class="prop-value"
                                    :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(getTradeMarketPrice(selectedItem) /
                                    getAllExchangesEquitiesAsMoneyDefaultCurrency * 100) }}%</td>
                                <td v-else class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    parseNumber(0) }}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/axios/http-common";
import Menu from '../components/Menu.vue';
import UiUtils from "@/assets/js/ui_utils.js";
import Translations from "@/lang/translations.js";

export default {
    components: {
        Menu
    },
    data() {
        return {
            selectedItemExchangeId: parseInt(this.itemExchangeId),
            selectedItem: null,
            currentVuewName: "ExchangeBreakdown",
            ordersProp: JSON.parse(this.orders),
            tradesProps: JSON.parse(this.trades),
            trade: null,
            tradeListOrders: []
        }
    },
    props: {
        itemExchangeId: {
            required: true,
            type: String
        },
        orders: {
            required: true,
            type: String
        },
        trades: {
            required: true,
            type: String
        }
    },
    methods: {
        formatPrice(val, curr, autoDigits = 2) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage, autoDigits);
        },
        parseNumber(num, afterDot = 2) {
            return UiUtils.formatNumber(num, afterDot);
        },
        capitalizeFirstLetter(string) {
            return string
                ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
                : "";
        },
        getMoneyRatio(currency) {
            if (!currency) {
                return 0;
            }
            const wallet = this.$store.getters["getWallet"];

            if (!wallet || !wallet?.r) {
                return 0;
            }
            const rate = wallet.r.filter(item => {
                return 0 === item.c.toString().toUpperCase().localeCompare(currency.toString().toUpperCase() + '/BGN');
            });
            if (!rate || 0 === rate?.length) {
                return 0;
            }
            return rate[0].r;
        },
        getStockType(typeId) {
            switch(parseInt(typeId)) {
                case 1: return this.getEquitiesLabel;
                case 2: return this.getBondsLabel;
                case 3: return this.getCompInstrumentsLabel;
                case 4: return this.getFundsLabel;
                case 5: return this.getReitsLabel;
                case 6: return this.getRightsLabel;
                case 7: return this.getCfdLabel;
                case 8: return this.getMarginLabel;
                case 9: return this.getNettingLabel;
                case 10: return this.getRepoLabel;
            }

            return "Unknown";
        },
        getStockCommissionsPaid(stock) {
            let pp = 0;
            stock.t?.forEach(item => {
                if (0 === "b".localeCompare(item.tt)) {
                    pp += item.fee || 0;
                }
            });

            return pp || 0;
        },
        getDate(unix) {
            return UiUtils.parseUnixTime(unix);
        },
        getOrderType(ot) {
            if (!ot) {
                return "";
            }
            return 0 === ot.toString().toLowerCase().localeCompare("b") 
                ? Translations.getBuyLabel(this.getUserLanguage) 
                : Translations.getSellLabel(this.getUserLanguage);
        },
        getPurchaseType(type) {
            if (!type) {
                return "";
            }
            return 0 === type.toString().toLowerCase().localeCompare("market")
                ? Translations.getMarketLabel(this.getUserLanguage)
                : Translations.getLimitLabel(this.getUserLanguage);
        },        
        async getOrderById(orderId) {
            try {
                const user = this.$store.getters["getUser"];
                const res = await http.getApiClient(this.$store.getters["getUserType"]).get("", {
                    params: {
                        a: "get_order",
                        id: user.id,
                        key: user.key,
                        oid: orderId
                    }
                });
                // add to reactive state
                this.tradeListOrders.push({
                    "oid": orderId,
                    "data": res.data
                });
                return res.data;
            } catch (err) {
                console.error(err);
            }
        },
        getOrderStatus(order) {
            if (!order) {
                return "N/A";
            }
            switch (order.status) {
                case 1:
                case 2:
                case 12:
                case 99: {
                    return Translations.getProcessingLabel(this.getUserLanguage);
                }
                case 3: {
                    return Translations.getPlacedLabel(this.getUserLanguage);
                }
                case 4: 
                case 5: {
                    return Translations.getCancelledLabel(this.getUserLanguage);
                }
                case 6: 
                case 7: {
                    return Translations.getFilledLabel(this.getUserLanguage);
                }
            }
            return "N/A";
        },
        goBack() {
            // if latestDisplayStock is not null the this is details for exchanges breakdown and we need it to display appropriate list of trades
            this.$router.push({ name: "Wallet", params: { latestActiveMenuSelection: "Equities" } });
        },
        getPercentDiff(marketPrice, avgPrice) {
            const diff = parseFloat(marketPrice) - parseFloat(avgPrice);
            return parseFloat(diff) / parseFloat(avgPrice) * 100;
        }, 
        getPurchasePrice(item) {
            return item?.am * item?.ap + item?.fee;
        },
        getCurrentPrice(item) {
            return item?.am * item?.mp + item?.fee;
        },
        getTradeAvgPrice(item) {
            return UiUtils.getTradeAvgPrice(item);
        },
        getTradePurchasePrice(item) {
            return UiUtils.getTradePurchasePrice(item);
        },
        getTradeMarketPrice(item) {
            return UiUtils.getTradeMarketPrice(item);
        },
        getCurrencyRatio(stockCurrency, requestCurrency) {
            const userWallet = this.$store.getters["getWallet"];
            return UiUtils.getCurrencyRatio(userWallet, stockCurrency, requestCurrency);
        }
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },          
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getExchangeCurrency() {
            const exchanges = this.$store.getters["getExchanges"];
            return UiUtils.getExchangeCurrencyById(exchanges, this.selectedItemExchangeId);
        },
        getCurrency() {
            return this.$store.getters["getCurrency"].toUpperCase();
        },
        getExchangeEquitiesValue() {
            const exchanges = this.$store.getters["getExchanges"];
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            return UiUtils.extractExchangeEquitiesValue(UiUtils.getExchangeCodeById(exchanges, this.selectedItemExchangeId), this.getExchangeCurrency, userWallet);
        },
        getExchangeEquitiesValueInBaseCurrency() {
            const exchanges = this.$store.getters["getExchanges"];
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            return UiUtils.extractExchangeEquitiesValue(UiUtils.getExchangeCodeById(exchanges, this.selectedItemExchangeId), this.getCurrency, userWallet);
        },        
        getExchangeEquitiesValueInExchangeCurrency() {
            const exchanges = this.$store.getters["getExchanges"];
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let ex = UiUtils.getExchangeCodeById(exchanges, this.selectedItemExchangeId);
            return UiUtils.extractExchangeEquitiesValue(ex, this.getExchangeCurrency, userWallet);
        },        
        getExchangeAverageEquitiesValue() {
            const exchanges = this.$store.getters["getExchanges"];
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            return UiUtils.extractExchangeEquitiesAverageValue(UiUtils.getExchangeCodeById(exchanges, this.selectedItemExchangeId), this.getExchangeCurrency, userWallet);
        },        
        totalAssetsValue() {
            const currency = this.getCurrency;
            const userWallet = this.$store.getters["getWallet"];
            return UiUtils.getTotalMoney(userWallet, currency);
        },
        totalBalance() {
            return (parseFloat(this.getAllExchangesEquitiesAsMoneyDefaultCurrency) + parseFloat(this.totalAssetsValue));
        },
        getExchangePositionsCount() {
            const exchanges = this.$store.getters["getExchanges"];
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let ex = UiUtils.getExchangeCodeById(exchanges, this.selectedItemExchangeId);

            return UiUtils.extractExchangePositionsCount(ex, userWallet);
        },          
        getExchangeSharesCount() {
            const exchanges = this.$store.getters["getExchanges"];
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            let ex = UiUtils.getExchangeCodeById(exchanges, this.selectedItemExchangeId);

            return UiUtils.extractExchangeEquitiesCount(ex, userWallet);
        },        
        getAllExchangesEquitiesAsMoneyDefaultCurrency() {
            const userWallet = this.$store.getters["getWallet"];
            if (!userWallet) {
                return 0;
            }
            return parseFloat(UiUtils.extractExchangeEquitiesValue('xbul', this.getCurrency, userWallet)) +
                parseFloat(UiUtils.extractExchangeEquitiesValue('xetr', this.getCurrency, userWallet)) +
                parseFloat(UiUtils.extractExchangeEquitiesValue('xfra', this.getCurrency, userWallet));
        },
        getAvailableXbulStocks() {
            const wallet = this.$store.getters["getWallet"];
            let selectedExchanges = [];
            wallet?.s?.forEach(stock => {
                if (0 === 'xbul'.localeCompare(stock.ex.toString().toLowerCase())) {
                    selectedExchanges.push(stock);
                }
            });
            return selectedExchanges;
        },
        getAvailableXetrStocks() {
            const wallet = this.$store.getters["getWallet"];
            let selectedExchanges = [];
            wallet?.s?.forEach(stock => {
                if (0 === 'xetr'.localeCompare(stock.ex.toString().toLowerCase())) {
                    selectedExchanges.push(stock);
                }
            });
            return selectedExchanges;
        },
        getAvailableXfraStocks() {
            const wallet = this.$store.getters["getWallet"];
            const exchanges = this.$store.getters["getExchanges"];
            let selectedExchanges = [];
            wallet?.s?.forEach(stock => {
                if (0 === 'xfra'.localeCompare(stock.ex.toString().toLowerCase())) {
                    selectedExchanges.push(stock);
                }
            });
            return selectedExchanges;
        },        
        getExchangeAvailableStocks() {
            switch (this.selectedItemExchangeId) {
                case 1: return this.getAvailableXbulStocks;
                case 2: return this.getAvailableXetrStocks;
                case 3: return this.getAvailableXfraStocks;
            }

            return [];
        },
        getFilteredTrades() {
            const exchanges = this.$store.getters["getExchanges"];
            let currentExchange = exchanges.filter(exchange => {
                    return exchange.eID === this.selectedItemExchangeId;
            });
            const wallet = this.$store.getters["getWallet"];
            const tradesToReturn = wallet?.s?.filter(item => {
                return 0 === item.sc.toString().toLowerCase().localeCompare(this.selectedItem?.sc?.toString().toLowerCase())                
                    && 0 === currentExchange[0]?.code.toString().toLowerCase().localeCompare(item.ex.toString().toLowerCase());
            });
            if (!tradesToReturn) {
                return [];
            }
            // create transaction type entries
            /* 
            * "sc": "CHIM","am": 25.0,"ap": 0.7122,"mp": 0.715,"cc": "BGN","xc": "XBUL","nEN": "CHIMIMPORT JSC","nBG": "Химимпорт АД","stid": 1,"ts": 1713790645,"qty": 10.0,"sp": 0.695,"pt": 6.95,"tt": "b","ot": 1,"oid": "342853"
            */
            let remapped = [];
            tradesToReturn.forEach(item => {
                item.t?.forEach(transaction => {
                    (async () => await this.getOrderById(transaction.oid))();
                    remapped.push({
                        "sc": item.sc,
                        "am": item.am,
                        "ap": item.ap,
                        "mp": item.mp,
                        "cc": item.cc,
                        "xc": item.ex,
                        "nEN": item.nEN,
                        "nBG": item.nBG,
                        "stid": item.stid,
                        "ts": transaction.ts,
                        "qty": transaction.qty,
                        "sp": transaction.sp,
                        "pt": transaction.pt,
                        "tt": transaction.tt,
                        "ot": transaction.ot,
                        "oid": transaction.oid,
                        "fee": transaction.fee || 0
                    });
                });
            });

            return remapped;
        },
        getExchangeName() {
            const exchanges = this.$store.getters["getExchanges"];
            let currentExchange = exchanges.filter(exchange => {
                return exchange.eID === this.selectedItemExchangeId;
            });

            return currentExchange ? currentExchange[0].code : "";
        },
        getPositionsLabel() {
            return Translations.getPositionsLabel(this.getUserLanguage);
        },
        getBaseCurrencyLabel() {
            return Translations.getBaseCurrencyLabel(this.getUserLanguage);
        },
        getMyPositionsLabel() {
            return Translations.getMyPositionsLabel(this.getUserLanguage);
        },
        getPerformanceLabel() {
            return Translations.getPerformanceLabel(this.getUserLanguage);
        },
        getPositionsBalanceLabel() {
            return Translations.getPositionsBalanceLabel(this.getUserLanguage);
        },
        getTotalBalanceLabel() {
            return Translations.getTotalBalanceLabel(this.getUserLanguage);
        },
        getStockCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage);
        },
        getStockNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage);
        },
        getCurrencyLabel() {
            return Translations.getCurrencyLabel(this.getUserLanguage);
        },
        getEquitiesLabel() {
            return Translations.getEquitiesLabel(this.getUserLanguage);
        },
        getBondsLabel() {
            return Translations.getBondsLabel(this.getUserLanguage);
        },
        getCompInstrumentsLabel() {
            return Translations.getCompInstrumentsLabel(this.getUserLanguage);
        },
        getFundsLabel() {
            return Translations.getFundsLabel(this.getUserLanguage);
        },
        getReitsLabel() {
            return Translations.getReitsLabel(this.getUserLanguage);
        },
        getRightsLabel() {
            return Translations.getRightsLabel(this.getUserLanguage);
        },
        getCfdLabel() {
            return Translations.getCfdLabel(this.getUserLanguage);
        },
        getMarginLabel() {
            return Translations.getMarginLabel(this.getUserLanguage);
        },
        getNettingLabel() {
            return Translations.getNettingLabel(this.getUserLanguage);
        },
        getRepoLabel() {
            return Translations.getRepoLabel(this.getUserLanguage);
        }, 
        getTypeLabel() {
            return Translations.getTypeLabel(this.getUserLanguage);
        }, 
        getPurchasePriceLabel() {
            return Translations.getPurchasePriceLabel(this.getUserLanguage);
        }, 
        getAmountLabel() {
            return Translations.getAmountLabel(this.getUserLanguage);
        }, 
        getPurchasedAmountLabel() {
            return Translations.getPurchasedAmountLabel(this.getUserLanguage);
        },
        getCommissionLabelLabel() {
            return Translations.getCommissionLabelLabel(this.getUserLanguage);
        }, 
        getTotalAmountLabel() {
            return Translations.getTotalAmountLabel(this.getUserLanguage);
        }, 
        getAveragePriceLabel() {
            return Translations.getAveragePriceLabel(this.getUserLanguage);
        }, 
        getMarketPriceLabel() {
            return Translations.getMarketPriceLabel(this.getUserLanguage);
        },
        getValueLabel() {
            return Translations.getValueLabel(this.getUserLanguage);
        },
        getTradesLabel() {
            return Translations.getTradesLabel(this.getUserLanguage);
        },
        getOrderIdLabel() {
            return Translations.getOrderLabel(this.getUserLanguage);
        },
        getOrderTypeLabel() {
            return Translations.getOrderTypeLabel(this.getUserLanguage);
        },
        getDateLabel() {
            return Translations.getDateLabel(this.getUserLanguage);
        },
        getTimestampLabel() {
            return Translations.getTimestampLabel(this.getUserLanguage);
        },
        getQuantityLabel() {
            return Translations.getQuantityLabel(this.getUserLanguage);
        },
        getSumLabel() {
            return Translations.getSumLabel(this.getUserLanguage);
        },
        getPriceLabel() {
            return Translations.getPriceLabel(this.getUserLanguage);
        },
        getPriceТotalLabel() {
            return Translations.getPriceТotalLabel(this.getUserLanguage);
        },
        getFeeLabel() {
            return Translations.getFeeLabel(this.getUserLanguage);
        },
        getCouldNotReadTradesMessage() {
            return Translations.getCouldNotReadTradesMessage(this.getUserLanguage);
        },
        getCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage);
        },
        getNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage);
        }, 
        getExchangeLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage);
        }, 
        getInformationLabel() {
            return Translations.getInformationLabel(this.getUserLanguage);
        },
        getPortfolioLabel() {
            return Translations.getPortfolioLabel(this.getUserLanguage);
        },
        getMyNumberOfInstrumentsLabel() {
            return Translations.getMyNumberOfInstrumentsLabel(this.getUserLanguage);
        },
        getReturnLabel() {
            return Translations.getReturnLabel(this.getUserLanguage);
        },
    }
}
</script>

<style scoped>
    th {
        text-align: left
    }
  .equity_align_right {
    vertical-align: middle;
    text-align: right;
  }

  .center_verticaly {
    vertical-align: middle;
  }

  .equity_align_right_heavy {
    vertical-align: middle;
    text-align: right;
    font-weight: bold;
  }

  .greenColor {
    color: #74b21f;
  }

  .redColor {
    color: #ff0000;
  }

  .card-img-top {
      width: 100%;
      height: 5vw;
      object-fit: scale-down;
  }

    .prop-value {
        text-align: right;
        font-weight: bold;
    } 
    
  .xsmall-font-size {
    font-size: x-small;
  }

    .card-img-top-responsive {
      width: 100%;
      height: 15vw;
      object-fit: scale-down;
  }  
</style>