<template>
    <div class="hollow-dots-spinner center">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </div>
</template>

<style scoped>

.hollow-dots-spinner,
.hollow-dots-spinner * {
    box-sizing: border-box;
}

.hollow-dots-spinner {
    height: 5px;
    width: calc(10px * 3);
}

.hollow-dots-spinner .dot {
    width: 5px;
    height: 5px;
    margin: 0 calc(5px / 2);
    border: calc(5px / 5) solid #0D6EFD;
    border-radius: 50%;
    float: left;
    transform: scale(0);
    animation: hollow-dots-spinner-animation 1000ms ease infinite 0ms;
}

.hollow-dots-spinner .dot:nth-child(1) {
    animation-delay: calc(50ms * 1);
}

.hollow-dots-spinner .dot:nth-child(2) {
    animation-delay: calc(50ms * 2);
}

.hollow-dots-spinner .dot:nth-child(3) {
    animation-delay: calc(50ms * 3);

}

@keyframes hollow-dots-spinner-animation {
    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
</style>