/* eslint-disable */
<template>
  <div class="vh-100 d-flex justify-content-center">
    <div class="form-access my-auto">
      <div id="logo_holder" class="mt-2 mb-5">
        <img id="efocs_logo" src="@/assets/images/EFOCS_Logo_Horizontal.svg" />
      </div>
      <div class="d-flex justify-content-center">
        <strong>{{ getWelcomeToEfocsLabel }}</strong>
      </div>
      <div class="mb-5 d-flex justify-content-center">
        {{ getLoginToYourAccountLabel }}
      </div>      
      <div class="btn-group d-flex mtb15" role="group">
        <input type="radio" class="btn-check w-100" id="loginreal" autocomplete="off" value="live" v-model="accountType">
        <label class="btn btn-outline-primary" for="loginreal">{{ getLiveLabel }}</label>
        <input type="radio" class="btn-check w-100" id="logindemo" value="demo" v-model="accountType" autocomplete="off">
        <label class="btn btn-outline-primary" for="logindemo">{{ getDemoLabel }}</label>
      </div>
      <form>
        <span>{{ getLoginLabel }}</span>
        <div class="form-group">
          <input v-model="name" type="email" class="form-control" :placeholder="getUsernamePlaceholder" required />
        </div>
        <div class="form-group">
          <input v-model="password" type="password" class="form-control" :placeholder="getPasswordLabel" required />
        </div>
        <div class="text-end">
          <router-link v-if="'demo' === accountType" to="/reset">{{ getForgotPasswordLabel }}</router-link>
          <div v-else>&nbsp;</div>
        </div>
        <div v-if="'demo' === accountType" class="custom-control form-check">
          <input type="checkbox" class="form-check-input" id="form-checkbox" v-model="shouldRememberDemo" />
          <label class="form-check-label" htmlFor="form-checkbox">
            {{ getRememberMeLabel }}
          </label>
        </div>
        <div v-else class="custom-control form-check">
          <input type="checkbox" class="form-check-input" id="form-checkbox" v-model="shouldRememberLive" />
          <label class="form-check-label" htmlFor="form-checkbox">
            {{ getRememberMeLabel }}
          </label>
        </div>
        <button type="submit" class="btn"
          :class="{ 'btn-warning': 'live' === accountType, 'btn-primary': 'demo' === accountType}"
          :disabled='!submitEnabled && !blockLogin' @click.prevent="doLogin()">{{ getLoginLabel }}</button>
      </form>
      <h2 v-if="'demo' === accountType">
        {{ getDontHaveDemoAccountLabel }}
        <router-link to="/sign-up">{{ getCreateDemoLabel }}</router-link>
      </h2>
      <h2 v-else>
        {{ getDontHaveAccountLabel }}
        <a href="https://my.eurofinance.bg/" target="_blanc">{{ getCreateAccountLabel }}</a>
      </h2>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import http from "@/axios/http-common";
import Translations from "@/lang/translations.js";

/**
 * this method identifies unique stocks in list with O(n) complexity (fastest possible)
 * taken from https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
 */
function onlyUnique(array) {
  var unique = [];
  var distinct = [];
  for (let i = 0; i < array.length; i++) {
    if (!unique[array[i].cMIC + array[i].code]) {
      distinct.push(array[i]);
      unique[array[i].cMIC + array[i].code] = 1;
    }
  }

  return distinct;
}

export default {
  name: 'Login',
  data() {
    return {
      shouldRememberDemo: this.$store.getters["getRememberMeDemo"],
      shouldRememberLive: this.$store.getters["getRememberMeLive"],
      name: '',
      password: '',
      accountType: this.$store.getters["getLastOpenedUserType"],
      blockLogin: false,
      currentLanguage: this.getUserLanguage,
      mess: this.message,
      mType: parseInt(this.messageType),
      versionVerifier: null,
      webAppVersion: 1728403554
    }
  },
  props: {
    message: {
      required: false,
      type: String
    },
    messageType: {
      required: false,
      type: String
    }
  },  
  watch: {
    getUserLanguage: function (newVal) {
      this.currentLanguage = newVal;
    },
    accountType: function (newVal) {
      this.setStoredUserNameAndPassword(newVal);
    }
  },  
  methods: {
    setStoredUserNameAndPassword(newVal) {
      if (0 === "demo".localeCompare(newVal)) {
        this.name = this.$store.getters["getRememberMeDemo"]
          ? this.$store.getters["getRememberedUsernameDemo"]
          : '';
        this.password = this.$store.getters["getRememberMeDemo"]
          ? this.$store.getters["getRememberedPasswordDemo"]
          : '';
      } else {
        this.name = this.$store.getters["getRememberMeLive"]
          ? this.$store.getters["getRememberedUsernameLive"]
          : '';
        this.password = this.$store.getters["getRememberMeLive"]
          ? this.$store.getters["getRememberedPasswordLive"]
          : '';
      }      
    },
    async doLogin() {
      // block login button
      this.blockLogin = true;
      // store if needed
      if (0 === "demo".localeCompare(this.accountType)) {
        this.$store.commit("setRememberMeDemo", this.shouldRememberDemo);
        if (this.shouldRememberDemo) {
          this.$store.commit("setRememberedUsernameDemo", this.name);
          this.$store.commit("setRememberedPasswordDemo", this.password);
        }
      } else {
        this.$store.commit("setRememberMeLive", this.shouldRememberLive);
        if (this.shouldRememberLive) {
          this.$store.commit("setRememberedUsernameLive", this.name);
          this.$store.commit("setRememberedPasswordLive", this.password);
        }
        // store user and password for relogin when needed
        this.$store.commit("setReloginUsernameLive", this.name);
        this.$store.commit("setReloginPasswordLive", this.password);
      }
      let payload = {
        api: http,
        username: this.name,
        password: this.password,
        demo: 0 === "demo".localeCompare(this.accountType)
      };
      try {
        this.$store.commit("setAppStateNotReady");
        const loginResult = await this.$store.dispatch("loginUser", payload);
        switch (loginResult) {
          case 0: {
            // set last login type
            this.$store.commit("setLastOpenedUserType", this.accountType);
            // do not load anything until all data is collected in dashboard vue
            this.$router.push({ name: "Dashboard" });
            break;
          }
          default: {
            this.blockLogin = false;

            this.$toast.error(this.getCouldNotLoginMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("setAppStateReady");
            break;
          }
        }
      } catch(error) {
        this.blockLogin = false;
        console.error(error);
        this.$store.commit("setAppStateReady");
      }
    },
    getUserType() {
      return this.$store.getters["getUserType"];
    },  
  },
  computed: {
    submitEnabled() {
      return 0 < this.name.length && 0 < this.password.length;
    },
    getUserLanguage() {
      return this.$store.getters["getNonLoggedInLanguage"] || "EN";
    },
    getCouldNotLoginMessage() {
      return 0 === "BG".localeCompare(this.currentLanguage) ?
        "Неуспешен вход. Моля, провери идентификационните си данни." :
        "Login failed. Please check your credentials.";
    },
    getDemoLabel() {
      return Translations.getDemoLabel(this.currentLanguage);
    },
    getLiveLabel() {
      return Translations.getLiveLabel(this.currentLanguage);
    }, 
    getUsernamePlaceholder() {
      return Translations.getUsernamePlaceholder(this.currentLanguage);
    }, 
    getPasswordLabel() {
      return Translations.getPasswordLabel(this.currentLanguage);
    }, 
    getForgotPasswordLabel() {
      return Translations.getForgotPasswordLabel(this.currentLanguage);
    },
    getLoginLabel() {
      return Translations.getLoginLabel(this.currentLanguage);
    },
    getCreateDemoLabel() {
      return Translations.getCreateDemoLabel(this.currentLanguage);
    }, 
    getCreateAccountLabel() {
      return Translations.getCreateAccountLabel(this.currentLanguage);
    },    
    getDontHaveDemoAccountLabel() {
      return Translations.getDontHaveDemoAccountLabel(this.currentLanguage);
    }, 
    getDontHaveAccountLabel() {
      return Translations.getDontHaveAccountLabel(this.currentLanguage);
    }, 
    getRememberMeLabel() {
      return Translations.getRememberMeLabel(this.currentLanguage);
    },
    getLoginToYourAccountLabel() {
      return Translations.getLoginToYourAccountLabel(this.currentLanguage);
    },
    getWelcomeToEfocsLabel() {
      return Translations.getWelcomeToEfocsLabel(this.currentLanguage);
    }
  },
  async mounted() {
    this.setStoredUserNameAndPassword(this.accountType);
    // reset store to avoid users access to other user's data
    this.$store.commit("resetStoreState", null);
    // redirect
    this.$store.commit("setUser", null);
    // default lang if not set
    this.currentLanguage = this.getUserLanguage;
    // show message if any
    setTimeout(() => {
      if (this.message) {
        if (0 === this.messageType) {
          this.$toast.success(this.message, {
            "duration": 5000,
            "pauseOnHover": true
          });
        } else {
          this.$toast.error(this.message, {
            "duration": 5000,
            "pauseOnHover": true
          });
        }
      }      
    }, 500);
    // run version update checker
    this.versionVerifier = window.setInterval(async function () {
      try {
        const res = await axios.get("https://my.efocs.app/ping");
        if (res) {
          if (0 === res.data.ec) {
            // check version
            if (this.webAppVersion !== res.data.ver) {
              // reload
              location.reload();
            }
          }
        }
      } catch (e) {
        console.error(e);
      }        
    }.bind(this), 5000);      
  },
  unmounted() {
    // stop verifier
    if (this.versionVerifier) {
      clearInterval(this.versionVerifier);
    }
  }
}
</script>
<style scoped>
  #efocs_logo {
    width: 50%; 
    height: auto;    
  }

  #logo_holder {
    text-align: center;
  }
</style>
