<template>
    <apexchart type="area" :options="options" :series="series" :width="width" :height="height" />
</template>

<script>
import UiUtils from "@/assets/js/ui_utils.js";

export default {
    name: "GAreaChart",
    props: {
        data: {
            required: true,
            type: Array
        },
        width: {
            type: Number,
            default: 600
        },
        height: {
            type: Number,
            default: 480
        },
        lbls: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            series: this.data,
            options: {
                xaxis: {
                    type: 'datetime',
                    tickAmount: 6,
                },                
                chart: {
                    type: 'area',
                    width: "100%",
                    height: 350,
                    zoom: {
                        autoScaleYaxis: true
                    },
                    animations: {
                        initialAnimation: {
                            enabled: true
                        }
                    },
                    toolbar: {
                        show: false,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: false,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: false
                        }
                    }
                },
                legend: {
                    show: false
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return UiUtils.formatNumber(val);
                        },
                        title: {
                            formatter: function (seriesName) {
                                return '';
                            }
                        }
                    },
                    x: {
                        formatter: function (unix) {
                            return new Date(unix).toISOString().slice(0, 19).replace('T', ' ')
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                markers: {
                    size: 0,
                    style: 'hollow',
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 100]
                    }
                },
                stroke: {
                    curve: 'straight',
                },
            },
        }
    }
}
</script>