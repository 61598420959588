<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="row sm-gutters">
            <!-- Menu -->
            <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
                <Menu></Menu>
            </div>
            <div class="col-sm-12" :class="{ 'col-md-10': 1200 < getWindowsWidth }">
                <!-- header -->
                <div class="row">
                    <div class="col-sm-6">
                        <!-- back button -->
                        <button class="btn btn btn-outline-secondary btn-light mb-2"
                            :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                            @click.prevent="goBack()">&larr;</button>
                    </div>
                    <div class="col-sm-6">
                        <!-- view header -->
                        <h5 v-if="1200 < getWindowsWidth">{{ getOrdersLabel }} - #{{ order?.order_id }}</h5>
                        <h6 v-else>{{ getOrdersLabel }} - #{{ order?.order_id }}</h6>
                    </div>
                </div>
                <!-- order details -->
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTimestampLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getDate(order?.acquired) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getOrderIdLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">#{{
                                    order?.order_id }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getTypeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getOrderType(order?.action) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getOrderTypeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    order?.type }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getExchangeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    order?.xcode }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCodeLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    order?.code }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getNameLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'BG'.localeCompare(getUserLanguage)">{{ order?.nBG ?
                                    order?.nBG : order?.nEN }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="0 === 'EN'.localeCompare(getUserLanguage)">{{ order?.nEN }}
                                </td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getQuantityLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    order?.amount }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getCurrencyLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    order?.cc }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getPriceLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-if="2 != order?.stid">{{
                                    formatPrice(order?.price, order?.cc, 2) }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }"
                                    v-else>{{
                                    formatBondsPrice(order?.price, 2) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getEstimatedAmountLabel
                                    }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getEstimatedAmountAsMoney(), order?.cc, 2) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getEstimatedCommissionLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(order?.fee, order?.cc, 2) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getTotalEstimatedAmountLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    formatPrice(getEstimatedTotalAsMoney(), order?.cc, 2) }}</td>
                            </tr>
                            <tr>
                                <td :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{ getStatusLabel }}</td>
                                <td class="prop-value" :class="{ 'xsmall-font-size': 1200 > getWindowsWidth }">{{
                                    getOrderStatus(order) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Menu from '../components/Menu.vue';
import Translations from "@/lang/translations.js";
import UiUtils from "@/assets/js/ui_utils.js";

export default {
    components: {
        Menu
    },
    data() {
        return {
            order: JSON.parse(this.orderDetails)
        }
    },
    props: {
        orderDetails: {
            required: true,
            type: String,
            default: null
        }
    },
    methods: {
        getDate(unix) {
            return UiUtils.parseUnixTime(unix);
        },
        getOrderType(ot) {
            return 'b' === ot || 'B' === ot ?
                Translations.getBuyLabel(this.getUserLanguage) :
                Translations.getSellLabel(this.getUserLanguage);
        },
        parseNumber(num) {
            return UiUtils.formatNumber(num);
        },
        goBack() {
            this.$router.push({ name: "Wallet", params: { latestActiveMenuSelection: "Orders" } });
        },
        getOrderStatus(order) {
            if (!order) {
                return "N/A";
            }
            switch (order.status) {
                case 1:
                case 2:
                case 12:
                case 99: {
                    return Translations.getProcessingLabel(this.getUserLanguage);
                }
                case 3: {
                    return Translations.getPlacedLabel(this.getUserLanguage);
                }
                case 4: 
                case 5: {
                    return Translations.getCancelledLabel(this.getUserLanguage);
                }
                case 6: 
                case 7: {
                    return Translations.getFilledLabel(this.getUserLanguage);
                }
            }
            return "N/A";
        },
        formatBondsPrice(val, autoDigits = null) {
            return UiUtils.formatPrice(val, '%', this.getUserLanguage, autoDigits);
        },
        formatPrice(val, curr, autoDigits = null) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage, autoDigits);
        },
        getEstimatedAmountAsMoney() {
            return 2 !== parseInt(this.order?.stid || 1)
                ? parseFloat(this.order?.price || 0) * (this.order?.amount || 0)
                : parseFloat(this.order?.price || 0) * (this.order?.amount || 0) / 100;
        },
        getEstimatedTotalAsMoney() {
            let estimatedTotal = this.getEstimatedAmountAsMoney();

            return 0 === this.order?.action.toString().toUpperCase().localeCompare('B') 
                ? parseFloat(estimatedTotal) + parseFloat(this.order?.fee || 0)
                : parseFloat(estimatedTotal) - parseFloat(this.order?.fee || 0);
        },        
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },           
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getOrdersLabel() {
            return Translations.getOrdersLabel(this.getUserLanguage);
        },
        getTimestampLabel() {
            return Translations.getTimestampLabel(this.getUserLanguage);
        },
        getCurrencyLabel() {
            return Translations.getCurrencyLabel(this.getUserLanguage);
        },
        getQuantityLabel() {
            return Translations.getQuantityLabel(this.getUserLanguage);
        },
        getEstimatedTotalLabel() {
            return Translations.getEstimatedTotalLabel(this.getUserLanguage);
        },
        getTotalEstimatedAmountLabel() {
            return Translations.getTotalEstimatedAmountLabel(this.getUserLanguage);
        },
        getOrderIdLabel() {
            return Translations.getOrderLabel(this.getUserLanguage);
        },
        getTypeLabel() {
            return Translations.getTypeLabel(this.getUserLanguage);
        },
        getPriceLabel() {
            return Translations.getPriceLabel(this.getUserLanguage);
        },
        getEstimatedCommissionLabel() {
            return Translations.getEstimatedCommissionLabel(this.getUserLanguage);
        },
        getExchangeLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage);
        },
        getCodeLabel() {
            return Translations.getCodeLabel(this.getUserLanguage);
        }, 
        getNameLabel() {
            return Translations.getNameLabel(this.getUserLanguage);
        }, 
        getOrderTypeLabel() {
            return Translations.getOrderTypeLabel(this.getUserLanguage);
        }, 
        getStatusLabel() {
            return Translations.getStatusLabel(this.getUserLanguage);
        },
        getEstimatedAmountLabel() {
            return Translations.getEstimatedAmountLabel(this.getUserLanguage);
        },
    }
}
</script>

<style scoped>
.prop-value {
    text-align: right;
    font-weight: bold;
}

.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
}   
</style>