import { createStore } from "vuex";
import { uuid } from "vue-uuid";

const store = createStore({
  state: {
    /* current light/dark template them */
    theme: true,
    /* indicates data is being loaded and app should display a spinner */
    appReady: true,
    /* logged user data */
    user: { ec: -1, dexp: "", id: 0, key: "", act: -1, wls: [], lang: "", esdbv: 0 },
    /* logged user type */
    userType: 0,
    /* quotes data */
    quotes: Array<any>(),
    /* quote trading data */
    quoteTr: {},
    /* quote extended data */
    quoteEx: {},
    /* quote depth data */
    depth: {},
    /* exchanges data */
    exchanges: Array<any>(),
    /* exchanges phases */
    exchangesPhases: Array<any>(),
    /* stocks data */
    stocks: Array<any>(),
    /* indexed stocks by stock code and exchange code for fast access */
    indexedStocks: Array<any>(),
    /* user wallet data */
    wallet: null,
    /* gainers list */
    gainers: Array<any>(),
    /* losers list */
    losers: Array<any>(),
    /* most traded list */
    mostTraded: Array<any>(),
    /* indexes list */
    indexes: Array<any>(),
    /* user preferred currency */
    currency: "BGN",
    /* searched stocks data in user watchlist */
    searchedStocksUserWatchlist: Array<any>(),
    /* searched stocks data from header search item */
    searchedStocks: Array<any>(),
    /* searched stocks data in undex watchlist */
    searchedStocksIndexesWatchlist: Array<any>(),
    /* selected stock code for trading */
    selectedTradingCode: "",
    /* selected stock code for trading segment */
    selectedTradingCodeSegment: "",
    /* selected stock exchange for trading */
    selectedTradingExchangeId: 0,
    /* selected stock for trading share type id */
    selectedTradingCodeShareTypeId: 0,
    /* selected stock name for trading */
    selectedTradingStockName: "",
    /* selected trading action: B(uy)/S(ell) */
    selectedTradingAction: "B",
    /* selected trading quantity from depth to trading */
    selectedTradingQuantity: 0,
    /* selected trading price from depth to trading */
    selectedTradingPrice: 0,
    /* system messages */
    systemMessages: Array<any>(),
    /* indexes as watchlists */
    indexesWatchlists: Array<any>(),
    /* selected exchange code, 0 for all exchanges */
    selectedExchangeCode: 0,
    /* order id user wants to update */
    orderIdToUpdate: "",
    /* demo username to remember in login form */
    rememberedUsernameDemo: "",
    /* demo password to remember in login form */
    rememberedPasswordDemo: "",
    /* live username to remember in login form */
    rememberedUsernameLive: "",
    /* live password to remember in login form */
    rememberedPasswordLive: "",
    /* live username to remember for silent relogin */
    reloginUsernameLive: "",
    /* live password to remember for silent relogin */
    reloginPasswordLive: "",
    /* last user type to login */
    lastOpenLoginUserType: "live",
    /* keep demo user credentials set */
    rememberMeSetDemo: false,
    /* keep live user credentials set */
    rememberMeSetLive: false,
    /* non-logged in language */
    nonLoggedInLanguage: "EN",
    hasItemInSearch: false,
    watchlistSortingOption: 0,
    /* the width of the windows the app is working in */
    appWindowWidth: 0,
    /* set when user selects an order from wallet to update and resend */
    orderToEdit: null,
    /* watchlist/index name clicked by user */
    clickedWatchlistName: null,
    /* known exchanges indexes names */
    knownExchangeIndexes: ["SOFIX", "BGREIT", "BGBX40", "BGTR30", "DAX", "MDAX", "SDAX", "TECDAX"],
    /* stop sse on silent relogin */
    stopSse: false
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    isUserLogged(state) {
      return 0 === state.user?.ec;
    },
    getNonLoggedInLanguage(state) {
      return state.nonLoggedInLanguage;
    },
    getUserWatchlists(state) {
      return !state.user || !state.user?.wls ?
        [] :
        state.user.wls;
    },
    isAppReady(state) {
      return state.appReady;
    },
    getKnownExchangeIndexes(state) {
      return state.knownExchangeIndexes;
    },
    getClickedWatchlistName(state) {
      return state.clickedWatchlistName;
    },
    getStopSse(state) {
      return state.stopSse;
    },
    getQuotes(state) {
      return state.quotes;
    },
    getQuoteTrading(state) {
      return state.quoteTr;
    },
    getQuoteExtended(state) {
      return state.quoteEx;
    },
    getDepth(state) {
      return state.depth;
    },
    getStocks(state) {
      return state.stocks;
    },
    getSearchedStocksUserWatchlist(state) {
      return state.searchedStocksUserWatchlist;
    },
    getSearchedStocks(state) {
      return state.searchedStocks;
    },
    getSearchedStocksIndexWatchlist(state) {
      return state.searchedStocksIndexesWatchlist;
    },
    getExchanges(state) {
      return state.exchanges;
    },
    getExchangesPhases(state) {
      return state.exchangesPhases;
    },
    getWallet(state) {
      return state.wallet;
    },
    getGainers(state) {
      return state.gainers;
    },
    getLosers(state) {
      return state.losers;
    },
    getMostTraded(state) {
      return state.mostTraded;
    },
    getIndexes(state) {
      return state.indexes;
    },
    getCurrency(state) {
      return state.currency;
    },
    getSelectedTradingCode(state) {
      return state.selectedTradingCode;
    },
    getSelectedTradingCodeSegment(state) {
      return state.selectedTradingCodeSegment;
    },
    getSelectedTradingCodeShareTypeId(state) {
      return state.selectedTradingCodeShareTypeId;
    },
    getSelectedTradingExchangeId(state) {
      return state.selectedTradingExchangeId;
    },
    getSelectedTradingStockName(state) {
      return state.selectedTradingStockName;
    },
    getSelectedTradingAction(state) {
      return state.selectedTradingAction;
    },
    getSystemMessages(state) {
      return state.systemMessages;
    },
    getSelectedTradingQuantity(state) {
      return state.selectedTradingQuantity;
    },
    getSelectedTradingPrice(state) {
      return state.selectedTradingPrice;
    },
    getIndexedStocks(state) {
      return state.indexedStocks;
    },
    getUserType(state) {
      return state.userType;
    },
    getIndexesWatchlists(state) {
      return state.indexesWatchlists;
    },
    getSelectedExchangeCode(state) {
      return state.selectedExchangeCode;
    },
    getOrderIdToUpdate(state) {
      return state.orderIdToUpdate;
    },
    getRememberedUsernameDemo(state) {
      return state.rememberedUsernameDemo;
    },
    getRememberedPasswordDemo(state) {
      return state.rememberedPasswordDemo;
    },
    getRememberedUsernameLive(state) {
      return state.rememberedUsernameLive;
    },
    getRememberedPasswordLive(state) {
      return state.rememberedPasswordLive;
    },
    getReloginUsernameLive(state) {
      return state.reloginUsernameLive;
    },
    getReloginPasswordLive(state) {
      return state.reloginPasswordLive;
    },
    getLastOpenedUserType(state) {
      return state.lastOpenLoginUserType;
    },
    getRememberMeDemo(state) {
      return state.rememberMeSetDemo;
    },
    getRememberMeLive(state) {
      return state.rememberMeSetLive;
    },
    getHasItemInSearch(state) {
      return state.hasItemInSearch;
    },
    getWatchlistSortingOption(state) {
      return state.watchlistSortingOption;
    },
    getAppWindowWidth(state) {
      return state.appWindowWidth;
    },
    getOrderToEdit(state) {
      return state.orderToEdit;
    }
  },
  mutations: {
    resetStoreState(state) {
      state.user = { ec: -1, dexp: "", id: 0, key: "", act: -1, wls: [], lang: "", esdbv: 0 };
      state.quotes = Array<any>();
      state.quoteTr = {};
      state.quoteEx = {};
      state.depth = {};
      state.exchanges = Array<any>();
      state.exchangesPhases = Array<any>();
      state.stocks = Array<any>();
      state.indexedStocks = Array<any>();
      state.wallet = null;
      state.gainers = Array<any>();
      state.losers = Array<any>();
      state.mostTraded = Array<any>();
      state.indexes = Array<any>();
      state.searchedStocksUserWatchlist = Array<any>();
      state.searchedStocks = Array<any>();
      state.searchedStocksIndexesWatchlist = Array<any>();
      state.selectedTradingCode = "";
      state.selectedTradingCodeSegment = "";
      state.selectedTradingCodeShareTypeId = 0;
      state.selectedTradingExchangeId = 0;
      state.selectedTradingStockName = "";
      state.selectedTradingAction = "B";
      state.selectedTradingQuantity = 0;
      state.selectedTradingPrice = 0;
      state.systemMessages = Array<any>();
      state.indexesWatchlists = Array<any>();
      state.selectedExchangeCode = 0;
      state.orderIdToUpdate = "";
      state.watchlistSortingOption = 0;
      state.orderToEdit = null;
      state.reloginUsernameLive = '';
      state.reloginPasswordLive = '';
    },
    changeTheme(state) {
      if (state.theme) {
        state.theme = false;
      } else {
        state.theme = true;
      }
    },
    setAppStateReady(state) {
      state.appReady = true;
    },
    setAppStateNotReady(state) {
      state.appReady = false;
    },
    setUser(state, user) {
      state.user = user;
    },
    setNonLoggedInLanguage(state, lang) {
      state.nonLoggedInLanguage = lang;
    },
    setClickedWatchlistName(state, name) {
      state.clickedWatchlistName = name;
    },
    setStopSse(state, val) {
      state.stopSse = val;
    },
    setQuotes(state, quotes) {
      state.quotes = quotes;
    },
    updateWatchlists(state, wls) {
      if (wls) {
        state.user.wls = wls;
      }
    },
    setQuoteTrading(state, quoteTr) {
      if (quoteTr) {
        state.quoteTr = quoteTr;
      }
    },
    setQuoteExtended(state, quoteEx) {
      if (quoteEx) {
        state.quoteEx = quoteEx;
      }
    },
    setDepth(state, depth) {
      if (depth) {
        state.depth = depth;
      }
    },
    setExchangesPhases(state, phases) {
      if (phases) {
        state.exchangesPhases = phases;
      }
    },
    upsertExchangesPhases(state, phase) {
      if (!phase) {
        return;
      }
      const keys = Object.keys(phase);
      const currItem = state.exchangesPhases.filter(item => {
        return 0 === item.ex.toString().toLowerCase().localeCompare(keys[0].toString().toLowerCase());
      });
      if (!currItem || 0 === currItem.length) {
        // add
        state.exchangesPhases.push({ 'ex': keys[0], 'ph': phase[keys[0]] });
      } else {
        // update
        currItem[0]['ph'] = phase[keys[0]];
      }
    },
    updateQuote(state, quote: any) {
      // check value
      if (!quote || !quote.b || isNaN(quote?.b)) {
        return;
      }
      const item = state.quotes.filter(quoteItem => {
        return 0 === quoteItem.xc.toString().toLowerCase().localeCompare(quote.xc.toString().toLowerCase())
          && 0 === quoteItem.sc.toString().toLowerCase().localeCompare(quote.sc.toString().toLowerCase())
      });
      if (item && item[0]) {
        // update
        const update: any = item[0];
        // update values
        update.b = quote.b;
        update.a = quote.a;
        update.dif = quote.dif;
        update.ch = quote.ch;
        update.t = new Date().getTime() - quote.t;

        item[0] = update;
      } else {
        // insert new
        state.quotes.push(quote);
      }
    },
    setStocks(state, stocks) {
      if (stocks) {
        state.stocks = stocks;
        // indexed stocks
        const inxStocks: any = [];
        stocks.forEach((stock: any) => {
          // mic + code must be unique from server
          inxStocks.push(stock.cMIC + stock.code);
        });
        state.indexedStocks = inxStocks;
      }
    },
    setSearchedStocksUserWatchlist(state, stocks) {
      state.searchedStocksUserWatchlist = stocks;
    },
    setSearchedStocks(state, stocks) {
      state.searchedStocks = stocks;
    },
    setSearchedStocksIndexWatchlist(state, stocks) {
      state.searchedStocksIndexesWatchlist = stocks;
    },
    addSearchedStockUserWatchlist(state, payload) {
      if (!payload.reset) {
        state.searchedStocksUserWatchlist.push(payload.stock);
      } else {
        const newItems = [];
        if (null !== payload.stock) {
          newItems.push(payload.stock);
        }
        state.searchedStocksUserWatchlist = newItems;
      }
    },
    addSearchedStockIndexWatchlist(state, payload) {
      if (!payload.reset) {
        state.searchedStocksIndexesWatchlist.push(payload.stock);
      } else {
        const newItems = [];
        if (null !== payload.stock) {
          newItems.push(payload.stock);
        }
        state.searchedStocksIndexesWatchlist = newItems;
      }
    },
    addStock(state, stock) {
      if (stock) {
        state.stocks.push(stock);
        state.indexedStocks.push(stock.cMIC + stock.code);
      }
    },
    setExchanges(state, exchanges) {
      if (exchanges) {
        state.exchanges = exchanges;
      }
    },
    setWallet(state, wallet) {
      if (wallet) {
        state.wallet = wallet;
      }
    },
    setGainers(state, gainers) {
      state.gainers = gainers;
    },
    updateGainers(state, gainer) {
      const item: any = state.gainers.filter((it: any) => {
        return 0 === it.sc.toString().toLowerCase().localeCompare(gainer.sc.toString().toLowerCase())
      });
      if (item && 0 < item?.length) {
        const update: any = item[0];
        // update values
        update.dv = gainer.dv;
        update.ch = gainer.ch;
        update.lp = gainer.lp;

        item[0] = update;
      }
    },
    updateGainersList(state, gainers) {
      // check empty (comm break)
      if (!gainers.list || 0 === gainers?.list?.length) {
        return;
      }
      const newItems = Array<any>();

      // exchanges
      gainers.list.forEach((item: any) => {
        newItems.push({
          sc: item.sc,
          dv: item.dv,
          ch: item.ch,
          lp: item.lp,
          ex: item.ex
        });
      });
      state.gainers = newItems;
    },
    setLosers(state, losers) {
      if (losers) {
        state.losers = losers;
      }
    },
    updateLosers(state, loser) {
      const item: any = state.losers.filter((it: any) => {
        return 0 === it.sc.toString().toLowerCase().localeCompare(loser.sc.toString().toLowerCase())
      });
      if (item && 0 < item?.length) {
        const update: any = item[0];
        // update values
        update.dv = loser.dv;
        update.ch = loser.ch;
        update.lp = loser.lp;

        item[0] = update;
      }
    },
    updateLosersList(state, losers) {
      // check empty (comm break)
      if (!losers.list || 0 === losers?.list?.length) {
        return;
      }

      const newItems = Array<any>();
      // exchanges
      losers.list.forEach((item: any) => {
        newItems.push({
          sc: item.sc,
          dv: item.dv,
          ch: item.ch,
          lp: item.lp,
          ex: item.ex
        });
      });
      state.losers = newItems;
    },
    setMostTraded(state, mostTraded) {
      if (mostTraded) {
        state.mostTraded = mostTraded;
      }
    },
    updateMostTraded(state, mt) {
      const item: any = state.mostTraded.filter((it: any) => {
        return 0 === it.sc.toString().toLowerCase().localeCompare(mt.sc.toString().toLowerCase())
      });
      if (item && 0 < item?.length) {
        const update: any = item[0];
        // update values
        update.dv = mt.dv;
        update.ch = mt.ch;
        update.lp = mt.lp;

        item[0] = update;
      }
    },
    updateMostTradedList(state, mostTraded) {
      // check empty (comm break)
      if (!mostTraded.list || 0 === mostTraded?.list?.length) {
        return;
      }
      const newItems = Array<any>();
      // exchanges
      mostTraded.list.forEach((item: any) => {
        newItems.push({
          sc: item.sc,
          dv: item.dv,
          ch: item.ch,
          lp: item.lp,
          ex: item.ex
        });
      });
      state.mostTraded = newItems;
    },
    setIndexes(state, indexes) {
      // check empty (comm break)
      if (!indexes || 0 === indexes?.length) {
        return;
      }
      const newItems = Array<any>();
      indexes.forEach((item: any) => {
        newItems.push({
          sc: item.sc,
          val: item.val,
          dif: item.dif,
          ch: item.ch
        });
      });
      state.indexes = newItems;
    },
    updateIndex(state, index) {
      const item: any = state.indexes.filter((it: any) => {
        return 0 === it.sc.toString().toLowerCase().localeCompare(index.sc.toString().toLowerCase())
      });
      if (item && 0 < item?.length) {
        const update: any = item[0];
        // update values
        update.t = index.t;
        update.val = index.val;
        update.dif = index.dif;
        update.ch = index.ch;

        item[0] = update;
      } else {
        // add new item to index
        state.indexes.push(index);
      }
    },
    updateUserLanguage(state, lang) {
      if (null !== state.user) {
        state.user.lang = lang;
      }
    },
    setCurrency(state, curr) {
      state.currency = curr;
    },
    setSelectedTradingCode(state, code) {
      state.selectedTradingCode = code;
    },
    setSelectedTradingCodeSegment(state, code) {
      state.selectedTradingCodeSegment = code;
    },
    setSelectedTradingCodeShareTypeId(state, code) {
      state.selectedTradingCodeShareTypeId = code;
    },
    setSelectedTradingExchangeId(state, exchangeId) {
      state.selectedTradingExchangeId = exchangeId;
    },
    setSelectedTradingStockName(state, name) {
      state.selectedTradingStockName = name;
    },
    setSelectedTradingAction(state, action) {
      state.selectedTradingAction = action;
    },
    addSystemMessage(state, message) {
      state.systemMessages.push(message);
    },
    resetSystemMessages(state) {
      state.systemMessages = [];
    },
    setSelectedTradingQuantity(state, qty) {
      state.selectedTradingQuantity = qty;
    },
    setSelectedTradingPrice(state, prc) {
      state.selectedTradingPrice = prc;
    },
    setUserType(state, type) {
      /* 0: demo, 1: real */
      state.userType = type;
    },
    setIndexesWatchlists(state, arr) {
      state.indexesWatchlists = arr;
    },
    addIndexesWatchlists(state, payload) {
      const sameItems = state.indexesWatchlists;
      // set sort option
      payload.arr.ord = payload.index;
      sameItems.push(payload.arr);
      state.indexesWatchlists = sameItems.sort((a, b) => a.ord > b.ord ? 1 : -1);
    },
    setSelectedExchangeCode(state, code) {
      state.selectedExchangeCode = code;
    },
    setOrderIdToUpdate(state, oid) {
      state.orderIdToUpdate = oid;
    },
    setRememberedUsernameDemo(state, username) {
      state.rememberedUsernameDemo = username;
    },
    setRememberedPasswordDemo(state, password) {
      state.rememberedPasswordDemo = password;
    },
    setRememberedUsernameLive(state, username) {
      state.rememberedUsernameLive = username;
    },
    setRememberedPasswordLive(state, password) {
      state.rememberedPasswordLive = password;
    },
    setReloginUsernameLive(state, username) {
      state.reloginUsernameLive = username;
    },
    setReloginPasswordLive(state, password) {
      state.reloginPasswordLive = password;
    },
    setLastOpenedUserType(state, type) {
      state.lastOpenLoginUserType = type;
    },
    setRememberMeDemo(state, doRemember) {
      state.rememberMeSetDemo = doRemember;
    },
    setRememberMeLive(state, doRemember) {
      state.rememberMeSetLive = doRemember;
    },
    setHasItemInSearch(state, val) {
      state.hasItemInSearch = val;
    },
    setWatchlistSortingOption(state, val) {
      state.watchlistSortingOption = val
    },
    setAppWindowWidth(state, val) {
      state.appWindowWidth = val;
    },
    setOrderToEdit(state, val) {
      state.orderToEdit = val;
    }
  },
  actions: {
    async loginUser({ commit }, payload): Promise<any> {
      const loginFailedError = -1;
      const incorrectCharsetError = -2;
      // use post
      let usrAndPass = payload.username + ':' + payload.password;
      try {
        usrAndPass = btoa(usrAndPass);
        // swap case
        let swappedString = '';
        let i = 0;
        while (i < usrAndPass.length) {
          let n = usrAndPass.charAt(i);
          n = n == n.toUpperCase()
            ? n.toLowerCase()
            : n.toUpperCase();
          swappedString += n;
          i++;
        }
        usrAndPass = btoa(swappedString);
      } catch (e) {
        // this is only for btoa as it requires latin1
        // using a regexp will be tricky, so we just capture the exception and test if its
        // because user entered utf characters
        console.error("Incorrect charset");
        return incorrectCharsetError;
      }

      try {
        const res = await payload.api.getApiClient(payload.demo ? 0 : 1).post("", {}, {
          params: {
            a: payload.demo
              ? "demo_login_ex"
              : "login_client_ex"
          },
          headers: {
            "Authorization": "Basic " + usrAndPass
          }
        });
        if (res) {
          // check answer
          if (0 === res.data.ec) {
            commit("setUser", res.data);
            commit("setUserType", payload.demo ? 0 : 1);
            // set webapp generated device_id and fb_token to comply with logout policy
            const deviceId = "webapp-" + uuid.v1();
            // set it no matter what, this will be used at a later point when "keep me logged in" is implemented
            await payload.api.getApiClient(payload.demo ? 0 : 1).get("", {
              params: {
                a: payload.demo
                  ? "demo_login"
                  : "login_client",
                id: res.data.id,
                key: res.data.key,
                dev_id: encodeURIComponent(deviceId)
              }
            });
          }
          return res.data.ec;
        }
        return loginFailedError;
      } catch (e) {
        console.error(e);
        return loginFailedError;
      }
    },
    async getQuotes({ state, commit }, payload): Promise<any> {
      try {
        const res = await payload.api.getApiClient(state.userType).get("", {
          params: {
            a: "get_quotes",
            id: state.user.id,
            key: state.user.key,
            sc: 0 === payload.quotes.length ? null : payload.quotes.join()
          }
        });
        if (res) {
          // check answer
          if (0 === res.data.ec) {
            // update quotes
            commit("setQuotes", res.data.list);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    async setSseQuotes({ state }, payload): Promise<any> {
      try {
        await payload.api.getApiClient(state.userType).get("", {
          params: {
            a: "set_sse",
            id: state.user.id,
            key: state.user.key,
            "quote-set": 0 === payload.quotes.length ? null : payload.quotes.join(),
            "quote-sts": 0 === payload.quotes.length ? "off" : "on"
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }
});

export default store;
