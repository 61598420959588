<template>
  <div class="container-fluid mtb15 no-fluid">
    <div class="row sm-gutters">
      <!-- Menu -->
      <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
        <Menu></Menu>
      </div>
      <!-- Profile -->
      <div class="col-sm-12" :class="{ 'col-md-10': 1200 < getWindowsWidth }">
        <div class="settings mtb15">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-2">
                <ul class="nav nav-tabs nav-pills" 
                  :class="{ 'settings-nav': 1200 < getWindowsWidth, 'show_list_horizontal text-nowrap': 1200 > getWindowsWidth }"
                  id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ 'active': 'main' === currentMenuItem, 'xsmall-font-size': 1200 > getWindowsWidth }" id="Main-tab"
                      data-bs-toggle="tab" data-bs-target="#Main" type="button" role="tab" aria-controls="Main"
                      :aria-selected="'main' === currentMenuItem">
                      {{ getMainLabel.toUpperCase() }}
                    </button>
                  </li>                  
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ 'active': 'currency' === currentMenuItem, 'xsmall-font-size': 1200 > getWindowsWidth }" id="Currency-tab"
                      data-bs-toggle="tab" data-bs-target="#Currency" type="button" role="tab" aria-controls="Currency"
                      :aria-selected="'currency' === currentMenuItem">
                      {{ getCurrencyLabel.toUpperCase() }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ 'active': 'language' === currentMenuItem, 'xsmall-font-size': 1200 > getWindowsWidth }" id="Language-tab"
                      data-bs-toggle="tab" data-bs-target="#Language" type="button" role="tab" aria-controls="Language"
                      :aria-selected="'language' === currentMenuItem">
                      {{ getLanguageLabel.toUpperCase() }}
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ 'active': 'password' === currentMenuItem, 'xsmall-font-size': 1200 > getWindowsWidth }" id="Password-tab"
                      data-bs-toggle="tab" data-bs-target="#Password" type="button" role="tab" aria-controls="Password"
                      :aria-selected="'password' === currentMenuItem">
                      {{ getPasswordLabel.toUpperCase() }}
                    </button>
                  </li>
                </ul>
              </div>
              <div class="col-lg-10">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade"
                    :class="{ 'show': 'main' === currentMenuItem, 'active': 'main' === currentMenuItem }"
                    id="Main" role="tabpanel" aria-labelledby="Main-tab">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">{{ getMainLabel }}</h5>
                        <div class="settings-profile">
                          <form>
                            <div class="row">
                              <div class="col-md-12 mt15">
                                <div class="container">
                                  <div class="img-overlay-wrap">
                                    <img width="100" src="../assets/images/EFOCS_Avatar.svg" class=" rounded-circle" alt="avatar" />
                                    <svg class="svg-rect" width="100" height="20">                      
                                        <rect x="0" y="0" rx="3" ry="3" width="100" height="20" :fill="getUserTypeFillColor"></rect>
                                        <text class="avatar_text pt-4" x="50%" y="50%" text-anchor="middle" stroke="white" stroke-width="1px" dy=".3em">{{ getUserTypeString }}</text>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt15">
                                <label htmlFor="userId">{{ getClientNumberLabel }}</label>
                                <input id="userId" type="text" class="form-control" :value="getUserIdForDisplay(getUser.id)" disabled />
                              </div>
                              <div class="col-md-12 mt15">
                                <label htmlFor="userName">{{ getNameLabel }}</label>
                                <input id="userName" type="text" class="form-control" :value="getUser.name" disabled />
                              </div>
                              <div v-if="0 === getUserType()" class="col-md-12 mt15">
                                <label htmlFor="userPhone">{{ getPhoneLabel }}</label>
                                <input id="userPhone" type="text" class="form-control" :value="getUser.phone" disabled />
                              </div>
                              <div v-if="0 === getUserType()" class="col-md-12 mt15">
                                <label htmlFor="userEmail">{{ getEmailLabel }}</label>
                                <input id="userEmail" type="text" class="form-control" :value="getUser.email" disabled />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>                  
                  <div class="tab-pane fade"
                    :class="{ 'show': 'password' === currentMenuItem, 'active': 'password' === currentMenuItem }"
                    id="Password" role="tabpanel" aria-labelledby="Password-tab">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">{{ getPasswordLabel }}</h5>
                        <div class="settings-profile">
                          <form>
                            <div class="row">
                              <div class="col-md-12 mt15">
                                <label htmlFor="currentPass">
                                  {{ getCurrentPasswordLabel }}
                                </label>
                                <input id="currentPass" type="password" class="form-control"
                                  :placeholder="getPasswordPlaceHolder" v-model="oldPassword" />
                              </div>
                              <div class="col-md-12 mt15">
                                <label htmlFor="newPass">{{ getNewPasswordLabel }}</label>
                                <input id="newPass" type="password" class="form-control"
                                  :placeholder="getPasswordPlaceHolder" v-model="newPassword" />
                              </div>
                              <div class="col-md-12 mt15">
                                <label htmlFor="repeatNewPass">{{ getRepeatPasswordPlaceHolder }}</label>
                                <input id="repeatNewPass" type="password" class="form-control"
                                  :placeholder="getRepeatPasswordPlaceHolder" v-model="repeatNewPassword" />
                              </div>
                              <div class="col-md-12 mt15">
                                <input type="submit" :value="getConfirmLabel" :disabled="canUpdatePassword"
                                  @click.prevent="updatePassword" />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade"
                    :class="{ 'show': 'language' === currentMenuItem, 'active': 'language' === currentMenuItem }"
                    id="Language" role="tabpanel" aria-labelledby="Language-tab">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">{{ getLanguageLabel }}</h5>
                        <div class="settings-profile">
                          <form>
                            <div class="row mt-4">
                              <div class="col-md-12">
                                <label class="btn" for="langEn" :class="{ 'w-100': 1200 > getWindowsWidth, 'w-25': 1200 < getWindowsWidth, 'btn-outline-primary' : 'EN' !== language, 'btn-primary': 'EN' === language}">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <img src="../assets/images/English.svg" class="lang_flag" alt="language" />
                                    </div>
                                    &nbsp;English
                                  </div>
                                </label>
                                <input type="radio" class="btn-check" id="langEn" autocomplete="off" value="EN" v-model="language"><br />
                                <label class="btn" for="langBg" :class="{ 'w-100': 1200 > getWindowsWidth, 'w-25': 1200 < getWindowsWidth, 'btn-outline-primary': 'BG' !== language, 'btn-primary': 'BG' === language }">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <img src="../assets/images/Bulgarian.svg" class="lang_flag" alt="language" />
                                    </div>
                                    &nbsp;Български
                                  </div>                                  
                                </label>
                                <input type="radio" class="btn-check" id="langBg" value="BG" v-model="language" autocomplete="off">
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade"
                    :class="{ 'show': 'currency' === currentMenuItem, 'active': 'currency' === currentMenuItem }"
                    id="Currency" role="tabpanel" aria-labelledby="Currency-tab">
                    <div class="card">
                      <div class="card-body">
                        <h5 class="card-title">{{ getCurrencyLabel }}</h5>
                        <div class="settings-profile">
                          <form>
                            <div class="row mt-4">
                              <div class="col-md-12">
                                <label class="btn" for="currEur" :class="{ 'w-100': 1200 > getWindowsWidth, 'w-25': 1200 < getWindowsWidth, 'btn-outline-primary' : 'EUR' !== currency, 'btn-primary': 'EUR' === currency }">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <img src="../assets/images/EUR.svg" class="lang_flag" alt="language" />
                                    </div>
                                    &nbsp;EUR
                                  </div>
                                </label>
                                <input type="radio" class="btn-check" id="currEur" autocomplete="off" value="EUR" v-model="currency"><br />
                                <label class="btn" for="currBgn" :class="{ 'w-100': 1200 > getWindowsWidth, 'w-25': 1200 < getWindowsWidth, 'btn-outline-primary': 'BGN' !== currency, 'btn-primary': 'BGN' === currency }">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <img v-if="'EN' === language" src="../assets/images/BGN.svg" class="lang_flag" alt="language" />
                                      <img v-if="'BG' === language" src="../assets/images/LEV.svg" class="lang_flag" alt="language" />
                                    </div>
                                    &nbsp;BGN
                                  </div>                                    
                                </label>
                                <input type="radio" class="btn-check" id="currBgn" value="BGN" v-model="currency" autocomplete="off">                                
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/axios/http-common";
import Menu from '../components/Menu.vue';
import Translations from "@/lang/translations.js";
import ApiUtils from "@/assets/js/api_utils.js";

export default {
  components: {
    Menu
  },
  props: {
    selectedMenuItem: {
      required: false,
      type: String,
      default: "password"
    }
  },  
  data() {
    return {
      oldPassword: "", 
      newPassword: "", 
      repeatNewPassword: "",
      language: this.getUserLanguage(), 
      currency: this.$store.getters["getCurrency"],
//      currentMenuItem: "" === this.selectedMenuItem ? "password" : this.selectedMenuItem,
      disabled: false,
      timeout: null
    }
  },
  watch: {
    language: function (newVal) {
      //(async () => await this.updateLanguage(newVal))();
      this.$store.commit('setNonLoggedInLanguage', newVal);
    },
    currency: function (newVal) {
      this.updateCurrency(newVal);
      this.$toast.success(this.getCurrencyUpdatedMessage, {
        "duration": 5000,
        "pauseOnHover": true
      });
      this.$store.commit("addSystemMessage", {
        date: (new Date()).getTime(),
        mess: this.getCurrencyUpdatedMessage,
        error: false
      });      
    }    
  },
  computed: {
    getWindowsWidth() {
      return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
    },       
    getUserTypeFillColor() {
      const userType = this.$store.getters["getUserType"];
      return 0 === userType ?
        "#0F7392" :
        "#EC6024";
    },
    getUserTypeString() {
      const userType = this.$store.getters["getUserType"];
      return 0 === userType ?
        "DEMO" :
        "LIVE";
    },    
    currentMenuItem() {
      return this.$route.params.selectedMenuItem;
    },
    canUpdatePassword() {
      return !this.oldPassword || !this.newPassword || 0 !== this.newPassword.localeCompare(this.repeatNewPassword);
    },
    getGeneralInformationLabel() {
      return Translations.getGeneralInformationLabel(this.getUserLanguage());
    },
    getSecurityInformationLabel() {
      return Translations.getSecurityInformationLabel(this.getUserLanguage());
    },
    getLanguageLabel() {
      return Translations.getLanguageLabel(this.getUserLanguage());
    },
    getCurrencyLabel() {
      return Translations.getCurrencyLabel(this.getUserLanguage());
    },
    getBaseCurrencyLabel() {
      return Translations.getBaseCurrencyLabel(this.getUserLanguage());
    },    
    getConfirmLabel() {
      return Translations.getConfirmLabel(this.getUserLanguage());
    },
    getCurrentPasswordLabel() {
      return Translations.getCurrentPasswordLabel(this.getUserLanguage());
    },
    getPasswordLabel() {
      return Translations.getPasswordLabel(this.getUserLanguage());
    },
    getNewPasswordLabel() {
      return Translations.getNewPasswordLabel(this.getUserLanguage());
    },
    getProfileLabel() {
      return Translations.getProfileLabel(this.getUserLanguage());
    },
    getPasswordPlaceHolder() {
      return Translations.getPasswordPlaceHolder(this.getUserLanguage());
    },
    getRepeatPasswordPlaceHolder() {
      return Translations.getRepeatPasswordPlaceHolder(this.getUserLanguage());
    },
    getCouldNotUpdatePasswordMessage() {
      return Translations.getCouldNotUpdatePasswordMessage(this.getUserLanguage());
    },
    getPasswordUpdatedMessage() {
      return Translations.getPasswordUpdatedMessage(this.getUserLanguage());
    },
    getCouldNotUpdateLanguageMessage() {
      return Translations.getCouldNotUpdateLanguageMessage(this.getUserLanguage());
    }, 
    getLanguageUpdatedMessage() {
      return Translations.getLanguageUpdatedMessage(this.getUserLanguage());
    }, 
    getCurrencyUpdatedMessage() {
      return Translations.getCurrencyUpdatedMessage(this.getUserLanguage());
    },
    getMainLabel() {
      return Translations.getMainLabel(this.getUserLanguage());
    },
    getUser() {
      return this.$store.getters["getUser"];
    },
    getDemoLabel() {
      return Translations.getDemoLabel(this.getUserLanguage());
    }, 
    getLiveLabel() {
      return Translations.getLiveLabel(this.getUserLanguage());
    }, 
    getClientNumberLabel() {
      return Translations.getClientNumberLabel(this.getUserLanguage());
    },
    getNameLabel() {
      return Translations.getNameLabel(this.getUserLanguage());
    },
    getPhoneLabel() {
      return Translations.getPhoneLabel(this.getUserLanguage());
    },
    getEmailLabel() {
      return Translations.getEmailLabel(this.getUserLanguage());
    },
    getInvalidSessionLabel() {
      return Translations.getInvalidSessionLabel(this.getUserLanguage());
    },    
  },
  methods: {
    getUserType() {
      return this.$store.getters["getUserType"];
    },    
    async updatePassword() {
      const user = this.$store.getters["getUser"];
      // change password
      try {
        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "change_password",
            id: user.id,
            key: user.key,
            opw: this.oldPassword,
            npw: this.newPassword
          }
        });
        // forbid user to click again
        this.oldPassword = "";
        this.newPassword = "";
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            this.$toast.success(this.getPasswordUpdatedMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getPasswordUpdatedMessage,
              error: false
            });
          } else {
            this.$toast.error(this.getCouldNotUpdatePasswordMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotUpdatePasswordMessage,
              error: true
            });
          }
        }
      } catch (e) {
        console.error(e);
      }   
    },
    updateCurrency(curr) {
      this.disabled = true;

      // Re-enable after 5 seconds
      this.timeout = setTimeout(() => {
        this.disabled = false
      }, 2000);      
      // set currency
      this.$store.commit("setCurrency", curr);
    },
    async updateLanguage(lang) {
      const user = this.$store.getters["getUser"];
      // set lang
      try {
        this.disabled = true;

        // Re-enable after 5 seconds
        this.timeout = setTimeout(() => {
          this.disabled = false
        }, 2000);

        const res = await http.getApiClient(this.getUserType()).get("", {
          params: {
            a: "set_lang",
            id: user.id,
            key: user.key,
            lang: lang
          }
        });
        if (res) {
          // check answer
          if (ApiUtils.isApiResponseInvalidSession(res)) {
            this.$store.commit("setAppStateReady");
            // logout
            setTimeout(
              ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
              500);
            return;
          }          
          if (0 === res.data.ec) {
            this.$store.commit("updateUserLanguage", this.language);
            this.$toast.success(this.getLanguageUpdatedMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getLanguageUpdatedMessage,
              error: false
            });
          } else {
            this.$toast.error(this.getCouldNotUpdateLanguageMessage, {
              "duration": 5000,
              "pauseOnHover": true
            });
            this.$store.commit("addSystemMessage", {
              date: (new Date()).getTime(),
              mess: this.getCouldNotUpdateLanguageMessage,
              error: true
            });
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    getUserLanguage() {
      return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
    },
    getUserIdForDisplay(id) {
      return 0 === this.getUserType()
        ? 'demo-' + id
        : id;
    },
   
  },
  beforeUnmount() {
    // clear the timeout before the component is destroyed
    clearTimeout(this.timeout);
  }
}
</script>

<style scoped>

input:disabled {
  cursor: not-allowed;
  opacity: 0.2;
  pointer-events: all !important;
}

.lang_flag {
    width: 20px; 
    height: 20px;
}


  .avatar_text {
    top:0;
    left:0;
    font-size: xx-small;
  }

.img-overlay-wrap {
  position: relative;
  display: inline-block; /* <= shrinks container to image size */
  transition: transform 150ms ease-in-out;
}

.img-overlay-wrap img { /* <= optional, for responsiveness */
   display: block;
   max-width: 100%;
   height: auto;
}

.img-overlay-wrap svg {
  position: absolute;
  top: 80%;
  left: 0;
}

  .show_list_horizontal {
      overflow-x: auto; 
      max-width: 100%; 
      max-height: auto; 
      flex: 1; 
      flex-wrap: nowrap;
  }

.xxsmall-font-size {
    font-size: xx-small;
}

.xsmall-font-size {
    font-size: x-small;
} 
</style>