<template>
    <div class="container-fluid mtb15 no-fluid">
        <div class="row sm-gutters">
            <!-- Menu -->
            <div v-if="1200 < getWindowsWidth" class="col-sm-12 col-md-2">
                <Menu></Menu>
            </div>
            <!-- Modal trade confirmed -->
            <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="tradeConfirmedLabel" aria-hidden="true"
                :class="{ 'show': tradeConfirmedModalShow, 'd-block': tradeConfirmedModalActive }">
                <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <TradeConfirmed :order="orderModel" />
                    </div>
                    <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        @click="tradeConfirmedModalActive = false; tradeConfirmedModalShow = false;">{{
                            getCloseLabel
                        }}</button>
                    </div>
                </div>
                </div>
            </div>               
            <!-- Modal add to watchlist -->
            <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="addToWatchlistLabel" aria-hidden="true"
                :class="{ 'show': watchlistSelectionModalShow, 'd-block': watchlistSelectionModalActive }">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="addToWatchlistLabel">{{
                            getWatchlistSelectionLabel }}</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="btn-group d-flex mtb15" role="group">
                                    <input type="radio" class="btn-check" :id="'newwl' + selectedItemCode" autocomplete="off"
                                        value="new" v-model="newWatchlistSelected">
                                    <label class="btn btn-outline-primary" :for="'newwl' + selectedItemCode">{{
                                        getNewWatchlistNameLabel
                                    }}</label>
                                    <input type="radio" class="btn-check" :id="'existwl' + selectedItemCode" value="old"
                                        v-model="newWatchlistSelected" autocomplete="off">
                                    <label class="btn btn-outline-primary" :for="'existwl' + selectedItemCode">{{
                                        getExistingWatchlistNameLabel
                                    }}</label>
                                </div>  
                            </div>
                            <div class="row" v-show="'new' === newWatchlistSelected">
                                <input :id="'watchlistName' + selectedItemCode" type="text" class="form-control"
                                    :placeholder="getWatchlistNamePlaceholder" v-model="watchlistName" />
                            </div>
                            <div class="row" v-show="'new' !== newWatchlistSelected">
                                <label :htmlFor="'watchlistName' + selectedItemCode">{{ getWatchlistSelectionLabel }}</label>
                                <select :id="'watchlistName' + selectedItemCode" class="form-select" v-model="watchlistName">
                                    <option v-for="(watchlist) in getWatchlists" :key="watchlist.wln"
                                        :value="watchlist.wln">{{ watchlist.wln }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="updateWatchlist()" :disabled="'' === watchlistName">{{
                                getSaveLabel
                                }}</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal"
                                @click="watchlistSelectionModalActive = false; watchlistSelectionModalShow = false;">{{
                                getCloseLabel
                                }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="watchlistSelectionModalActive" class="modal-backdrop fade show">
            </div>
            <!-- Modal show trading in responsive -->
            <div v-if="1200 > getWindowsWidth" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="showTradingInResponsive" aria-hidden="true"
                :class="{ 'show': tradingInResponsiveModalShow, 'd-block': tradingInResponsiveModalActive }">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" 
                                @click="tradingInResponsiveModalShow = false; tradingInResponsiveModalActive = false; showTradingInResponsive = false;">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>                    
                        <div class="modal-body">
                            <Trading :key="this.selectedItemCode"></Trading>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Extended stock info -->
            <div class="col-sm-12" :class="{'col-md-10' : 1200 < getWindowsWidth }">
                <div class="settings mtb15">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-2">
                                <ul class="nav nav-tabs nav-pills" 
                                :class="{'settings-nav' : 1200 < getWindowsWidth, 'show_list_horizontal text-nowrap' : 1200 > getWindowsWidth}"
                                id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link"
                                            :class="{ 'active': 0 === activeExtendedStockItem.localeCompare('EXTENDED') }"
                                            id="Extended-tab" data-bs-toggle="tab"
                                            data-bs-target="#Extended" type="button" role="tab" aria-controls="Extended"
                                            :aria-selected="0 === activeExtendedStockItem.localeCompare('EXTENDED')"
                                            @click.prevent="inExtendedInfoMenuItemClick('Extended')">
                                            {{ getInformationLabel.toUpperCase() }}
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" 
                                            :class="{ 'active': 0 === activeExtendedStockItem.localeCompare('GRAPH') }"
                                            id="Graph-tab" data-bs-toggle="tab"
                                            data-bs-target="#Graph" type="button" role="tab" aria-controls="Graph"
                                            :aria-selected="0 === activeExtendedStockItem.localeCompare('GRAPH')"
                                            @click.prevent="inExtendedInfoMenuItemClick('Graph')">
                                            {{ getGraphLabel.toUpperCase() }}
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" 
                                            :class="{ 'active': 0 === activeExtendedStockItem.localeCompare('DEPTH') }"
                                            id="Depth-tab" data-bs-toggle="tab"
                                            data-bs-target="#Depth" type="button" role="tab" aria-controls="Depth"
                                            :aria-selected="0 === activeExtendedStockItem.localeCompare('DEPTH')"
                                            @click.prevent="inExtendedInfoMenuItemClick('Depth')">
                                            {{ getDepthLabel.toUpperCase() }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6">
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade"
                                        :class="{ 'show active': 0 === activeExtendedStockItem.localeCompare('EXTENDED') }"
                                        id="Extended" role="tabpanel"
                                        aria-labelledby="Extended-tab">
                                        <div class="card border-dark">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex">
                                                        <div>
                                                            <h5 class="card-title"><strong>{{ getStockName }}</strong>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="text-end">
                                                            <button v-if="!isStockPartOfDefaultWatchlist" class="btn btn-primary" @click="updateWatchlist">+</button>
                                                            <button v-else class="btn btn-primary" @click="removeFromWatchlist">-</button>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>{{ selectedItemCode }}</strong></td>
                                                                <td>{{ getExchangeNameLabel }}: <strong>{{ getExchange?.code?.toUpperCase() }}</strong></td>
                                                                <td>{{ getExchangePricesLabel }} <strong>{{ getStockCurrency }}</strong></td>
                                                                <td v-if="!isClosedMarked">{{ getExchangePhaseLabel }}
                                                                    <label class="label greenColor">
                                                                        <strong>{{ getMarketPhaseLabel }}</strong>
                                                                    </label>
                                                                </td>
                                                                <td v-else>{{ getExchangePhaseLabel }} 
                                                                    <label class="label redColor">
                                                                        <strong>{{ getMarketPhaseLabel }}</strong>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <strong>
                                                    <span v-if="0 < parseFloat(getQuoteEx().ldp)" :class="buyColor">
                                                        {{ formatPrice(getQuoteEx().ldp, getStockCurrency).replace(' ', '&nbsp;') }}
                                                    </span>
                                                    <span v-else :class="buyColor">
                                                        {{ formatPrice(getQuoteEx().ldp, getStockCurrency).replace(' ', '&nbsp;') }}
                                                    </span>
                                                </strong> <sup><span v-if="0 < parseFloat(getQuoteEx().ch)" class="green">+{{ parseNumber(getQuoteEx().ch) }}% (+{{ parseNumber(getQuoteEx().dif) }})</span><span v-else class="red">{{ parseNumber(getQuoteEx().ch) }}% ({{ parseNumber(getQuoteEx().dif) }})</span></sup>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>{{ getPreviousCloseLabel }}</strong></td>
                                                                <td class="align-text-right">{{ formatPrice(getQuoteEx().prc, getStockCurrency).replace(' ', '&nbsp;') }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>{{ getDailyVolumeLabel }}</strong></td>
                                                                <td class="align-text-right">{{ parseNumber(getQuoteEx().dv, 0) }}</td>
                                                            </tr>
                                                            <tr style="color: #0F7392;">
                                                                <td><strong>{{ getQuantityLabel }}</strong></td>
                                                                <td class="align-text-right">{{ parseNumber(getQuoteEx().bq, 0) }}</td>
                                                            </tr>
                                                            <tr style="color: #0F7392;">
                                                                <td><strong>{{ getBidLabel }}</strong></td>
                                                                <td class="align-text-right">{{ formatPrice(getQuoteEx().b, getStockCurrency).replace(' ', '&nbsp;') }}</td>
                                                            </tr>
                                                            <tr style="color: #EC6024;">
                                                                <td><strong>{{ getAskLabel }}</strong></td>
                                                                <td class="align-text-right">{{ formatPrice(getQuoteEx().a, getStockCurrency).replace(' ', '&nbsp;') }}</td>
                                                            </tr>
                                                            <tr style="color: #EC6024;">
                                                                <td><strong>{{ getQuantityLabel }}</strong></td>
                                                                <td class="align-text-right">{{ parseNumber(getQuoteEx().sq, 0) }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>{{ getTickSizeLabel }}</strong></td>
                                                                <td class="align-text-right">{{ parseNumber(calculateTickSize, calculateNumberOfMeaningfulDigits(calculateTickSize)) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" 
                                        :class="{ 'show active': 0 === activeExtendedStockItem.localeCompare('DEPTH') }"
                                        id="Depth" role="tabpanel" aria-labelledby="Depth-tab">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex">
                                                        <div>
                                                            <h5 class="card-title"><strong>{{ getStockName }}</strong>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="text-end">
                                                            <button v-if="!isStockPartOfDefaultWatchlist" class="btn btn-primary" @click="updateWatchlist">+</button>
                                                            <button v-else class="btn btn-primary" @click="removeFromWatchlist">-</button>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>{{ selectedItemCode }}</strong></td>
                                                                <td>{{ getExchangeNameLabel }}: <strong>{{ getExchange?.code?.toUpperCase() }}</strong></td>
                                                                <td>{{ getExchangePricesLabel }} <strong>{{ getStockCurrency }}</strong></td>
                                                                <td v-if="!isClosedMarked">{{ getExchangePhaseLabel }}
                                                                    <label class="label greenColor">
                                                                        <strong>{{ getMarketPhaseLabel }}</strong>
                                                                    </label>
                                                                </td>
                                                                <td v-else>{{ getExchangePhaseLabel }} 
                                                                    <label class="label redColor">
                                                                        <strong>{{ getMarketPhaseLabel }}</strong>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <strong><span v-if="0 < parseFloat(getDepth().ldp)" :class="buyColor">{{ formatPrice(getDepth().ldp, (selectedItemCode, getExchange?.code)).replace(' ', '&nbsp;') }}</span>
                                                    <span v-else :class="buyColor">{{ formatPrice(getDepth().ldp, getStockCurrency).replace(' ', '&nbsp;') }}</span></strong> 
                                                    <sup>
                                                        <span v-if="0 < parseFloat(getDepth().ch)" class="green">+{{ parseNumber(getDepth().ch) }}% (+{{ parseNumber(getDepth().dif) }})</span>
                                                        <span v-else class="red">{{ parseNumber(getDepth().ch) }}% ({{ parseNumber(getDepth().dif) }})</span>
                                                    </sup>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>{{ getLastTransactionLabel }}</strong></td>
                                                                <td class="align-text-right">
                                                                    {{ parseNumber(getDepth().ldc, 0) }} x {{ formatPrice(getDepth().ldp, getStockCurrency).replace(' ', '&nbsp;') }}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>{{ getDailyVolumeLabel }}</strong></td>
                                                                <td class="align-text-right">{{ parseNumber(getDepth().dv, 0) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="row">
                                                    <div class="btn-group d-flex mtb15" role="group">
                                                        <input type="radio" class="btn-check" id="otbid" autocomplete="off" value="B" v-model="depthDetails">
                                                        <label class="btn btn-outline-primary" for="otbid">{{ getBidLabel }}</label>
                                                        <input type="radio" class="btn-check" id="otask" value="S" v-model="depthDetails" autocomplete="off">
                                                        <label class="btn btn-outline-primary" for="otask">{{ getAskLabel }}</label>
                                                    </div>                                                    
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table" style='table-layout:fixed;'>
                                                        <thead>
                                                            <tr>
                                                                <th class="align-text-center">{{ getOrdersLabel }}</th>
                                                                <th class="align-text-center">Σ {{ getQuantityLabel }}</th>
                                                                <th class="align-text-center">{{ getQuantityLabel }}</th>
                                                                <th v-if="'B' === depthDetails" class="align-text-center">{{ getBidLabel }}</th>
                                                                <th v-else class="align-text-center">{{ getAskLabel }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody v-if="'B' === depthDetails">
                                                            <tr v-for="(item, index) in getDepth().B" :key="index" @click="setTradingActionquantityAndPrice('S', item.q, item.p)">
                                                                <td v-if="item.oc" class="align-text-center">{{ parseNumber(item.oc, 0) }}</td>
                                                                <td v-else class="align-text-center">&nbsp;</td>
                                                                <td class="align-text-center">{{ parseNumber(sigmaB[index], 0) }}</td>
                                                                <td class="align-text-center">{{ parseNumber(item.q, 0) }}</td>
                                                                <td class="align-text-center">{{ formatPrice(item.p, getStockCurrency).replace(' ', '&nbsp;') }}</td>
                                                            </tr>
                                                        </tbody>
                                                        <tbody v-else>
                                                            <tr v-for="(item, index) in getDepth().A" :key="index" @click="setTradingActionquantityAndPrice('B', item.q, item.p)">
                                                                <td v-if="item.oc" class="align-text-center">{{ parseNumber(item.oc, 0) }}</td>
                                                                <td v-else class="align-text-center">&nbsp;</td>
                                                                <td class="align-text-center">{{ parseNumber(sigmaA[index], 0) }}</td>
                                                                <td class="align-text-center">{{ parseNumber(item.q, 0) }}</td>
                                                                <td class="align-text-center">{{ formatPrice(item.p, getStockCurrency).replace(' ', '&nbsp;') }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" 
                                        :class="{ 'show active': 0 === activeExtendedStockItem.localeCompare('GRAPH') }"
                                        id="Graph" role="tabpanel" aria-labelledby="Graph-tab">
                                        <div v-if="seriesData && 0 !== seriesData.length" class="card">
                                            <div class="card-body">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex">
                                                        <div>
                                                            <h5 class="card-title"><strong>{{ getStockName }}</strong>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p class="text-end">
                                                            <button v-if="!isStockPartOfDefaultWatchlist" class="btn btn-primary" @click="updateWatchlist">+</button>
                                                            <button v-else class="btn btn-primary" @click="removeFromWatchlist">-</button>                                                            
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>{{ selectedItemCode }}</strong></td>
                                                                <td>{{ getExchangeNameLabel }}: <strong>{{ getExchange?.code?.toUpperCase() }}</strong></td>
                                                                <td>{{ getExchangePricesLabel }} <strong>{{ getStockCurrency }}</strong></td>
                                                                <td v-if="!isClosedMarked">{{ getExchangePhaseLabel
                                                                }}
                                                                    <label class="label greenColor">
                                                                        <strong>{{
                                                                        getMarketPhaseLabel
                                                                        }}</strong></label>
                                                                </td>
                                                                <td v-else>{{ getExchangePhaseLabel }} <label
                                                                        class="label redColor">
                                                                        <strong>{{
                                                                        getMarketPhaseLabel
                                                                        }}</strong></label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div id="history_depth_menu" class="row" style="overflow-x: auto; max-width: 100%; max-height: auto; flex: 1; flex-wrap: nowrap;">
                                                    <input type="radio" class="btn-check" id="day" autocomplete="off"
                                                        value="day" v-model="graphInterval" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="day">1D</label>
                                                    <input type="radio" class="btn-check" id="week" value="week"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="week">1W</label>
                                                    <input type="radio" class="btn-check" id="month" value="month"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="month">1M</label>
                                                    <input type="radio" class="btn-check" id="sixmonths" value="6mo"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="sixmonths">6M</label>
                                                    <input type="radio" class="btn-check" id="ytd" value="ytd"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="ytd">YTD</label>
                                                    <input type="radio" class="btn-check" id="year" value="1year"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="year">1Y</label>
                                                    <input type="radio" class="btn-check" id="years3" value="3years"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="years3">3Y</label>
                                                    <input type="radio" class="btn-check" id="years5" value="5years"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="years5">5Y</label>
                                                    <input type="radio" class="btn-check" id="max" value="max"
                                                        v-model="graphInterval" autocomplete="off" :disabled="disabled">
                                                    <label class="btn btn-outline-primary" for="max">MAX</label>
                                                </div>                                               
                                                <div class="d-flex align-items-center justify-content-center">
                                                    <GAreaChart :height="1200 < getWindowsWidth ? 480 : 240" :width="1200 < getWindowsWidth ? 640 : 320" :data="seriesData" :lbls="seriesLabels"></GAreaChart>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else><h4>{{ getChartDataForInstrumentUnavailableMessage }}</h4></div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="1200 < getWindowsWidth" class="col-lg-4">
                                <Trading :key="this.selectedItemCode"></Trading>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- trade button -->
        <button v-if="1200 > getWindowsWidth && !showTradingInResponsive" id="tradeButton" class="btn position-fixed bottom-0 end-0" 
            @click.prevent="showTradingInResponsive = true; tradingInResponsiveModalShow = true; tradingInResponsiveModalActive = true;">
            <img src="../assets/images/trade_button.svg" alt="">
        </button>        
    </div>
</template>

<script>
import Menu from "../components/Menu.vue";
//import AreaChart from "../components/AreaChart.vue"
import http from "@/axios/http-common";
import UiUtils from "@/assets/js/ui_utils.js";
import ApiUtils from "@/assets/js/api_utils.js";
import Translations from "@/lang/translations.js";
import Trading from '../components/Trading.vue';
import TradeConfirmed from '@/components/TradeConfirmed.vue';
import GAreaChart from "../components/GAreaChart.vue";

// exchange liquidity price levels
const sdLiqPriceLevels = [0, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000];
// exchange tick sizes for limit prices
const sdMaxTickSizes = [500, 500, 200, 100, 50, 20, 10, 5, 2, 1, 0.5];
const STRING_NOT_AVAILABLE = "N/A";

export default {
    components: {
        Menu,
        GAreaChart,
        Trading,
        TradeConfirmed
    },
    data() {
        return {
            changePercent: null,
            changeColor: "blackColor",
            buy: null,
            buyColor: "blackColor",
            depthDetails: 'B',
            graphInterval: "ytd",
            seriesData: [],
            seriesLabels: [],
            sigmaB: [],
            sigmaA: [],
            /* active modal dialog for watchlist selection */
            watchlistSelectionModalActive: false,
            /* show modal dialog for watchlist selection */
            watchlistSelectionModalShow: false,   
            newWatchlistSelected: "old",
            watchlistName: "Default",
            disabled: false,
            timeout: null,
            selectedItemCode: this.stockCode || this.$store.getters["getSelectedTradingCode"],
            selectedItemExchangeId: parseInt(this.itemExchangeId) || this.$store.getters["getSelectedTradingExchangeId"],
            activeExtendedStockItem: "EXTENDED",
            tradeConfirmedModalActive: false,
            tradeConfirmedModalShow: false,
            orderModel: {},
            hasReestablishingConnectionMessage: false,
            sseVerifier: null,
            hasEvents: false,
            sseClient: null,
            showTradingInResponsive: false,
            tradingInResponsiveModalShow: false,
            tradingInResponsiveModalActive: false            
        }
    },
    props: {
        stockCode: {
            required: true,
            type: String
        },
        itemExchangeId: {
            required: true,
            type: String
        }
    },
    watch: {
        changePercent: function(newVal, oldVal) {
            if (null != oldVal && null != oldVal && newVal !== oldVal) {
                if (oldVal > newVal) {
                    this.changeColor = "red";
                } else if (oldVal < newVal) {
                    this.changeColor = "green";
                }
            }
        },
        buy: function (newVal, oldVal) {
            if (null != oldVal && null != oldVal && newVal !== oldVal) {
                if (oldVal > newVal) {
                    this.buyColor = "red";
                } else if (oldVal < newVal) {
                    this.buyColor = "green";
                }
                // restore
                setTimeout(() => {
                    this.buyColor = "blackColor";
                }, 2000);
            }
        },
        graphInterval: function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.disabled = true;

                // Re-enable after 0.5 seconds
                this.timeout = setTimeout(() => {
                    this.disabled = false
                }, 500);
                (async () => {
                    // gathering data could be a long process, so we set the wait
                    this.$store.commit("setAppStateNotReady");
                    await this.getInstrumentHistory();
                    this.$store.commit("setAppStateReady");
                })();
            }
        },
        getSelectedTradingCode: function (newVal, oldVal) {
            if (oldVal !== newVal) {
                this.selectedItemCode = newVal;
                this.selectedItemExchangeId = this.$store.getters["getSelectedTradingExchangeId"];
                setTimeout(() => {
                    (async () => await Promise.all([this.setQuoteTr(newVal), this.getQuoteTr(), this.setQuoteExAndDepth(), this.getQuoteExAndDepth(), this.getInstrumentHistory()]))();
                    this.getQuoteEx();
                }, 300);
            }
        },
        stopSseState: function (newVal) {
            if (newVal) {
                if (this.sseClient) {
                try {
                    this.sseClient.close();
                } catch (err) {
                    console.error(err);
                }
                this.$store.commit("setStopSse", false);
                }
            }
        }
    },
    methods: {
        getUserType() {
            return this.$store.getters["getUserType"];
        },
        getOrderType(ot) {
            return 'b' === ot || 'B' === ot ?
                Translations.getBuyLabel(this.getUserLanguage()) :
                Translations.getSellLabel(this.getUserLanguage());
        },
        getDate(unix) {
            return UiUtils.parseUnixTime(unix);
        },
        getBuyLabel() {
            return Translations.getBuyLabel(this.getUserLanguage());
        },
        getSellLabel() {
            return Translations.getSellLabel(this.getUserLanguage());
        },
        getStockNameByCode(stockCode) {
            const stocks = this.$store.getters["getStocks"];
            const indexedStocks = this.$store.getters["getIndexedStocks"];

            return UiUtils.getStockName(stocks, indexedStocks, stockCode, this.getUserLanguage());
        },
        setupStream() {
            const user = this.$store.getters["getUser"];
            if (!user) {
                return;
            }
            try {
                if (0 === this.getUserType()) {
                    // sandbox
                    this.sseClient = new EventSource("https://sse.sandbox.efocs.app/api/sse?vn=100&os=b&id=" + encodeURIComponent(user.id) +
                        "&key=" + encodeURIComponent(user.key));
                } else {
                    // production
                    this.sseClient = new EventSource("https://sse.prod.efocs.app/api/sse?vn=100&os=b&id=" + encodeURIComponent(user.id) +
                        "&key=" + encodeURIComponent(user.key));
                }

                // verify we have valid connection
                if (!this.sseClient) {
                    return;
                }
                // show message if connection has been lost
                if (this.hasReestablishingConnectionMessage) {
                    const reestablishConnectionMessage = this.getReestablishedConnectionLabel;
                    // show user info
                    this.$toast.success(reestablishConnectionMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: reestablishConnectionMessage,
                        error: false
                    });
                    this.hasReestablishingConnectionMessage = false;
                }

                this.sseClient.addEventListener("HEARTBEAT", event => {
                    this.hasEvents = true;
                }, { passive: true });
                this.sseClient.addEventListener("SESSION_CLOSED", event => {
                    this.hasEvents = true;
                    // logout
                    ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1);                         
                }, { passive: true });
                this.sseClient.addEventListener("QUOTE", event => {
                    try {
                        this.$store.commit("updateQuote", JSON.parse(event.data));
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
                this.sseClient.addEventListener("QUOTE_TR", event => {
                    this.hasEvents = true;
                    try {
                        this.$store.commit("setQuoteTrading", JSON.parse(event.data));
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
                this.sseClient.addEventListener("QUOTE_EX", event => {
                    this.hasEvents = true;
                    try {
                        this.$store.commit("setQuoteExtended", JSON.parse(event.data));
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
                this.sseClient.addEventListener("DEPTH", event => {
                    this.hasEvents = true;
                    try {
                        this.$store.commit("setDepth", JSON.parse(event.data));
                        let obj = JSON.parse(event.data);
                        // calculate sigmas
                        let sigma = 0;
                        this.sigmaA = [];
                        this.sigmaB = [];
                        for (let cnt = 0; cnt < obj.B.length; cnt++) {
                            if (0 === cnt) {
                                sigma = obj.B[cnt].q;
                            } else {
                                sigma = sigma + obj.B[cnt].q;
                            }
                            this.sigmaB.push(sigma);
                        }
                        sigma = 0;
                        for (let cnt = 0; cnt < obj.A.length; cnt++) {
                            if (0 === cnt) {
                                sigma = obj.A[cnt].q;
                            } else {
                                sigma = sigma + obj.A[cnt].q;
                            }
                            this.sigmaA.push(sigma);
                        }                    
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
                this.sseClient.addEventListener("PHASE", event => {
                    this.hasEvents = true;
                    try {
                        this.$store.commit("upsertExchangesPhases", JSON.parse(event.data));
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
                this.sseClient.addEventListener("EXECUTED", event => {
                    this.hasEvents = true;
                    try {
                        const orderData = JSON.parse(event.data);
                        this.formOrderObject(orderData);
                        this.tradeConfirmedModalShow = true;
                        this.tradeConfirmedModalActive = true;                    
                        let mess = "";
                        if (0 === "BG".localeCompare(this.getUserLanguage())) {
                            mess = "Вашата поръчка №";
                        } else {
                            mess = "Your order #";
                        }
                        mess = mess + orderData.id + ", " + this.getDate(orderData.d) +
                            ", " + this.getOrderType(orderData.t) + " " + orderData.sc + "(" + this.getStockNameByCode(orderData.sc) + ") " +
                            orderData.qty + "@" + orderData.sp + " " + orderData.cc;
                        if (0 === "BG".localeCompare(this.getUserLanguage())) {
                            mess = mess + " е изпълнена";
                        } else {
                            mess = mess + " is completed";
                        }
                        this.$toast.success(mess, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: mess,
                            error: false
                        });
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
                this.sseClient.addEventListener("REJECTED", event => {
                    this.hasEvents = true;
                    try {
                        const orderData = JSON.parse(event.data);
                        let mess = "";
                        if (0 === "BG".localeCompare(this.getUserLanguage())) {
                            mess = "Вашата поръчка №";
                        } else {
                            mess = "Your order #";
                        }
                        const stockName = orderData.nBG
                            ? orderData.nBG : orderData.nEN;
                        mess = mess + orderData.oid + ", " + this.getDate(orderData.ts) +
                            ", " + orderData.sc + "(" + stockName + ") " +
                            orderData.qty + "@" + orderData.sp + " " + orderData.cc;
                        if (0 === "BG".localeCompare(this.getUserLanguage())) {
                            mess = mess + " е отказана";
                        } else {
                            mess = mess + " is rejected";
                        }
                        this.$toast.error(mess, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: mess,
                            error: true
                        });
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
                this.sseClient.addEventListener("error", event => {
                    this.hasEvents = false;
                    try {
                        if (event.readyState == EventSource.CLOSED) {
                            console.log(EventSource);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }, { passive: true });
            } catch (error) {
                console.error(error);
            }
        },            
        getUserLanguage() {
            return this.$store.getters["getNonLoggedInLanguage"]?.toUpperCase() || "EN";
        },
        getStartDateForInstrumentHistory() {
            const nowDate = new Date();
            let prevDate = new Date(nowDate.getTime());
            
            switch (this.graphInterval) {
                case "day": {
                    if (0 === nowDate.getDay()) {
                        prevDate.setDate(nowDate.getDate() - 3);
                    } else if (6 === nowDate.getDay()) {
                        prevDate.setDate(nowDate.getDate() - 2);

                    } else {
                        prevDate.setDate(nowDate.getDate() - 1);
                    }
                    break;
                }
                case "week": {
                    prevDate.setDate(nowDate.getDate() - 7);
                    break;
                }
                case "month": {
                    prevDate.setDate(nowDate.getDate() - 30);
                    break;
                }
                case "6mo": {
                    prevDate.setDate(nowDate.getDate() - 180);
                    break;
                }
                case "1year": {
                    prevDate.setDate(nowDate.getDate() - 360);
                    break;
                }
                case "ytd": {
                    prevDate = new Date(nowDate.getFullYear(), 0, 1);
                    break;
                }
                case "3years": {
                    prevDate.setDate(nowDate.getDate() - 1080);
                    break;
                }
                case "5years": {
                    prevDate.setDate(nowDate.getDate() - 1800);
                    break;
                }
                case "max": {
                    prevDate.setDate(nowDate.getDate() - 3600);
                    break;
                }
            }            
            return parseInt(prevDate.getTime() / 1000);            
        },
        getIntervalForInstrumentHistory() {
            switch (this.graphInterval) {
                case "day": {
                    // day
                    return "5m";
                }
                case "week": {
                    // week
                    return "30m";
                }
                case "month": 
                case "6mo":
                case "1year":
                case "ytd": {
                    // month, 6 months, year, year to date
                    return "1d";
                }
                case "3years":
                case "5years": {
                    return "1w";
                }
                case "max": {
                    return "1mo"
                }                
            }
            return "5m";
        },
        async getInstrumentHistory() {
            const user = this.$store.getters["getUser"];
            try {
                const res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "get_instrument_history_ex",
                        id: user.id,
                        key: user.key,
                        sc: this.selectedItemCode,
                        sd: this.getStartDateForInstrumentHistory(),
                        ed: parseInt((new Date()) / 1000),
                        it: this.getIntervalForInstrumentHistory(),
                        ex: this.getExchange?.code
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(
                            ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                            500);
                        return;
                    }                    
                    if (0 === res.data.ec) {
                        let historyData = [];
                        let historyLabels = [];
                        res.data.list.forEach(item => {
                            item.data.forEach(data => {
                                historyLabels.push(data.tS.slice(0, 16));
                                historyData.push([data.t * 1000, data.hi]);
                            });
                        });
                        this.seriesLabels = historyLabels;
                        this.seriesData.length = 0;
                        this.seriesData.push({
                            data: historyData
                        });
                    } else {
                        this.seriesData.length = 0;
                        this.$toast.error(this.getCouldNotReadHistoryMessage, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getCouldNotReadHistoryMessage,
                            error: true
                        });
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },        
        async setQuoteExAndDepth() {
            if ("" === this.selectedItemCode) {
                return;
            }
            // set sse for quote tr
            const user = this.$store.getters["getUser"];
            const exchange = this.getExchange;
            try {                
                const res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "set_sse",
                        id: user.id,
                        key: user.key,
                        "phase-sts": "on",
                        "quote-sts": "off",
                        "quote-ex-set": !this.selectedItemCode ? null : UiUtils.combineExchangeStockPair(exchange.code, this.selectedItemCode),
                        "quote-ex-sts": !this.selectedItemCode ? "off" : "on",
                        "quote-tr-set": !this.selectedItemCode ? null : UiUtils.combineExchangeStockPair(exchange.code, this.selectedItemCode),
                        "quote-tr-sts": !this.selectedItemCode ? "off" : "on",
                        "depth-set": !this.selectedItemCode ? null : UiUtils.combineExchangeStockPair(exchange.code, this.selectedItemCode),
                        "depth-sts": !this.selectedItemCode ? "off" : "on"
                    }
                });
                if (res) {
                    // check answer
                    if (0 !== res.data.ec) {
                        this.$toast.error(this.getCouldNotSetQuoteMessage, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getCouldNotSetQuoteMessage,
                            error: true
                        });
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getQuoteExAndDepth() {
            if ("" === this.selectedItemCode) {
                return;
            }
            // get quote ex
            const user = this.$store.getters["getUser"];
            const exchange = this.getExchange;
            try {
                let res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "get_quote_ex",
                        id: user.id,
                        key: user.key,
                        sc: UiUtils.combineExchangeStockPair(exchange.code, this.selectedItemCode)
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(
                            ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                            500);
                        return;
                    }                    
                    if (0 === res.data.ec) {
                        // set in vuex
                        this.$store.commit("setQuoteExtended", res.data);
                    } else {
                        this.$toast.error(this.getCouldNotSetQuoteMessage, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getCouldNotSetQuoteMessage,
                            error: true
                        });
                    }
                } 
                // get depth
                res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "get_depth",
                        id: user.id,
                        key: user.key,
                        sc: UiUtils.combineExchangeStockPair(exchange.code, this.selectedItemCode)
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(
                            ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                            500);
                        return;
                    }                    
                    if (0 === res.data.ec) {
                        // set in vuex
                        this.$store.commit("setDepth", res.data);
                        // calculate sigmas if any
                        let sigma = 0;
                        if (res?.data?.B && 0 < res?.data?.B?.length) {
                            for (let cnt = 0; cnt < res.data.B.length; cnt++) {
                                if (0 === cnt) {
                                    sigma = res.data.B[cnt].q;
                                } else {
                                    sigma = sigma + res.data.B[cnt].q;
                                }
                                this.sigmaB.push(sigma);
                            } 
                        }
                        sigma = 0;
                        if (res?.data?.A && 0 < res?.data?.A?.length) {
                            for (let cnt = 0; cnt < res.data.A.length; cnt++) {
                                if (0 === cnt) {
                                    sigma = res.data.A[cnt].q;
                                } else {
                                    sigma = sigma + res.data.A[cnt].q;
                                }
                                this.sigmaA.push(sigma);
                            }
                        }
                    } else {
                        this.$toast.error(this.getCouldNotSetQuoteMessage, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getCouldNotSetQuoteMessage,
                            error: true
                        });
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        getQuoteEx() {
            const quoteEx = this.$store.getters["getQuoteExtended"];
            this.changePercent = quoteEx && quoteEx.ch ? quoteEx.ch : "N/A";
            this.buy = quoteEx && quoteEx.b ? quoteEx.b : "N/A";
            return quoteEx && quoteEx.prc 
                ? quoteEx 
                : { b: 0, a: 0, dif: 0, ch: 0, bq: 0, sq: 0, ldc: 0, ldp: 0, "1yr": 0, mc: 0, pe: 0, prc: 0, dv: 0 };
        },
        getDepth() {
            const depth = this.$store.getters["getDepth"];
            this.changePercent = depth && depth.ch ? depth.ch : "N/A";
            this.buy = depth && depth.ldp ? depth.ldp : "N/A";
            return depth && depth.ldp ?
                depth :
                { bcp: 0, acp: 0, dif: 0, ch: 0, ldc: 0, ldp: 0, dv: 0, B: [], A: [] };
        },
        openTrader() {
            const stocks = this.$store.getters["getStocks"];
            const mapped  = stocks.filter(item => {
                return 0 === item.code.toString().toUpperCase().localeCompare(this.selectedItemCode.toUpperCase() &&
                    parseInt(item.cMIC) === this.selectedItemExchangeId);
            });
            this.$store.commit("setSearchedStocksUserWatchlist", mapped);
            this.$store.commit("setSearchedStocksIndexWatchlist", mapped);
            // this is ither market or limit order
            this.$store.commit("setOrderIdToUpdate", "");
//            this.inExtendedInfoMenuItemClick('trade');
//            this.$router.push({ name: "Trader" });
        },
        setTradingActionquantityAndPrice(action, qty, price) {
            this.$store.commit("setSelectedTradingAction", action);
            this.$store.commit("setSelectedTradingQuantity", qty);
            this.$store.commit("setSelectedTradingPrice", price); 

            this.openTrader();
        },
        parseNumber(num, afterDot = 2) {
            return UiUtils.formatNumber(num, afterDot);
        },
        formatPrice(val, curr) {
            return UiUtils.formatPrice(val, curr, this.getUserLanguage());
        },
        choseWatchlist() {
            // open modal
            const body = document.querySelector("body");
            this.watchlistSelectionModalActive = true;
            this.watchlistSelectionModalShow = true;
            body.classList.add("modal-open");            
        },
        async removeFromWatchlist() {
            this.$store.commit("setAppStateNotReady");
            const updateResponse = await ApiUtils.removeFromWatchlist(http, UiUtils, this.$store, this.getExchange.code, this.selectedItemCode);
            this.$store.commit("setAppStateReady");
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.$toast.success(this.getRemovedFromWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getRemovedFromWatchlistMessage,
                        error: false
                    });
                }
            }
        },        
        async updateWatchlist() {

            const exchanges = this.$store.getters["getExchanges"];
            const xCode = UiUtils.getExchangeCodeById(exchanges, this.selectedItemExchangeId);
            this.$store.commit("setAppStateNotReady");
            const updateResponse = await ApiUtils.updateDefaultWatchlist(http, UiUtils, this.$store, xCode, this.selectedItemCode);
            this.$store.commit("setAppStateReady");
            if (updateResponse) {
                // check answer
                if (updateResponse.error) {
                    switch (parseInt(updateResponse.type)) {
                        case 1: {
                            // occupied name
                            this.$toast.error(this.getWatchlistNameOccupiedMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistNameOccupiedMessage,
                                error: true
                            });
                            break;
                        }
                        case 2: {
                            // watchlist contains element
                            this.$toast.error(this.getWatchlistContainsStockMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getWatchlistContainsStockMessage,
                                error: true
                            });
                            break;
                        }
                        case 3: {
                            // API error
                            this.$toast.error(this.getCouldNotUpdateWatchlistMessage, {
                                "duration": 5000,
                                "pauseOnHover": true
                            });
                            this.$store.commit("addSystemMessage", {
                                date: (new Date()).getTime(),
                                mess: this.getCouldNotUpdateWatchlistMessage,
                                error: true
                            });
                            break;
                        }
                    }
                } else {
                    this.$toast.success(this.getAddedToWatchlistMessage, {
                        "duration": 5000,
                        "pauseOnHover": true
                    });
                    this.$store.commit("addSystemMessage", {
                        date: (new Date()).getTime(),
                        mess: this.getAddedToWatchlistMessage,
                        error: false
                    });
                }
            }

            this.watchlistSelectionModalActive = false;
            this.watchlistSelectionModalShow = false;
        },
        async getQuoteTr() {
            if ("" === this.selectedItemCode) {
                return;
            }
            // get quote trading for selected item
            const user = this.$store.getters["getUser"];
            // get exchange code
            const exchanges = this.$store.getters["getExchanges"];
            const itemExchangeCode = UiUtils.getExchangeCodeById(exchanges, this.$store.getters["getSelectedTradingExchangeId"]);
            try {
                const res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "get_quote_tr",
                        id: user.id,
                        key: user.key,
                        sc: UiUtils.combineExchangeStockPair(itemExchangeCode, this.selectedItemCode)
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(
                            ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                            500);
                        return;
                    }                    
                    if (0 === res.data.ec) {
                        // set quote tr
                        this.quoteTrading = res.data;
                        this.$store.commit("setQuoteTrading", res.data);
                    } else {
                        this.$toast.error(this.getCouldNotReadQuotesMessage, {
                            "duration": 5000,
                            "pauseOnHover": true
                        });
                        this.$store.commit("addSystemMessage", {
                            date: (new Date()).getTime(),
                            mess: this.getCouldNotReadQuotesMessage,
                            error: true
                        });
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        async setQuoteTr(code) {
            if ("" === code) {
                return;
            }
            // set sse for quote tr
            // get exchange code
            const exchanges = this.$store.getters["getExchanges"];
            const itemExchangeCode = UiUtils.getExchangeCodeById(exchanges, this.$store.getters["getSelectedTradingExchangeId"]);
            await ApiUtils.setQuoteTrading(http, UiUtils, this.$store, itemExchangeCode, code);
        },
        inExtendedInfoMenuItemClick(name) {
            this.activeExtendedStockItem = name.toString().toUpperCase();
        },
        formOrderObject(event) {
            this.orderModel.type = this.getOrderType(event.t);
            this.orderModel.ex = event.xc;
            this.orderModel.code = event.sc;
            this.orderModel.name = 0 === 'BG'.localeCompare(this.getUserLanguage())
                ? event.nBG
                : event.nEN;
            this.orderModel.amount = this.parseNumber(event.qty);
            this.orderModel.price = this.parseNumber(event.sp);
            this.orderModel.price_total = this.parseNumber(event.pt);
            this.orderModel.currency = event.cc;
            this.orderModel.oid = event.id;
            this.orderModel.fee = this.parseNumber(event.fee);
            this.orderModel.time = this.getDate(event.d);
            this.orderModel.total_amount = this.parseNumber(event.pt + event.fee);
        },        
        calculateNumberOfMeaningfulDigits(val) {
            return UiUtils.calculateNumberOfMeaningfulDigits(val);
        },
        async getXfraData(xfraList) {
            if (!xfraList || 0 === xfraList.length) {
                return;
            }
            const user = this.$store.getters["getUser"];
            // get exchanges and stocks
            try {
                const res = await http.getApiClient(this.getUserType()).get("", {
                    params: {
                        a: "get_stocks_full_xfra",
                        id: user.id,
                        key: user.key,
                        sc: xfraList.join()
                    }
                });
                if (res) {
                    // check answer
                    if (ApiUtils.isApiResponseInvalidSession(res)) {
                        this.$store.commit("setAppStateReady");
                        // logout
                        setTimeout(
                            ApiUtils.cleanAndLogoutOnError(this.$store, this.$router, this.getInvalidSessionLabel, 1),
                            500);
                        return;
                    }
                    if (0 === res.data.ec) {
                        // add exchange index (hardcoded 3)
                        res.data.stocks.forEach(item => {
                            // this answer does not comply with the get_exchanges_stocks_ex answer
                            item.code = item.sc;
                            item.cMIC = 3;
                            item.tID = item.stid;
                            this.$store.commit("addStock", item);
                        })
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }        
    },
    computed: {
        getWindowsWidth() {
            return this.$store.getters["getAppWindowWidth"] || window.innerWidth;
        },          
        getStockName() {
            const stocks = this.$store.getters["getStocks"];
            const indexedStocks = this.$store.getters["getIndexedStocks"];

            // if we have no info in current stocks list and this is XFRA item
            // we should call the API for additional info
            const stockName = UiUtils.getStockName(stocks, indexedStocks, this.selectedItemCode, this.selectedItemExchangeId, this.getUserLanguage());

            if (0 !== STRING_NOT_AVAILABLE.localeCompare(stockName))
            {
                return stockName;
            }

            (async () => {
                // gathering data could be a long process, so we set the wait
                this.$store.commit("setAppStateNotReady");
                const xfraList = [];
                xfraList.push(this.selectedItemCode);
                await this.getXfraData(xfraList);
                this.$store.commit("setAppStateReady");
            })();

            return UiUtils.getStockName(stocks, indexedStocks, this.selectedItemCode, this.selectedItemExchangeId, this.getUserLanguage());
        },
        getExchange() {
            try {
                const exchanges = this.$store.getters["getExchanges"];
                if (exchanges && 0 < exchanges?.length) {
                    // find exchange 
                    const exchange = exchanges.filter(item => {
                        return parseInt(item.eID) === parseInt(this.selectedItemExchangeId);
                    })[0];
                    return exchange;
                }
            } catch (error) {
                console.error(error);
            }
            return {};
        },
        isClosedMarked() {
            const quoteEx = this.$store.getters["getQuoteExtended"];
            const mp = quoteEx?.ph || '';
            return 0 === mp.toString().toUpperCase().localeCompare("ENDTR") 
                || 0 === mp.toString().toUpperCase().localeCompare("CLOSE");
        },
        getMarketPhaseLabel() {
            const quoteEx = this.$store.getters["getQuoteExtended"];
            const mp = quoteEx?.ph || '';
            return 0 === mp.toString().toUpperCase().localeCompare("ENDTR") 
                || 0 === mp.toString().toUpperCase().localeCompare("CLOSE")
                ? Translations.getClosedLabel(this.getUserLanguage()) 
                : Translations.getOpenedLabel(this.getUserLanguage());
        },        
        getInformationLabel() {
            return Translations.getInformationLabel(this.getUserLanguage());
        },
        getDepthLabel() {
            return Translations.getDepthLabel(this.getUserLanguage());
        },
        getGraphLabel() {
            return Translations.getGraphLabel(this.getUserLanguage());
        },
        getTradeLabel() {
            return Translations.getTradeLabel(this.getUserLanguage());
        },
        getExchangeNameLabel() {
            return Translations.getExchangeLabel(this.getUserLanguage());
        },
        getExchangePhaseLabel() {
            return Translations.getExchangePhaseLabel(this.getUserLanguage());
        },
        getExchangePricesLabel() {
            return Translations.getExchangePricesLabel(this.getUserLanguage());
        },
        getPreviousCloseLabel() {
            return Translations.getPreviousCloseLabel(this.getUserLanguage());
        },
        getDailyVolumeLabel() {
            return Translations.getDailyVolumeLabel(this.getUserLanguage());
        }, 
        getTotalAmountLabel() {
            return Translations.getTotalAmountLabel(this.getUserLanguage());
        },
        getQuantityLabel() {
            return Translations.getQuantityLabel(this.getUserLanguage());
        },
        getBidLabel() {
            return Translations.getBidLabel(this.getUserLanguage());
        },
        getAskLabel() {
            return Translations.getAskLabel(this.getUserLanguage());
        },
        getOneYearReturnLabel() {
            return Translations.getOneYearReturnLabel(this.getUserLanguage());
        },
        getMarketCapLabel() {
            return Translations.getMarketCapLabel(this.getUserLanguage());
        },
        getLastTransactionLabel() {
            return Translations.getLastTransactionLabel(this.getUserLanguage());
        },
        getOrdersLabel() {
            return Translations.getOrdersLabel(this.getUserLanguage());
        },
        getCouldNotReadPhasesMessage() {
            return Translations.getCouldNotReadPhasesMessage(this.getUserLanguage());
        },
        getCouldNotReadHistoryMessage() {
            return Translations.getCouldNotReadHistoryMessage(this.getUserLanguage());
        },
        getCouldNotSetQuoteMessage() {
            return Translations.getCouldNotSetQuoteMessage(this.getUserLanguage());
        },
        getWatchlistSelectionLabel() {
            return Translations.getWatchlistSelectionLabel(this.getUserLanguage());
        },
        getCloseLabel() {
            return Translations.getCloseLabel(this.getUserLanguage());
        },
        getSaveLabel() {
            return Translations.getSaveLabel(this.getUserLanguage());
        },
        getNewWatchlistNameLabel() {
            return Translations.getNewWatchlistNameLabel(this.getUserLanguage());
        },
        getExistingWatchlistNameLabel() {
            return Translations.getExistingWatchlistNameLabel(this.getUserLanguage());
        },
        getWatchlistNameLabel() {
            return Translations.getWatchlistLabel(this.getUserLanguage());
        },
        getWatchlistNamePlaceholder() {
            return Translations.getWatchlistNamePlaceholder(this.getUserLanguage());
        },
        getCouldNotUpdateWatchlistMessage() {
            return Translations.getCouldNotUpdateWatchlistMessage(this.getUserLanguage());
        },
        getStockNotAddedMessage() {
            return Translations.getStockNotAddedMessage(this.getUserLanguage());
        },
        getWatchlistNameOccupiedMessage() {
            return Translations.getWatchlistNameOccupiedMessage(this.getUserLanguage());
        },
        getWatchlists() {
            const user = this.$store.getters["getUser"];
            return user.wls ? user.wls : [];
        },
        getAddedToWatchlistMessage() {
            return Translations.getAddedToWatchlistMessage(this.getUserLanguage());
        },
        getSelectedTradingCode() {
            return this.$store.getters["getSelectedTradingCode"];
        },
        getCouldNotReadQuotesMessage() {
            return Translations.getCouldNotReadQuotesMessage(this.getUserLanguage());
        },
        getReestablishingConnectionLabel() {
            return Translations.getReestablishingConnectionLabel(this.getUserLanguage());
        }, 
        getReestablishedConnectionLabel() {
            return Translations.getReestablishedConnectionLabel(this.getUserLanguage());
        },
        getChartDataForInstrumentUnavailableMessage() {
            return Translations.getChartDataForInstrumentUnavailableMessage(this.getUserLanguage());
        },
        getInvalidSessionLabel() {
            return Translations.getInvalidSessionLabel(this.getUserLanguage());
        },
        getTickSizeLabel() {
            return Translations.getTickSizeLabel(this.getUserLanguage());
        },        
        isStockPartOfDefaultWatchlist() {
            if ("" === this.selectedItemCode) {
                return false;
            }
            const user = this.$store.getters["getUser"];
            const xCode = this.getExchange;
            let hasItem = false;
            for (let cnt = 0; cnt < user.wls.length; cnt++) {
                const wl = user.wls[cnt];
                if (0 == wl.wln.toString().toLowerCase().localeCompare("default")) {
                    hasItem = UiUtils.verifyPairListContainsElement(wl.sc, UiUtils.createExchangeStockCodePairObject(xCode.code, this.selectedItemCode));
                    if (hasItem) { break; }
                }
            }
            return hasItem;
        },
        getRemovedFromWatchlistMessage() {
            return Translations.getRemovedFromWatchlistMessage(this.getUserLanguage());
        },
        getStockCurrency() {
            if (2 === this.$store.getters["getSelectedTradingCodeShareTypeId"]) {
                return "%";
            }
            const stocks = this.$store.getters["getStocks"];
            const indexedStocks = this.$store.getters["getIndexedStocks"];

            return UiUtils.getStockCurrency(stocks, indexedStocks, this.selectedItemCode, this.selectedItemExchangeId);
        },
        getStockTypeId() {
            if ("" === this.selectedItemCode) {
                return 1;
            }
            const indexedStocks = this.$store.getters["getIndexedStocks"];
            const stocks = this.$store.getters["getStocks"];
            const stockIndex = indexedStocks.indexOf(this.selectedItemExchangeId + this.selectedItemCode);

            return stocks[stockIndex]?.tID || 1;
        },
        calculateTickSize() {
            // algo from trader
            const quoteTr = this.$store.getters["getQuoteTrading"];
            if (!quoteTr) {
                return 0.0;
            }

            var orderSharePrice = quoteTr.a;
            var liqBandNumber = quoteTr.lbn;

            if (0 >= orderSharePrice) {
                orderSharePrice = quoteTr.ltp;
            }
            if (0 >= orderSharePrice) {
                orderSharePrice = quoteTr.clp;
            }
            if (64 === liqBandNumber && 1 === orderSharePrice) return 0.001;
            switch (liqBandNumber) {
                case 82: return 0.001;
                case 83: return 0.0001;
                case 84: return 0.001;
                case 85: return 0.001;
                case 86: return 0.001;
            }

            switch (liqBandNumber) {
                case 33: liqBandNumber = 1; break;
                case 34: liqBandNumber = 2; break;
                case 35: liqBandNumber = 3; break;
                case 37: liqBandNumber = 4; break;
                case 38: liqBandNumber = 5; break;
                case 39: liqBandNumber = 6; break;
                case 40: liqBandNumber = 7; break;
                case 41: liqBandNumber = 8; break;
                case 42: liqBandNumber = 9; break;
                case 43: liqBandNumber = 10; break;
                case 90: liqBandNumber = 2; break;
                case 91: liqBandNumber = 1; break;
            }
            if (0 >= liqBandNumber || 10 < liqBandNumber) return 0;

            let tickSize = sdMaxTickSizes[liqBandNumber];

            for (let i = 18; i > 0; i--) {
                if (orderSharePrice >= sdLiqPriceLevels[i]) {
                    break;
                }
                if (tickSize >= 500) tickSize = 200;
                else if (tickSize >= 200) tickSize = 100;
                else if (tickSize >= 100) tickSize = 50;
                else if (tickSize >= 50) tickSize = 20;
                else if (tickSize >= 20) tickSize = 10;
                else if (tickSize >= 10) tickSize = 5;
                else if (tickSize >= 5) tickSize = 2;
                else if (tickSize >= 2) tickSize = 1;
                else if (tickSize >= 1) tickSize = 0.5;
                else if (tickSize >= 0.5) tickSize = 0.2;
                else if (tickSize >= 0.2) tickSize = 0.1;
                else if (tickSize >= 0.1) tickSize = 0.05;
                else if (tickSize >= 0.05) tickSize = 0.02;
                else if (tickSize >= 0.02) tickSize = 0.01;
                else if (tickSize >= 0.01) tickSize = 0.005;
                else if (tickSize >= 0.005) tickSize = 0.002;
                else if (tickSize >= 0.002) tickSize = 0.001;
                else if (tickSize >= 0.001) tickSize = 0.0005;
                else if (tickSize >= 0.0005) tickSize = 0.0002;
                else if (tickSize >= 0.0002) tickSize = 0.0001;
            }
            return tickSize;
        },
        stopSseState() {
        return this.$store.getters["getStopSse"];
        }
    },
    async mounted() {
        await Promise.all([this.getQuoteTr(), this.setQuoteExAndDepth(), this.getQuoteExAndDepth(), this.getInstrumentHistory()])
        this.getQuoteEx();
        this.hasEvents = false;        
        // start sse monitor
        this.sseVerifier = window.setInterval(function () {
            if (EventSource.OPEN !== this.sseClient?.readyState || !this.hasEvents) {
                // close current client if any
                if (this.sseClient) {
                    try {
                        this.sseClient.close();
                    } catch (err) {
                        console.error(err);
                    }
                    this.sseClient = null;
                }
                this.setupStream();
            }
        }.bind(this), 3000);
    },
    beforeUnmount() {
        // clear the timeout before the component is destroyed
        clearTimeout(this.timeout);
    },
    unmounted() {
        // stop verifier
        if (this.sseVerifier) {
            clearInterval(this.sseVerifier);
        }        
        // close sse
        if (this.sseClient) {
            try {
                this.sseClient.close();
            } catch (err) {
                console.error(err);
            }
        }
    }
}
</script>

<style scoped>




    .greenColor {
        color: #74b21f;
    }

    .redColor {
        color: #ff0000;
    }

    .whiteColor {
        color: #ffffff;
    }

    .blackColor {
        color: #000000;
    }

    .align-text-right {
        text-align: right !important;
    }
    .align-text-center {
        text-align: center !important;
    }

    #history_depth_menu {
        width: 100%;
        overflow-x: hidden;
    }

    #history_depth_menu .btn-check {
        max-width: 4.5rem;
    }

    #history_depth_menu .btn-outline-primary {
        max-width: 4.5rem;
        margin-right: 0.2rem;
    }

    .show_list_horizontal {
        overflow-x: auto; 
        max-width: 100%; 
        max-height: auto; 
        flex: 1; 
        flex-wrap: nowrap;
    }
</style>